.ProgressBar-horn-container {
  display: inline-block;
  z-index: 30;
  position: relative;
  top: 30px;
  left: -12px;
}

.ProgressBar-horn {
  display: inline-block;
  transform: translateX(3px);
}

.ProgressBar-horn img {
  width: 100%;
  height: 100%;
}
