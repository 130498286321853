table {
  color: #58595c !important;
  text-align: center;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin-top: 25px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8f8f8;
}

thead {
  font-size: 20px;
}

thead th:first-child,
tbody tr td:first-child {
  text-align: left;
  padding-left: 24px;
}

.table td,
.table th {
  padding: 0.6rem;
}

table th {
  border-top: none !important;
}

tbody {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.53px;
}

/*Media Queries*/
@media screen and (max-width: 1366px) and (min-width: 1281px) {
  thead {
    font-size: 19px;
  }
  tbody {
    font-size: 15px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1250px) {
  thead {
    font-size: 19px;
  }
  tbody {
    font-size: 15px;
  }
}

@media screen and (max-width: 1019px) and (min-width: 768px) {
  tbody,
  thead {
    font-size: 13px;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  tbody,
  thead {
    font-size: 13px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  tbody,
  thead {
    font-size: 13px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  tbody,
  thead {
    font-size: 12px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  tbody,
  thead {
    font-size: 12px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  tbody,
  thead {
    font-size: 12px;
  }
}
