.Navbar {
  position: fixed;
  width: 100%;
  z-index: 200;
}

.Navbar-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 25px 0 20px 0;
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
}

.Navbar-item {
  opacity: 0;
  transform: translateY(50px);
}

.Navbar-item:not(:last-child) {
  margin-right: 40px;
}

.Navbar-link {
  text-transform: uppercase;
  text-decoration: none !important;
  font-size: 16px;
  color: #fff;
}

.Navbar-link:hover {
  color: #fff;
}

.Navabar-item-logo {
  position: absolute;
  left: 0;
}

.Navabar-item-logo img {
  width: 160px;
  height: auto;
}

.Navbar-item-ing {
  position: absolute;
  right: 0;
}

.Navbar-btn {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
}

.Navbar-btn:focus {
  outline: none;
}

.Navbar-login {
  text-decoration: none !important;
}

.Navbar-login:hover,
.Navbar-login:active,
.Navbar-login:link {
  color: #fff;
}

/*Mobile Nav Icon*/
.toggle-btn-movil {
  position: absolute;
  z-index: 100;
  margin: 25px 0 0 20px;
  cursor: pointer;
}

.icon-movil {
  width: 27px;
  height: 2px;
  background-color: #fff;
}

.icon-movil:not(:last-child) {
  margin-bottom: 5px;
}

/*Menu Mobile*/

.mobile-menu-bg {
  position: absolute;
  background-color: #f47600;
  height: 100%;
  width: 100%;
  left: -100%;
  z-index: 90;
}

.mobile-menu-container {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile-link {
  object-fit: contain;
  font-size: 20px;
  letter-spacing: 0.31px;
  text-align: center;
  color: #ffffff;
}

.mobile-button {
  font-size: 20px;
  letter-spacing: 0.31px;
  text-align: center;
  color: #ffffff;
  background-color: transparent;
  border: none;
}

.mobile-button:focus {
  outline: none;
}

.mobile-item {
  text-align: center;
}

.mobile-item:not(:last-child) {
  margin-bottom: 20px;
}

.Navbar-movil-logo {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
}

.Navbar-movil-logo > img {
  height: 30px;
  width: auto;
}

.Navbar-mobile-line {
  max-width: 160px;
  height: 1px;
  background-color: #fff;
  margin: 0 auto 25px auto;
}

/*Media Queries*/
@media screen and (min-width: 1921px) {
  .Navbar {
    max-width: 1440px;
  }

  .toggle-btn-movil {
    display: none;
  }

  .Navbar-movil-logo {
    display: none;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1441px) {
  .Navbar-link,
  .Navbar-btn {
    font-size: 16px;
  }

  .Navabar-item-logo img {
    width: 140px;
  }

  .toggle-btn-movil {
    display: none;
  }

  .Navbar-movil-logo {
    display: none;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .toggle-btn-movil {
    display: none;
  }

  .Navbar-movil-logo {
    display: none;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .toggle-btn-movil {
    display: none;
  }

  .Navbar-movil-logo {
    display: none;
  }

  .Navbar-list {
    max-width: 1206px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .toggle-btn-movil {
    display: none;
  }

  .Navbar-movil-logo {
    display: none;
  }

  .Navbar-list {
    max-width: 1206px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .Navbar {
    height: 64px;
  }

  .Navbar-list {
    display: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .Navbar {
    height: 64px;
  }

  .Navbar-list {
    display: none;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .Navbar {
    height: 64px;
  }

  .Navbar-list {
    display: none;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .Navbar {
    height: 64px;
  }

  .Navbar-list {
    display: none;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .Navbar {
    height: 64px;
  }

  .Navbar-list {
    display: none;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .Navbar {
    height: 64px;
  }

  .Navbar-list {
    display: none;
  }

  .toggle-btn-movil {
    margin: 25px 0 0 14px;
  }
}
