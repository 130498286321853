.ProjectDetails-stages-wrapper-dash > div {
  box-shadow: none;
}

.ProjectDetails-list-dash {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.ProjectDetails-item-dash {
  width: 100%;
}

.ProjectDetails-link-dash,
.ProjectDetails-link-dash:hover {
  width: 100%;
  opacity: 0.25;
  font-size: 15px;
  color: #000;
  text-align: center;
  display: inline-block;
  border-bottom: 1px solid #b3b3b3;
  padding: 13px;
  text-decoration: none !important;
}

.ProjectDetails-active-dash,
.ProjectDetails-active-dash:hover {
  opacity: 1;
  font-size: 15px;
  font-weight: 700;
  color: #f47600;
  border-bottom: 4px solid #f47600;
  text-decoration: none;
}

.ProjectDetails-options-dash {
  margin: 28px 0 0 0;
}
