.PackagesList {
    margin-bottom: 10px !important;
    padding: 7px 0;
    margin: 0 !important;
}

.PackagesList:hover {
    background-color: #F1D9C1;
}

.PackagesList:hover .PackagesList-Link-container .PackagesList-link {
    color: #f47600;
}

.PackagesList-link {
    text-decoration: none !important;
    color: #58595c;
}

.PackagesList-link:hover {
    color: #f47600;
}

.PackagesList-Link-container {
    width: 60%;
    margin: 0 auto;
}