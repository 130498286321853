.SingleUser {
  display: grid;
  grid-template-columns: 335px auto;
  grid-template-rows: repeat(1, 1fr);
  padding: 13px;
}
.SingleUser-link {
  text-decoration: none !important;
}

.SingleUser-title {
  opacity: 0.5;
  font-size: 15px;
  color: #000;
  margin: 0;
}

.SingleUser-link:nth-child(even) > div {
  background-color: #f8f8f8 !important;
}

.SingleUser-link:hover > div {
  background-color: #fef2e6;
}
