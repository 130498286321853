.UserProfile {
  padding: 80px 0 0 0;
  margin-bottom: 25rem;
}

.UserProfile-title-wrapper {
  margin-bottom: 40px;
}

.UserProfile-title {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 1.07px;
  color: #58595c;
  margin: 0;
}

.UserProfile-info {
  max-width: 1200px;
  height: 175px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.UserProfile-info-title-container {
  padding: 12px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.UserProfile-info-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.67px;
  color: #58595c;
}

.UserProfile-info-link-edit,
.UserProfile-info-link-edit:hover,
.UserProfile-info-link-edit-mobile,
.UserProfile-info-link-edit-mobile:hover {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.53px;
  color: #ff0000;
}

.UserProfile-info-container > div:nth-child(odd) {
  background-color: #f8f8f8;
}

/*Media Queries*/
@media screen and (min-width: 1441px) {
  .UserProfile-info-link-edit-mobile-wrapper,
  .UserProfile-wrapper-mobile {
    display: none;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .UserProfile-info-link-edit-mobile-wrapper,
  .UserProfile-wrapper-mobile {
    display: none;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .UserProfile-info-link-edit-mobile-wrapper,
  .UserProfile-wrapper-mobile {
    display: none;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .UserProfile-info-link-edit-mobile-wrapper,
  .UserProfile-wrapper-mobile {
    display: none;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .UserProfile-info {
    box-shadow: none;
    max-width: 100%;
    margin: 0 auto;
    height: 100%;
  }
  .UserProfile-info-title-container,
  .UserProfile-wrapper-desk {
    display: none;
  }
  .UserProfile-info-container > div:nth-child(odd) {
    background-color: #fff;
  }
  .UserProfile-info-container > div {
    border-bottom: 1px solid #b3b3b3;
  }
  .UserProfile-info-link-edit-mobile-wrapper {
    margin: 0 0 22px 0;
    text-align: right;
  }
  .UserProfile-info-link-edit-mobile {
    font-size: 16px;
  }
  .UserProfile-title-wrapper {
    margin-bottom: 0;
  }
  .ProfileInfo-container {
    align-items: flex-end;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .UserProfile-title {
    font-size: 24px;
  }
  .UserProfile {
    margin-bottom: 190px;
  }
  .UserProfile-info {
    box-shadow: none;
    max-width: 510px;
    margin: 0 auto;
    height: 100%;
  }
  .UserProfile-info-title-container,
  .UserProfile-wrapper-desk {
    display: none;
  }
  .UserProfile-info-container > div:nth-child(odd) {
    background-color: #fff;
  }
  .UserProfile-info-container > div {
    border-bottom: 1px solid #b3b3b3;
  }
  .UserProfile-info-link-edit-mobile-wrapper {
    margin: 0 0 22px 0;
    text-align: right;
  }
  .UserProfile-info-link-edit-mobile {
    font-size: 16px;
  }
  .UserProfile-title-wrapper {
    margin-bottom: 0;
  }
  .ProfileInfo-container {
    align-items: flex-end;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .UserProfile-title {
    font-size: 24px;
  }
  .UserProfile {
    margin-bottom: 200px;
  }
  .UserProfile-title-wrapper {
    margin-bottom: 32px;
  }
  .UserProfile-info {
    box-shadow: none;
    max-width: 510px;
    margin: 0 auto;
    height: 100%;
  }
  .UserProfile-info-title-container,
  .UserProfile-wrapper-desk {
    display: none;
  }
  .UserProfile-info-container > div:nth-child(odd) {
    background-color: #fff;
  }
  .UserProfile-info-container > div {
    border-bottom: 1px solid #b3b3b3;
  }
  .UserProfile-info-link-edit-mobile-wrapper {
    margin: 0 0 22px 0;
    text-align: right;
  }
  .UserProfile-info-link-edit-mobile {
    font-size: 16px;
  }
  .UserProfile-title-wrapper {
    margin-bottom: 0;
  }
  .ProfileInfo-container {
    align-items: flex-end;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .UserProfile-title {
    font-size: 24px;
  }
  .UserProfile {
    margin-bottom: 200px;
  }
  .UserProfile-title-wrapper {
    margin-bottom: 32px;
  }
  .UserProfile-info {
    box-shadow: none;
    max-width: 510px;
    margin: 0 auto;
    height: 100%;
  }
  .UserProfile-info-title-container,
  .UserProfile-wrapper-desk {
    display: none;
  }
  .UserProfile-info-container > div:nth-child(odd) {
    background-color: #fff;
  }
  .UserProfile-info-container > div {
    border-bottom: 1px solid #b3b3b3;
  }
  .UserProfile-info-link-edit-mobile-wrapper {
    margin: 0 0 22px 0;
    text-align: right;
  }
  .UserProfile-info-link-edit-mobile {
    font-size: 16px;
  }
  .UserProfile-title-wrapper {
    margin-bottom: 0;
  }
  .ProfileInfo-container {
    align-items: flex-end;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .UserProfile-title {
    font-size: 24px;
  }
  .UserProfile {
    margin-bottom: 210px;
  }
  .UserProfile-info {
    box-shadow: none;
    max-width: 510px;
    margin: 0 auto;
    height: 100%;
  }
  .UserProfile-title-wrapper {
    margin-bottom: 32px;
  }
  .UserProfile-info-title-container,
  .UserProfile-wrapper-desk {
    display: none;
  }
  .UserProfile-info-container > div:nth-child(odd) {
    background-color: #fff;
  }
  .UserProfile-info-container > div {
    border-bottom: 1px solid #b3b3b3;
  }
  .UserProfile-info-link-edit-mobile-wrapper {
    margin: 0 0 22px 0;
    text-align: right;
  }
  .UserProfile-info-link-edit-mobile {
    font-size: 16px;
  }
  .UserProfile-title-wrapper {
    margin-bottom: 0;
  }
  .ProfileInfo-container {
    align-items: flex-end;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .UserProfile-title {
    font-size: 24px;
  }
  .UserProfile {
    margin-bottom: 190px;
  }
  .UserProfile-info {
    box-shadow: none;
    max-width: 510px;
    margin: 0 auto;
    height: 100%;
  }
  .UserProfile-title-wrapper {
    margin-bottom: 32px;
  }
  .UserProfile-info-title-container,
  .UserProfile-wrapper-desk {
    display: none;
  }
  .UserProfile-info-container > div:nth-child(odd) {
    background-color: #fff;
  }
  .UserProfile-info-container > div {
    border-bottom: 1px solid #b3b3b3;
  }
  .UserProfile-info-link-edit-mobile-wrapper {
    margin: 0 0 22px 0;
    text-align: right;
  }
  .UserProfile-info-link-edit-mobile {
    font-size: 16px;
  }
  .UserProfile-title-wrapper {
    margin-bottom: 0;
  }
  .ProfileInfo-container {
    align-items: flex-end;
  }
}
