.DashInput-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.DashboardLabel {
  font-size: 14px;
  letter-spacing: 0.47px;
  color: #000;
}

.Dashboard-input {
  padding: 16px;
  background-color: #f7f7f7;
  border: none;
  height: 48px;
  opacity: 0.5;
  font-size: 15px;
  letter-spacing: 0.5px;
  color: #000;
}
