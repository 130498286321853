.Payments-project {
  max-width: 1200px;
  height: 100%;
  border-radius: 15px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

/*Media Queries*/
@media screen and (max-width: 767px) and (min-width: 480px) {
  .Payments-project {
    max-width: 510px;
    box-shadow: none;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .Payments-project {
    max-width: 384px;
    box-shadow: none;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .Payments-project {
    max-width: 345px;
    box-shadow: none;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .Payments-project {
    max-width: 330px;
    box-shadow: none;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .Payments-project {
    max-width: 330px;
    box-shadow: none;
  }
}
