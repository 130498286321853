.ResetPassword {
  width: 100%;
  height: 100vh;
  background-image: url('../../img/reset/reset.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

.ResetPassword-details {
  max-width: 400px;
  margin: 0 auto;
  padding-top: 45px;
}

.ResetPassword-title {
  font-size: 32px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 1.07px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 8px;
}

.ResetPassword-info {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.6px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 44px;
}

.ResetPassword-input {
  margin-bottom: 40px;
}

.ResetPassword-btn > div button {
  background-color: #f67600;
  color: #fff;
  height: 56px;
}

.ResetPassword-rinho-logo {
  margin-bottom: 65px;
}

.ResetPassword-img {
  width: 210px;
  height: auto;
  display: block;
  margin: 0 auto;
}

@media screen and (min-width: 1441px) {
  .ResetPassword-details {
    padding-top: 80px;
  }
  .ResetPassword-rinho-logo {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .ResetPassword-details {
    padding-top: 80px;
  }
  .ResetPassword-rinho-logo {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 959px) and (min-width: 736px) {
  .ResetPassword-details {
    padding-top: 35px;
  }
  .ResetPassword-details {
    max-width: 374px;
  }
  .ResetPassword-title {
    font-size: 24px;
  }
  .ResetPassword-info {
    font-size: 15px;
    width: 93%;
    margin: 0 auto 20px auto;
  }
  .ResetPassword-rinho-logo {
    margin-bottom: 20px;
  }
  .ResetPassword-input {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 736px) and (min-width: 667px) {
  .ResetPassword-details {
    padding-top: 35px;
  }
  .ResetPassword-details {
    max-width: 374px;
  }
  .ResetPassword-title {
    font-size: 24px;
  }
  .ResetPassword-info {
    font-size: 15px;
    width: 93%;
    margin: 0 auto 20px auto;
  }
  .ResetPassword-rinho-logo {
    margin-bottom: 20px;
  }
  .ResetPassword-input {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 666px) and (min-width: 640px) {
  .ResetPassword-details {
    padding-top: 30px;
  }
  .ResetPassword-details {
    max-width: 374px;
  }
  .ResetPassword-title {
    font-size: 24px;
  }
  .ResetPassword-info {
    font-size: 15px;
    width: 93%;
    margin: 0 auto 20px auto;
  }
  .ResetPassword-rinho-logo {
    margin-bottom: 20px;
  }
  .ResetPassword-input {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 639px) and (min-width: 568px) {
  .ResetPassword-details {
    padding-top: 15px;
  }
  .ResetPassword-details {
    max-width: 374px;
  }
  .ResetPassword-title {
    font-size: 24px;
  }
  .ResetPassword-info {
    font-size: 15px;
    width: 93%;
    margin: 0 auto 20px auto;
  }
  .ResetPassword-rinho-logo {
    margin-bottom: 10px;
  }
  .ResetPassword-input {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .ResetPassword {
    background-image: url('../../img/reset/Fondo.jpg');
  }
  .ResetPassword-details {
    max-width: 374px;
  }
  .ResetPassword-title {
    font-size: 24px;
  }
  .ResetPassword-info {
    font-size: 15px;
    width: 93%;
    margin: 0 auto 44px auto;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .ResetPassword {
    background-image: url('../../img/reset/Fondo.jpg');
  }
  .ResetPassword-details {
    max-width: 335px;
  }
  .ResetPassword-title {
    font-size: 24px;
  }
  .ResetPassword-info {
    font-size: 15px;
    width: 100%;
    margin: 0 auto 44px auto;
  }
  .ResetPassword-input {
    margin-bottom: 30px;
  }
  .ResetPassword-btn > div {
    max-width: 335px !important;
    height: 48px !important;
  }
  .ResetPassword-btn > div > button {
    height: 100%;
  }
  .ResetPassword-img {
    width: 171px;
    height: auto;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .ResetPassword {
    background-image: url('../../img/reset/Fondo.jpg');
  }
  .ResetPassword-details {
    max-width: 320px;
    padding-top: 30px;
  }
  .ResetPassword-title {
    font-size: 24px;
  }
  .ResetPassword-info {
    font-size: 15px;
    width: 100%;
    margin: 0 auto 30px auto;
  }
  .ResetPassword-input,
  .ResetPassword-rinho-logo {
    margin-bottom: 30px;
  }
  .ResetPassword-btn > div {
    max-width: 320px !important;
    height: 48px !important;
  }
  .ResetPassword-btn > div > button {
    height: 100%;
  }
  .ResetPassword-img {
    width: 156px;
    height: auto;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .ResetPassword {
    background-image: url('../../img/reset/Fondo.jpg');
  }
  .ResetPassword-details {
    max-width: 280px;
    padding-top: 30px;
  }
  .ResetPassword-title {
    font-size: 23px;
  }
  .ResetPassword-info {
    font-size: 15px;
    width: 100%;
    margin: 0 auto 30px auto;
  }
  .ResetPassword-input,
  .ResetPassword-rinho-logo {
    margin-bottom: 30px;
  }
  .ResetPassword-btn > div {
    max-width: 280px !important;
    height: 48px !important;
  }
  .ResetPassword-btn > div > button {
    height: 100%;
  }
  .ResetPassword-img {
    width: 156px;
    height: auto;
  }
}
