.HowItWorks {
  padding: 120px 0 0 0;
  position: relative;
  height: 2000px;
}

.HowItWorks-container {
  max-width: 1360px;
  height: 100%;
  margin: 0 auto;
}

.cloud-up {
  max-width: 284px;
  height: 264px;
  background-image: url('../../img/home-agency/cloud.png');
  background-size: contain;
  position: relative;
  top: -250px;
  background-repeat: no-repeat;
}

.clouds-group {
  height: 343px;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: 284px 284px;
  gap: 52px;
  position: relative;
  top: -217px;
  left: 85px;
}

.clouds-group-2 {
  height: 410px;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: 51px 284px 50px 284px 51px;
  gap: 52px;
  position: relative;
  top: -250px;
  left: 125px;
}

.clouds-group-3 {
  height: 450px;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: 314px 372px;
  gap: 52px;
  position: relative;
  top: -304px;
  left: 30%;
}

.clouds-group-4 {
  height: 480px;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: 50px 350px 29px 370px;
  gap: 24px;
  position: relative;
  top: -304px;
  left: 30%;
}

.cloud-1,
.cloud-2 {
  max-width: 284px;
  height: 264px;
  background-image: url('../../img/home-agency/cloud.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.cloud-2 {
  display: flex;
  align-self: flex-end;
}

.cloud-text-container > h4 {
  font-size: 64px;
  font-weight: 700;
  line-height: 1.25;
  text-align: left;
  color: #01355f;
  margin: 0 105px 0 0;
}

.cloud-text-container > p {
  font-size: 30px;
  line-height: 1;
  text-align: center;
  color: #01355f;
  margin: 0;
}

.cloud-text-container > p > span {
  display: block;
}

.circle-1 {
  max-width: 51px;
  height: 43px;
  background-image: url('../../img/home-agency/circle-1.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.circle-2 {
  max-width: 50px;
  height: 75px;
  background-image: url('../../img/home-agency/circle-2.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-self: center;
}

.circle-3 {
  max-width: 50px;
  height: 40px;
  background-image: url('../../img/home-agency/circle-3.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-self: flex-start;
}

.circle-4 {
  max-width: 29px;
  height: 33px;
  background-image: url('../../img/home-agency/circle-4.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-self: flex-start;
}

.cloud-2-up {
  margin-bottom: 25px;
}

.circle-1-end {
  display: flex;
  align-self: flex-end;
}

.cloud-1-group-3 {
  max-width: 314px;
  height: 318px;
}

.cloud-2-group-3 {
  max-width: 372px;
  height: 376px;
  display: flex;
  align-self: flex-end;
}

.HowItWorks-paragraph-container {
  max-width: 1200px;
  position: relative;
  top: -250px;
}

.HowItWorks-paragraph-container > p {
  text-align: left;
  width: 55%;
  font-size: 32px;
}

.cloud-1-group-4 {
  width: 350px;
  height: 357px;
  background-image: url('../../img/home-agency/cloud.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.cloud-2-group-4 {
  width: 370px;
  height: 377px;
  background-image: url('../../img/home-agency/cloud.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}

.cloud-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 105%;
  height: 73%;
  margin: 15px auto 0 auto;
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .HowItWorks-paragraph-container {
    margin-left: 120px;
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .HowItWorks-paragraph-container {
    margin: 0 120px;
  }
  .HowItWorks-container {
    max-width: 1200px;
  }
  .clouds-group-4 {
    grid-template-columns: 50px 330px 29px 330px;
  }
  .cloud-1-group-4,
  .cloud-2-group-4 {
    width: 330px;
    height: 340px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .HowItWorks-paragraph-container {
    margin: 0 120px;
  }
  .HowItWorks-container {
    max-width: 1200px;
  }
  .clouds-group-4 {
    grid-template-columns: 50px 330px 29px 330px;
  }
  .cloud-1-group-4,
  .cloud-2-group-4 {
    width: 330px;
    height: 340px;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .HowItWorks-paragraph-container {
    margin-left: 120px;
    margin-bottom: 48px;
  }
  .HowItWorks-container {
    max-width: 1286px;
  }
  .cloud-up {
    max-width: 234px;
    height: 224px;
  }
  .clouds-group {
    grid-template-columns: 234px 234px;
  }
  .cloud-1,
  .cloud-2 {
    max-width: 234px;
    height: 224px;
  }
  .clouds-group-2 {
    grid-template-columns: 51px 234px 50px 234px 51px;
    top: -190px;
  }
  .clouds-group-3 {
    grid-template-columns: 234px 234px;
  }
  .cloud-text-container > p {
    font-size: 25px;
  }
  .cloud-text-container > h4 {
    font-size: 50px;
  }
}

/*Estilos movil*/
.HowItWorks-container-movil {
  max-width: 720px;
  margin: 0 auto;
}
.HowItWorks-paragraph-movil > p {
  margin: 0;
}

.HowItWorks-perks-img {
  position: relative;
  height: 90px;
  width: auto;
  background-image: url('../../img/steps/huella.png');
  background-repeat: no-repeat;
}

.HowItWorks-perks-paragraph > p {
  font-size: 17px;
  line-height: 1.06;
  text-align: center;
  color: #01355f;
}

.HowItWorks-perks-paragraph > p > span {
  display: block;
}

.HowItWorks-movil-container-perks {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  margin: 48px 0 93px 0;
}

.HowItWorks-perks-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px 0;
}

.HowItWorks-perks-number {
  font-size: 32px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  color: #01355f;
  margin: 0;
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-44%, -50%);
}

@media screen and (min-width: 1441px) {
  .HowItWorks-container-movil {
    display: none;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .HowItWorks-container-movil {
    display: none;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .HowItWorks-container-movil {
    display: none;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .HowItWorks-container-movil {
    display: none;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .HowItWorks {
    height: 100%;
    padding: 50px 0 50px 0;
  }
  .HowItWorks-container {
    display: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .HowItWorks {
    height: 100%;
    padding: 50px 0 50px 0;
  }
  .HowItWorks-container {
    display: none;
  }
  .HowItWorks-paragraph-movil {
    width: 80%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .HowItWorks {
    height: 100%;
    padding: 50px 0 50px 0;
  }
  .HowItWorks-container {
    display: none;
  }
  .HowItWorks-paragraph-movil {
    width: 80%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .HowItWorks {
    height: 100%;
    padding: 50px 0 50px 0;
  }
  .HowItWorks-container {
    display: none;
  }
  .HowItWorks-paragraph-movil {
    width: 80%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .HowItWorks {
    height: 100%;
    padding: 50px 0 50px 0;
  }
  .HowItWorks-container {
    display: none;
  }
  .HowItWorks-paragraph-movil {
    width: 80%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .HowItWorks {
    height: 100%;
    padding: 50px 0 50px 0;
  }
  .HowItWorks-container {
    display: none;
  }
  .HowItWorks-paragraph-movil {
    width: 80%;
    margin: 0 auto;
  }
}
