.Jungle {
  position: relative;
  padding: 1rem 0 4rem 0;
}

.mg {
  margin: 50px 0 50px 0;
}

.Jungle-title-container {
  width: fit-content;
  margin: 0 auto;
}

.Jungle-title {
  color: #58595c;
  text-align: center;
  font-size: 64px;
  margin-bottom: 15px;
  white-space: nowrap;
  border-right: 2px solid;
  width: 0%;
  overflow: hidden;
  visibility: hidden;
}

.Jungle-paragraph-container {
  width: 60%;
  margin: 0 auto;
  transform: translateY(300px);
  opacity: 0;
}

.Jungle-paragraph-container p {
  color: #b3b3b3;
  font-size: 24px;
  text-align: center;
}

.Jungle-item {
  position: relative;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  bottom: 0;
  z-index: 10;
  margin: 0 auto;
  transform: scale(0);
}

.Jungle-item-one {
  background-image: url('../../img/jungle/integral_.jpg');
}

.Jungle-item-two {
  background-image: url('../../img/jungle/medio_.jpg');
  background-position-x: left;
}

.Jungle-item-three {
  background-image: url('../../img/jungle/basico_.jpg');
  background-position: 28% center;
}

.Jungle-item-four {
  background-image: url('../../img/jungle/micro_.jpg');
}

.Jungle-item-five {
  background-image: url('../../img/jungle/rs_.jpg');
}

.Jungle-item-text {
  position: absolute;
  bottom: 25%;
  width: 100%;
  color: #fff;
  text-align: center;
}

.Jungle-item-text p,
.Jungle-item-text h5 {
  margin: 0 !important;
}

.Jungle-item-text h5 {
  font-size: 20px;
}

.Jungle-btn {
  margin-top: 50px;
  font-size: 20px;
}

.arrow-icon {
  margin-bottom: 3px;
}

.Jungle-leave-one,
.Jungle-leave-two {
  position: absolute;
  width: 100%;
  height: 150px;
  background-image: url('../../img/jungle/leftLeave.png');
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 5;
  bottom: 0;
  transform: scale(0) rotate(3deg);
}

.Jungle-leave-one {
  margin-left: 6px;
}

.Jungle-leave-two {
  width: 86%;
  height: 157px;
  background-image: url('../../img/jungle/rightLeave_2.png');
  transform: rotate(-4deg);
  right: 16px;
  bottom: 27px;
  background-size: 100% 100%;
  z-index: 10;
  transform: scale(0) rotate(-6deg);
}

.Jungle-item-bg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #000;
  opacity: 0.6;
}

.Jungle-animation {
  position: absolute;
  width: 50%;
  height: auto;
  bottom: -25%;
  z-index: 20;
}

.Jungle-animation img {
  width: 100%;
  height: 100%;
}

/*Packages Horizontal Scroll*/
.Jungle-packages-movil {
  position: relative;
  overflow: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 30px 20px;
}

.Jungle-package-movil-container {
  display: inline-block;
  width: 260px;
  height: 530px;
  margin: 0 30px;
  border-radius: 150px;
  /* border: 1px solid #979797; */
  overflow: hidden;
  transition: all 0.3s ease-in 0s;
}

.Jungle-package-movil-container:hover {
  box-shadow: rgba(92, 84, 122, 0.14) 0px 4px 18px;
}

.Jungle-package-img-container {
  width: 260px;
  height: 250px;
  border-radius: 50%;
  background-image: url('../../img/jungle/integral_.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.package-one {
  background-image: url('../../img/jungle/integral_.jpg');
}

.package-two {
  background-image: url('../../img/jungle/medio_.jpg');
}

.package-three {
  background-image: url('../../img/jungle/basico_.jpg');
}

.package-four {
  background-image: url('../../img/jungle/micro_.jpg');
}

.package-five {
  background-image: url('../../img/jungle/rs_.jpg');
}

.Jungle-package-details {
  width: 95%;
  text-align: center;
  font-weight: 500;
  margin: 10px auto 10px auto;
  white-space: normal;
}

.Jungle-package-details p {
  font-size: 18px;
  margin: 0;
  color: #58595c;
}

.Jungle-package-details h4 {
  font-size: 18px;
  margin: 0;
  color: #f47600;
}

.Jungle-list-container {
  white-space: normal;
}

.Jungle-package-movil-container ul {
  width: 80%;
  list-style: none;
  padding: 0;
  margin: 0 auto;
}

.Jungle-list-container ul li {
  font-size: 15px;
  color: #58595c;
  text-align: center;
}

.Jungle-package-link-container {
  transform: translateY(88%);
  background-color: #f47600;
  padding: 21px 0;
  border-radius: 50%;
  text-align: center;
}

.Jungle-package-link {
  text-align: center;
  padding: 30px 0;
  color: #fff;
  font-weight: 500;
  text-decoration: none !important;
}

.Jungle-package-link:link,
.Jungle-package-link:active,
.Jungle-package-link:hover {
  color: #fff;
}
/*Botón categorias*/
.Jungle-movil-btn {
  text-align: center;
  margin-top: 30px;
}

.Jungle-btn {
  height: 64px;
  max-width: 360px;
}

/*Button animation*/
/* Shutter Out Horizontal */
.Jungle-btn .hvr-shutter-out-horizontal {
  text-align: center;
  height: 100%;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: #f47600;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  text-decoration: none !important;
  text-transform: uppercase;
  border-radius: 15px;
  color: #fff;
  font-weight: 500;
}

.Jungle-btn .hvr-shutter-out-horizontal:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #01355f;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  border-radius: 15px;
}

.Jungle-btn .hvr-shutter-out-horizontal:hover,
.Jungle-btn .hvr-shutter-out-horizontal:focus,
.Jungle-btn .hvr-shutter-out-horizontal:active {
  color: white;
}

.Jungle-btn .hvr-shutter-out-horizontal:hover:before,
.Jungle-btn .hvr-shutter-out-horizontal:focus:before,
.Jungle-btn .hvr-shutter-out-horizontal:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

/*Media Queries*/
@media screen and (min-width: 1921px) {
  .Jungle {
    padding: 2rem 0 4rem 0;
  }

  .Jungle-animation {
    bottom: -12%;
  }

  .Jungle-packages-movil {
    display: none;
  }
  .Jungle-movil-btn {
    display: none;
  }

  .Jungle-leave-one,
  .Jungle-leave-two {
    width: 100%;
    background-size: 90% 100%;
  }

  .Jungle-item {
    width: 250px;
    height: 250px;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1441px) {
  .Jungle-paragraph-container {
    width: 66%;
  }

  .Jungle-item-text p {
    font-size: 20px;
  }

  .Jungle-item {
    width: 250px;
    height: 250px;
  }

  .Jungle-animation {
    width: 37%;
    bottom: -28%;
  }

  .Jungle-packages-movil {
    display: none;
  }

  .Jungle-movil-btn {
    display: none;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .Jungle-animation {
    bottom: -28%;
  }

  .Jungle-packages-movil {
    display: none;
  }

  .Jungle-movil-btn {
    display: none;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .Jungle-packages-movil {
    display: none;
  }
  .Jungle-movil-btn {
    display: none;
  }
  .Jungle-paragraph-container {
    width: 50%;
  }
  .Jungle-title {
    font-size: 54px;
  }
  .Jungle-paragraph-container p {
    font-size: 19px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .Jungle-packages-movil {
    display: none;
  }
  .Jungle-movil-btn {
    display: none;
  }
  .Jungle-paragraph-container {
    width: 50%;
  }
  .Jungle-title {
    font-size: 54px;
  }
  .Jungle-paragraph-container p {
    font-size: 19px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .Jungle {
    padding: 4rem 0;
  }

  .Jungle-animation {
    display: none;
  }

  .movil-none {
    display: none;
  }

  .Jungle-items-container {
    display: none;
  }

  .Jungle-title {
    font-size: 35px;
  }

  .Jungle-paragraph-container p,
  .Jungle-package-details h4,
  .Jungle-btn-link-movil {
    font-size: 17px;
  }

  .Jungle-package-details p {
    font-size: 20px;
  }

  .Jungle-paragraph-container {
    width: 70%;
  }
  .Jungle-movil-btn {
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .Jungle {
    padding: 4rem 0;
  }

  .Jungle-animation {
    display: none;
  }

  .movil-none {
    display: none;
  }

  .Jungle-items-container {
    display: none;
  }

  .Jungle-title {
    font-size: 35px;
  }

  .Jungle-paragraph-container p,
  .Jungle-package-details h4,
  .Jungle-btn-link-movil {
    font-size: 17px;
  }

  .Jungle-package-details p {
    font-size: 20px;
  }

  .Jungle-paragraph-container {
    width: 70%;
  }
  .Jungle-movil-btn {
    margin: 0 auto;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .Jungle {
    padding: 4rem 0;
  }

  .Jungle-animation {
    display: none;
  }

  .movil-none {
    display: none;
  }

  .Jungle-items-container {
    display: none;
  }

  .Jungle-title {
    font-size: 24px;
  }

  .Jungle-paragraph-container p,
  .Jungle-package-details h4,
  .Jungle-btn-link-movil {
    font-size: 17px;
  }

  .Jungle-package-details p {
    font-size: 20px;
  }

  .Jungle-paragraph-container {
    width: 70%;
  }
  .Jungle-movil-btn {
    margin: 0 auto;
    width: 335px;
    height: 48px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .Jungle {
    padding: 4rem 0;
  }

  .Jungle-animation {
    display: none;
  }

  .movil-none {
    display: none;
  }

  .Jungle-items-container {
    display: none;
  }

  .Jungle-title {
    font-size: 24px;
  }

  .Jungle-paragraph-container {
    width: 100%;
  }

  .Jungle-paragraph-container p {
    font-size: 17px;
  }

  .Jungle-package-details h4,
  .Jungle-package-details p,
  .Jungle-btn-link-movil {
    font-size: 17px;
  }

  .Jungle-package-link-container {
    transform: translateY(94%);
  }

  .Jungle-movil-btn .Jungle-btn-link-movil {
    padding: 12px 85px !important;
  }
  .Jungle-movil-btn {
    margin: 0 auto;
    width: 335px;
    height: 48px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .Jungle {
    padding: 3rem 0;
  }

  .Jungle-animation {
    display: none;
  }

  .movil-none {
    display: none;
  }

  .Jungle-items-container {
    display: none;
  }

  .Jungle-title {
    font-size: 24px;
  }

  .Jungle-paragraph-container {
    width: 100%;
  }

  .Jungle-package-details h4,
  .Jungle-package-details p,
  .Jungle-btn-link-movil,
  .Jungle-paragraph-container p {
    font-size: 17px;
  }

  .Jungle-package-details h4,
  .Jungle-package-details p {
    font-size: 16px;
  }

  .Jungle-package-link-container {
    transform: translateY(94%);
  }

  .Jungle-movil-btn .Jungle-btn-link-movil {
    padding: 12px 85px !important;
  }
  .Jungle-movil-btn {
    margin: 0 auto;
    width: 335px;
    height: 48px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .Jungle {
    padding: 3rem 0;
  }

  .Jungle-animation {
    display: none;
  }

  .movil-none {
    display: none;
  }

  .Jungle-items-container {
    display: none;
  }

  .Jungle-title {
    font-size: 20px;
  }

  .Jungle-paragraph-container {
    width: 85%;
  }

  .Jungle-paragraph-container p {
    font-size: 14px;
  }

  .Jungle-package-details h4,
  .Jungle-package-details p {
    font-size: 14px;
  }

  .Jungle-package-link-container {
    transform: translateY(112%);
  }

  .Jungle-movil-btn .Jungle-btn-link-movil {
    padding: 12px 80px !important;
    font-size: 15px;
  }

  .Jungle-list-container ul li {
    font-size: 14px;
  }
  .Jungle-movil-btn {
    margin: 0 auto;
    width: 280px;
    height: 48px;
  }
}
