.NavbarBg {
  height: 100px;
  width: 100%;
  background-image: url('../../img/navbar/bgNav.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 5;
}

/*Media Queries*/
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .NavbarBg {
    display: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .NavbarBg {
    display: none;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .NavbarBg {
    display: none;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .NavbarBg {
    display: none;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .NavbarBg {
    display: none;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .NavbarBg {
    display: none;
  }
}
