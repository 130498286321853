.AssignProject {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  padding: 13px;
}

.AssignProject-title {
  opacity: 0.5;
  font-size: 15px;
  color: #000;
  margin: 0;
}

.AssignProject-btn {
  font-size: 15px;
  color: #f47600;
  border: none;
  background-color: transparent;
  text-decoration: underline;
}

.AssignProject-btn:focus {
  outline: none;
}
