.DropDownAdmin-main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.DropDownAdmin-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DropDownAdmin-options {
  position: absolute;
  width: 228px;
  height: 95px;
  border-radius: 15px;
  background-color: #ffffff;
  top: 9%;
  right: 1%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 16px 0px;
}

.DropDownAdmin-icon {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #f47600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DropDownAdmin-icon-initial {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}

.DropDownAdmin-arrow {
  margin-left: 6px;
}

.DropDownAdmin-options > ul {
  list-style: none;
  margin: 0;
  padding: 28px 0;
  width: 100%;
}

.DropDownAdmin-options > ul > li {
  text-align: center;
}

.DropDownAdmin-options > ul > li:hover {
  background-color: #fde4cc;
}

.DropDownAdmin-options > ul > li > button {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-align: center;
  color: #58595c;
  padding: 7px 0;
}

.DropDownAdmin-options > ul > li > button:hover {
  color: #f47600;
}

.DropDownAdmin-options > ul > li > button:focus {
  outline: none;
}
