.ProgressBar-container {
  position: relative;
  padding: 24px 0 48px 0;
}

.ProgressBar-list {
  padding: 0px 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.ProgressBar-item {
  text-align: center;
  font-size: 14px;
  color: #58595c;
  letter-spacing: 0.53px;
}

.ProgressBar-item:nth-child(4) {
  margin-right: 13px;
}

.ProgressBar-item:nth-child(5) {
  margin-right: 11px;
}

.ProgressBar-item:nth-child(6),
.ProgressBar-item:nth-child(7) {
  margin-right: 12px;
}

.ProgressBar-item span {
  display: block;
}

.ProgressBar {
  position: absolute;
  width: 100%;
  height: 3px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-color: transparent;
}

.ProgressBar-ball-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 20;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .ProgressBar-container {
    display: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .ProgressBar-container {
    display: none;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .ProgressBar-container {
    display: none;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .ProgressBar-container {
    display: none;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .ProgressBar-container {
    display: none;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .ProgressBar-container {
    display: none;
  }
}
