.ProfileInfo {
  padding: 12px 80px;
  height: 48px;
}

.ProfileInfo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.UserProfile-info-btn {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.53px;
  color: #ff0000;
  border: none;
  background-color: #fff;
}

.UserProfile-info-btn:focus {
  outline: none;
}

.ProfileInfo-paragraph-container-1 {
  width: 22%;
}

.ProfileInfo-paragraph-container-2 {
  width: 88%;
}

.ProfileInfo-paragraph {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.53px;
  color: #58595c;
}

/*Media Queries*/
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .ProfileInfo {
    padding: 15px 10px;
  }
  .ProfileInfo-container > div:first-child > p {
    font-size: 14px;
  }
  .ProfileInfo-container > div:last-child > p {
    font-size: 17px;
    color: #58595c;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .ProfileInfo {
    padding: 15px 10px;
  }
  .ProfileInfo-container > div:first-child > p {
    font-size: 14px;
  }
  .ProfileInfo-container > div:last-child > p {
    font-size: 17px;
    color: #58595c;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .ProfileInfo {
    padding: 15px 10px;
  }
  .ProfileInfo-container > div:first-child > p {
    font-size: 14px;
  }
  .ProfileInfo-container > div:last-child > p {
    font-size: 17px;
    color: #58595c;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .ProfileInfo {
    padding: 15px 10px;
  }
  .ProfileInfo-container > div:first-child > p {
    font-size: 14px;
  }
  .ProfileInfo-container > div:last-child > p {
    font-size: 17px;
    color: #58595c;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .ProfileInfo {
    padding: 15px 10px;
  }
  .ProfileInfo-container > div:first-child > p {
    font-size: 14px;
  }
  .ProfileInfo-container > div:last-child > p {
    font-size: 17px;
    color: #58595c;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .ProfileInfo {
    padding: 15px 10px;
  }
  .ProfileInfo-container > div:first-child > p {
    font-size: 14px;
  }
  .ProfileInfo-container > div:last-child > p {
    font-size: 17px;
    color: #58595c;
  }
}
