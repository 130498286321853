.AgencyParagraph {
  font-size: 28px;
  line-height: 1.14;
  text-align: center;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .AgencyParagraph {
    font-size: 25px;
    line-height: 1;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .AgencyParagraph {
    font-size: 23px;
    line-height: normal;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .AgencyParagraph {
    font-size: 17px;
    line-height: normal;
    margin: 0;
    font-weight: normal;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .AgencyParagraph {
    font-size: 17px;
    line-height: normal;
    margin: 0;
    font-weight: normal;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .AgencyParagraph {
    font-size: 17px;
    line-height: normal;
    margin: 0;
    font-weight: normal;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .AgencyParagraph {
    font-size: 17px;
    line-height: normal;
    margin: 0;
    font-weight: normal;
  }
}
