.PackageInterface {
  min-height: 75em;
}
.PackageInterface-main-container {
  padding: 0 0 7rem 0;
  margin-bottom: 16rem;
  max-width: 1200px;
}

.PackageInterface-row {
  height: 100%;
  margin: 80px 0;
  border-radius: 15px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
}

.PackageInterface-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PackageInterface-details {
  padding: 102px 33px 102px 0;
}

.PackageInterface-header {
  font-family: Ubuntu;
  font-size: 32px;
  font-weight: 500;
  line-height: 1;
  color: #58595c;
  margin-bottom: 25px;
}

.PackageInterface-paragraph {
  color: #58595c;
  font-size: 20px;
  text-align: justify;
  line-height: 1.5;
}

.PackageInterface-about {
  position: relative;
  width: 100%;
  height: 100%;
}

.PackageInerface-main-header {
  color: #58595c;
  text-align: center;
  font-size: 48px;
  font-weight: 600;
  margin: 0;
  padding: 32px 0 24px 0;
}

.PackageInerface-main-header span {
  display: block;
}

.PackageUser-container {
  position: absolute;
  left: 33px;
  width: 85.265%;
  height: 1165px;
  margin: 0 auto;
}

.PackageInterface-stages div h3 {
  font-size: 32px;
}

/*Media Queries*/
@media screen and (min-width: 1441px) {
  .PackageInterface {
    margin-bottom: 22%;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .PackageInterface {
    margin-bottom: 22%;
  }
  .PackageInterface-main-container {
    margin-bottom: 10rem;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .PackageInterface-main-container {
    max-width: 1126px;
  }
  .PackageInterface {
    margin-bottom: 22%;
  }
  .PackageInterface-main-container {
    margin-bottom: 7rem;
  }
  .PackageInterface-paragraph {
    font-size: 19px;
  }
  .PackageUser-container {
    height: 1165px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1250px) {
  .PackageInterface-main-container {
    max-width: 1140px;
  }
  .PackageInterface {
    margin-bottom: 22%;
  }
  .PackageInterface-main-container {
    margin-bottom: 7rem;
  }
  .PackageInterface-paragraph {
    font-size: 19px;
  }
  .PackageUser-container {
    height: 1165px;
  }
}

@media screen and (max-width: 1249px) and (min-width: 1190px) {
  .PackageUser-container {
    width: 80%;
  }
}

@media screen and (max-width: 1189px) and (min-width: 1024px) {
  .PackageInterface {
    padding: 32px 0;
  }
  .PackageInterface-main-container {
    max-width: 976px;
  }
  .PackageInerface-main-header {
    font-size: 40px;
  }
  .PackageInterface-header,
  .PackageInterface-stages div h3 {
    font-size: 24px;
  }
  .PackageInterface-paragraph {
    font-size: 17px;
  }
  .PackageUser-img {
    height: 390.5px;
  }
  .PackageUser-container {
    height: 1073px;
  }
}

@media screen and (max-width: 1019px) and (min-width: 768px) {
  .PackageInterface {
    padding: 32px 0;
  }
  .PackageInterface-main-container {
    margin-bottom: 0rem;
    padding: 0 0 10rem 0;
  }
  .PackageInterface-row {
    border-radius: 0;
    box-shadow: none !important;
    margin: 65px 0 0 0;
  }
  .PackageUser-container {
    position: initial;
    width: 75%;
    height: 1200px;
  }
  .PackageUser {
    border-radius: 15px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  }

  .PackageUser-img {
    border-radius: 15px 15px 0 0;
    height: 39%;
  }
  .PackageInerface-main-header {
    display: none;
  }
  .PackageInterface-container {
    width: 75%;
    margin: 0 auto;
  }
  .PackageInterface-details {
    padding: 32px 0;
  }
  .PackageInterface-stages {
    width: 75%;
    margin: 0 auto;
  }
  .adjust-moblile {
    margin: 0 !important;
  }
  .PackageInterface-paragraph {
    font-size: 19px;
  }
}

@media screen and (max-width: 767px) and (min-width: 736px) {
  .PackageInterface {
    padding: 32px 0;
  }
  .PackageInterface-main-container {
    margin-bottom: 2rem;
  }
  .PackageInterface-row {
    border-radius: 0;
    box-shadow: none !important;
    margin: 65px 0 0 0;
  }
  .PackageUser-container {
    position: initial;
    max-width: 368px;
    height: 447px;
  }
  .PackageUser {
    border-radius: 15px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  }

  .PackageUser-img {
    border-radius: 15px 15px 0 0;
    height: 43.18%;
  }
  .PackageInerface-main-header {
    display: none;
  }
  .PackageInterface-container {
    max-width: 368px;
    margin: 0 auto;
  }
  .PackageInterface-details {
    padding: 32px 0;
  }
  .PackageInterface-stages {
    max-width: 368px;
    margin: 0 auto;
  }
  .adjust-moblile {
    margin: 0 !important;
  }
  .PackageInterface-header,
  .PackageInterface-stages div h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .PackageInterface-paragraph {
    font-size: 17px;
  }
}

@media screen and (max-width: 735px) and (min-width: 667px) {
  .PackageInterface {
    padding: 32px 0;
  }
  .PackageInterface-main-container {
    margin-bottom: 2rem;
  }
  .PackageInterface-row {
    border-radius: 0;
    box-shadow: none !important;
    margin: 65px 0 0 0;
  }
  .PackageUser-container {
    position: initial;
    width: 329px;
    height: 447px;
  }
  .PackageUser {
    border-radius: 15px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  }

  .PackageUser-img {
    border-radius: 15px 15px 0 0;
    height: 43.18%;
  }
  .PackageInerface-main-header {
    display: none;
  }
  .PackageInterface-container {
    max-width: 329px;
    margin: 0 auto;
  }
  .PackageInterface-details {
    padding: 32px 0;
  }
  .PackageInterface-stages {
    max-width: 329px;
    margin: 0 auto;
  }
  .adjust-moblile {
    margin: 0 !important;
  }
  .PackageInterface-header,
  .PackageInterface-stages div h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .PackageInterface-paragraph {
    font-size: 17px;
  }
}

@media screen and (max-width: 666px) and (min-width: 640px) {
  .PackageInterface {
    padding: 32px 0;
  }
  .PackageInterface-main-container {
    margin-bottom: 2rem;
  }
  .PackageInterface-row {
    border-radius: 0;
    box-shadow: none !important;
    margin: 65px 0 0 0;
  }
  .PackageUser-container {
    position: initial;
    max-width: 314px;
    height: 447px;
  }
  .PackageUser {
    border-radius: 15px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  }

  .PackageUser-img {
    border-radius: 15px 15px 0 0;
    height: 43.18%;
  }
  .PackageInerface-main-header {
    display: none;
  }
  .PackageInterface-container {
    max-width: 314px;
    margin: 0 auto;
  }
  .PackageInterface-details {
    padding: 32px 0;
  }
  .PackageInterface-stages {
    max-width: 314px;
    margin: 0 auto;
  }
  .adjust-moblile {
    margin: 0 !important;
  }
  .PackageInterface-header,
  .PackageInterface-stages div h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .PackageInterface-paragraph {
    font-size: 17px;
  }
}

@media screen and (max-width: 639px) and (min-width: 569px) {
  .PackageInterface {
    padding: 32px 0;
  }
  .PackageInterface-main-container {
    margin-bottom: 2rem;
  }
  .PackageInterface-row {
    border-radius: 0;
    box-shadow: none !important;
    margin: 65px 0 0 0;
  }
  .PackageUser-container {
    position: initial;
    width: 97.193%;
    height: 1175px;
  }
  .PackageUser {
    border-radius: 15px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  }

  .PackageUser-img {
    border-radius: 15px 15px 0 0;
    height: 53%;
  }
  .PackageInerface-main-header {
    display: none;
  }
  .PackageInterface-container {
    max-width: 554px;
    margin: 0 auto;
  }
  .PackageInterface-details {
    padding: 32px 0;
  }
  .PackageInterface-stages {
    max-width: 554px;
    margin: 0 auto;
  }
  .adjust-moblile {
    margin: 0 !important;
  }
  .PackageInterface-header,
  .PackageInterface-stages div h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .PackageInterface-paragraph {
    font-size: 17px;
  }
}

@media screen and (max-width: 568px) and (min-width: 480px) {
  .PackageInterface {
    padding: 32px 0;
  }
  .PackageInterface-main-container {
    margin-bottom: 2rem;
  }
  .PackageInterface-row {
    border-radius: 0;
    box-shadow: none !important;
    margin: 65px 0 0 0;
  }
  .PackageUser-container {
    position: initial;
    max-width: 274px;
    height: 447px;
  }
  .PackageUser {
    border-radius: 15px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  }

  .PackageUser-img {
    border-radius: 15px 15px 0 0;
    height: 43.18%;
  }
  .PackageInerface-main-header {
    display: none;
  }
  .PackageInterface-container {
    max-width: 274px;
    margin: 0 auto;
  }
  .PackageInterface-details {
    padding: 32px 0;
  }
  .PackageInterface-stages {
    max-width: 274px;
    margin: 0 auto;
  }
  .adjust-moblile {
    margin: 0 !important;
  }
  .PackageInterface-header,
  .PackageInterface-stages div h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .PackageInterface-paragraph {
    font-size: 17px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .PackageInterface {
    padding: 32px 0;
  }
  .PackageInterface-main-container {
    margin-bottom: 2rem;
  }
  .PackageInterface-row {
    border-radius: 0;
    box-shadow: none !important;
    margin: 65px 0 0 0;
  }
  .PackageUser-container {
    position: initial;
    width: 95.835%;
    height: 800px;
  }
  .PackageUser {
    border-radius: 15px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  }

  .PackageUser-img {
    border-radius: 15px 15px 0 0;
    height: 240px;
  }
  .PackageInerface-main-header {
    display: none;
  }
  .PackageInterface-container {
    max-width: 368px;
    margin: 0 auto;
  }
  .PackageInterface-details {
    padding: 32px 0;
  }
  .PackageInterface-stages {
    max-width: 368px;
    margin: 0 auto;
  }
  .adjust-moblile {
    margin: 0 !important;
  }
  .PackageInterface-header,
  .PackageInterface-stages div h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .PackageInterface-paragraph {
    font-size: 17px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .PackageInterface {
    padding: 32px 0;
  }
  .PackageInterface-main-container {
    margin-bottom: 2rem;
  }
  .PackageInterface-row {
    border-radius: 0;
    box-shadow: none !important;
    margin: 65px 0 0 0;
  }
  .PackageUser-container {
    position: initial;
    width: 95.364%;
    height: 800px;
  }
  .PackageUser {
    border-radius: 15px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  }

  .PackageUser-img {
    border-radius: 15px 15px 0 0;
    height: 240px;
  }
  .PackageInerface-main-header {
    display: none;
  }
  .PackageInterface-container {
    max-width: 329px;
    margin: 0 auto;
  }
  .PackageInterface-details {
    padding: 32px 0;
  }
  .PackageInterface-stages {
    max-width: 329px;
    margin: 0 auto;
  }
  .adjust-moblile {
    margin: 0 !important;
  }
  .PackageInterface-header,
  .PackageInterface-stages div h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .PackageInterface-paragraph {
    font-size: 17px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .PackageInterface {
    padding: 32px 0;
  }
  .PackageInterface-main-container {
    margin-bottom: 2rem;
  }
  .PackageInterface-row {
    border-radius: 0;
    box-shadow: none !important;
    margin: 65px 0 0 0;
  }
  .PackageUser-container {
    position: initial;
    width: 95.156%;
    height: 800px;
  }
  .PackageUser {
    border-radius: 15px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  }

  .PackageUser-img {
    border-radius: 15px 15px 0 0;
    height: 240px;
  }
  .PackageInerface-main-header {
    display: none;
  }
  .PackageInterface-container {
    max-width: 314px;
    margin: 0 auto;
  }
  .PackageInterface-details {
    padding: 32px 0;
  }
  .PackageInterface-stages {
    max-width: 314px;
    margin: 0 auto;
  }
  .adjust-moblile {
    margin: 0 !important;
  }
  .PackageInterface-header,
  .PackageInterface-stages div h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .PackageInterface-paragraph {
    font-size: 17px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .PackageInterface {
    padding: 32px 0;
  }
  .PackageInterface-main-container {
    margin-bottom: 2rem;
  }
  .PackageInterface-row {
    border-radius: 0;
    box-shadow: none !important;
    margin: 65px 0 0 0;
  }
  .PackageUser-container {
    position: initial;
    width: 94.488%;
    height: 800px;
  }
  .PackageUser {
    border-radius: 15px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  }

  .PackageUser-img {
    border-radius: 15px 15px 0 0;
    height: 240px;
  }
  .PackageInerface-main-header {
    display: none;
  }
  .PackageInterface-container {
    max-width: 274px;
    margin: 0 auto;
  }
  .PackageInterface-details {
    padding: 32px 0;
  }
  .PackageInterface-stages {
    max-width: 274px;
    margin: 0 auto;
  }
  .adjust-moblile {
    margin: 0 !important;
  }
  .PackageInterface-header,
  .PackageInterface-stages div h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .PackageInterface-paragraph {
    font-size: 17px;
  }
}
