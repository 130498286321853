.QuestionAccordion-card {
  background-color: transparent;
  border: none;
  margin-bottom: 24px;
}

.QuestionAccordion-title {
  font-size: 32px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  color: #58595c;
  margin: 0;
}

.QuestionAccordion-answer {
  font-size: 32px;
  font-weight: 300;
  line-height: 1;
  color: #58595c;
  margin: 0 0 0 22px;
}

.QuestionAccordion-header {
  background-color: transparent;
  border-bottom: none;
  cursor: pointer;
}

.QuestionAccordion-header > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .QuestionAccordion-title,
  .QuestionAccordion-answer {
    font-size: 28px;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .QuestionAccordion-title,
  .QuestionAccordion-answer {
    font-size: 28px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .QuestionAccordion-title,
  .QuestionAccordion-answer {
    font-size: 17px;
  }
  .QuestionAccordion-answer {
    margin: 0;
  }
  .QuestionAccordion-header > div > img {
    width: 25px;
    height: auto;
    margin-right: 10px;
  }
  .QuestionAccordion-header {
    padding: 0;
  }
  .QuestionAccordion-body {
    padding: 24px 0 0 0 !important;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .QuestionAccordion-title,
  .QuestionAccordion-answer {
    font-size: 17px;
  }
  .QuestionAccordion-answer {
    margin: 0;
  }
  .QuestionAccordion-header > div > img {
    width: 25px;
    height: auto;
    margin-right: 10px;
  }
  .QuestionAccordion-header {
    padding: 0;
  }
  .QuestionAccordion-body {
    padding: 24px 0 0 0 !important;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .QuestionAccordion-title,
  .QuestionAccordion-answer {
    font-size: 17px;
  }
  .QuestionAccordion-answer {
    margin: 0;
  }
  .QuestionAccordion-header > div > img {
    width: 25px;
    height: auto;
    margin-right: 10px;
  }
  .QuestionAccordion-header {
    padding: 0;
  }
  .QuestionAccordion-body {
    padding: 24px 0 0 0 !important;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .QuestionAccordion-title,
  .QuestionAccordion-answer {
    font-size: 17px;
  }
  .QuestionAccordion-answer {
    margin: 0;
  }
  .QuestionAccordion-header > div > img {
    width: 25px;
    height: auto;
    margin-right: 10px;
  }
  .QuestionAccordion-header {
    padding: 0;
  }
  .QuestionAccordion-body {
    padding: 24px 0 0 0 !important;
  }
}
