.BriefDetails-card {
  border-radius: 15px !important;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: none;
  margin-bottom: 16px;
  cursor: pointer;
}

.BriefDetails-main-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.67px;
  color: #01355f;
}

.BriefDetails-header {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: none;
  background-color: #fff;
}

.BriefDetails-question {
  margin: 0;
}

.BriefDetails-question,
.BriefDetails-answer {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.53px;
  color: #58595c;
}

.BriefDetails-answer {
  font-weight: normal;
}

.BriefDetails-body {
  padding: 0 24px 24px 24px;
}

@media screen and (max-width: 1019px) and (min-width: 768px) {
  .BriefDetails-card {
    box-shadow: none !important;
    border: 1px solid #b3b3b3;
  }
  .BriefDetails-accordion > .card:not(:last-of-type) {
    border-bottom: 1px solid #b3b3b3;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .BriefDetails-main-title,
  .BriefDetails-question {
    font-size: 18px;
  }
  .BriefDetails-answer {
    font-size: 12px;
  }
  .BriefDetails-card {
    box-shadow: none;
    border: 1px solid #b3b3b3;
  }

  .BriefDetails-card {
    box-shadow: none !important;
    border: 1px solid #b3b3b3;
  }
  .BriefDetails-accordion > .card:not(:last-of-type) {
    border-bottom: 1px solid #b3b3b3;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .BriefDetails-main-title,
  .BriefDetails-question {
    font-size: 18px;
  }
  .BriefDetails-answer {
    font-size: 12px;
  }
  .BriefDetails-card {
    box-shadow: none !important;
    border: 1px solid #b3b3b3;
  }
  .BriefDetails-accordion > .card:not(:last-of-type) {
    border-bottom: 1px solid #b3b3b3;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .BriefDetails-main-title,
  .BriefDetails-question {
    font-size: 18px;
  }
  .BriefDetails-answer {
    font-size: 12px;
  }
  .BriefDetails-card {
    box-shadow: none !important;
    border: 1px solid #b3b3b3;
  }
  .BriefDetails-accordion > .card:not(:last-of-type) {
    border-bottom: 1px solid #b3b3b3;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .BriefDetails-main-title,
  .BriefDetails-question {
    font-size: 18px;
  }
  .BriefDetails-answer {
    font-size: 12px;
  }
  .BriefDetails-card {
    box-shadow: none !important;
    border: 1px solid #b3b3b3;
  }
  .BriefDetails-accordion > .card:not(:last-of-type) {
    border-bottom: 1px solid #b3b3b3;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .BriefDetails-main-title,
  .BriefDetails-question {
    font-size: 18px;
  }
  .BriefDetails-answer {
    font-size: 12px;
  }
  .BriefDetails-card {
    box-shadow: none !important;
    border: 1px solid #b3b3b3;
  }
  .BriefDetails-accordion > .card:not(:last-of-type) {
    border-bottom: 1px solid #b3b3b3;
  }
}
