.AgencyTitle {
  font-size: 64px;
  line-height: 1.63;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .AgencyTitle {
    font-size: 54px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .AgencyTitle {
    font-size: 54px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .AgencyTitle {
    font-size: 45px;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .AgencyTitle {
    font-size: 35px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .AgencyTitle {
    font-size: 24px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .AgencyTitle {
    font-size: 24px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .AgencyTitle {
    font-size: 24px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .AgencyTitle {
    font-size: 24px;
  }
}
