.Benefits {
  padding: 120px 0;
}

.Benefits-title {
  width: fit-content;
  margin: 0 auto;
}

.Benefits-title h2 {
  color: #58595c;
  text-align: center;
  font-size: 64px;
  margin-bottom: 24px;
  white-space: nowrap;
  border-right: 2px solid;
  width: 0%;
  overflow: hidden;
  visibility: hidden;
}

.Benefits-text {
  width: 45%;
  margin: 0 auto;
  transform: translateY(300px);
  opacity: 0;
}

.Benefits-text p {
  color: #b3b3b3;
  font-size: 24px;
  text-align: center;
}

.Benefit-text {
  opacity: 0;
  transform: translateY(50%);
}

.Benefit-text p {
  color: #58595c;
  font-size: 24px;
  text-align: center;
}

.Benefit-img {
  position: relative;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 61px;
}

.Benefit-img-one {
  width: 80%;
  height: 50%;
  margin: 0 auto;
  z-index: 5;
  transform: scale(0);
}

.Benefit-img-two {
  position: absolute;
  width: 60%;
  height: 77%;
  z-index: 10;
  transform: scale(0);
}

.Benefits-one,
.Benefits-two,
.Benefits-three,
.Benefits-four,
.Benefits-five,
.Benefits-six,
.Benefits-seven,
.Benefits-eight {
  background-image: url('../../img/benefits/one.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.Benefits-one {
  max-width: 195px;
  height: 96px;
}

.Benefits-two {
  background-image: url('../../img/benefits/one_.png');
  height: 152px;
}

.Benefits-three {
  background-image: url('../../img/benefits/two.png');
  max-width: 186px;
  max-height: 100px;
}

.Benefits-four {
  background-image: url('../../img/benefits/two_.png');
  max-width: 88px;
  max-height: 153px;
}

.Benefits-five {
  background-image: url('../../img/benefits/three.png');
  max-width: 232px;
  max-height: 99px;
}

.Benefits-six {
  background-image: url('../../img/benefits/three_.png');
  max-width: 130px;
}

.Benefits-seven {
  background-image: url('../../img/benefits/four.png');
  max-width: 218px;
  max-height: 104px;
}

.Benefits-eight {
  background-image: url('../../img/benefits/four_.png');
  max-width: 112px;
  max-height: 152px;
}

/*Media Queries*/
@media screen and (max-width: 1920px) and (min-width: 1441px) {
  .Benefits-text {
    width: 50%;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .Benefits-title h2 {
    font-size: 54px;
  }
  .Benefits-text {
    width: 40%;
  }
  .Benefits-text p,
  .Benefit-text p {
    font-size: 19px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .Benefits-title h2 {
    font-size: 54px;
  }
  .Benefits-text {
    width: 40%;
  }
  .Benefits-text p,
  .Benefit-text p {
    font-size: 19px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .Benefits-title h2 {
    font-size: 35px;
  }

  .Benefits-text {
    width: 55%;
  }
  .Benefits-text p,
  .Benefit-text p {
    font-size: 17px;
  }
  .Benefit-text p {
    width: 70%;
    margin: 0 auto;
  }
  .Benefit-img {
    margin-bottom: 37px;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .Benefits-title h2 {
    font-size: 35px;
  }

  .Benefits-text {
    width: 55%;
  }
  .Benefits-text p,
  .Benefit-text p {
    font-size: 17px;
  }
  .Benefit-text p {
    width: 100%;
    margin: 0 auto;
  }
  .Benefit-img {
    margin-bottom: 37px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .Benefits {
    padding: 48px 0;
  }
  .Benefits-title h2 {
    font-size: 24px;
  }

  .Benefits-text {
    width: 75%;
  }
  .Benefits-text p,
  .Benefit-text p {
    font-size: 17px;
  }
  .Benefit-text p {
    width: 100%;
    margin: 0 auto;
  }
  .Benefit-img {
    margin-bottom: 37px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .Benefits {
    padding: 48px 0;
  }
  .Benefits-title h2 {
    font-size: 24px;
  }

  .Benefits-text {
    width: 85%;
  }
  .Benefits-text p,
  .Benefit-text p {
    font-size: 17px;
  }
  .Benefit-text p {
    width: 100%;
    margin: 0 auto;
  }
  .Benefit-img {
    margin-bottom: 37px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .Benefits {
    padding: 48px 0;
  }
  .Benefits-title h2 {
    font-size: 24px;
  }

  .Benefits-text {
    width: 90%;
  }
  .Benefits-text p,
  .Benefit-text p {
    font-size: 17px;
  }
  .Benefit-text p {
    width: 100%;
    margin: 0 auto;
  }
  .Benefit-img {
    margin-bottom: 37px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .Benefits {
    padding: 3rem 0;
  }

  .Benefits-title h2 {
    font-size: 20px;
  }

  .Benefits-text {
    width: 85%;
  }

  .Benefit-img {
    height: 130px;
  }

  .Benefits-text p {
    font-size: 14px;
  }

  .Benefit-text p {
    font-size: 14px;
  }
}
