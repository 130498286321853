.BlueTitle {
  font-size: 48px;
  font-weight: 500;
  line-height: 1.17;
  text-align: center;
  color: #01355f;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .BlueTitle {
    font-size: 45px;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .BlueTitle {
    font-size: 35px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .BlueTitle {
    font-size: 24px;
    width: 80%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .BlueTitle {
    font-size: 24px;
    width: 80%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .BlueTitle {
    font-size: 24px;
    width: 100%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .BlueTitle {
    font-size: 24px;
    width: 100%;
    margin: 0 auto;
  }
}
