.ProjectAccordion {
  margin-bottom: 16px;
}

.ProjectAccordion-card {
  border-radius: 15px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: none;
}

.ProjectAccordion-card-header {
  background-color: #fff;
  border: none;
  padding: 24px;
  cursor: pointer;
}

.ProjectAccordion-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ProjectAccordion-title {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.67px;
}

.ProjectAccordion-card-body {
  padding: 0;
}

.ProjectAccordion-card-body > table {
  margin: 0;
  box-shadow: none;
}

.NoPaid {
  font-size: 20px;
  letter-spacing: 0.67px;
  color: #58595c;
  padding: 0 24px 24px 24px;
  margin: 0;
}
