.UserDashDetails {
  display: grid;
  grid-template-columns: 335px auto;
  grid-template-rows: repeat(1, 1fr);
  padding: 13px;
}

.UserDashDetails:nth-of-type(odd) {
  background-color: #f8f8f8;
}

.UserDashDetails-wrapper {
  margin-top: 38px;
}

.UserDashDetails-title {
  opacity: 0.5;
  font-size: 15px;
  color: #000;
  margin: 0;
}

.UserDashDetails-link,
.UserDashDetails-link:hover {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.53px;
  color: #f47600;
  text-decoration: underline;
}
