.CreateDeliverable-title {
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #000;
}

.CreateDeliverable-title-container{
  display: flex;
  justify-content: space-between;
}

.CreateDeliverable-form-wrapper {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.CreateDeliverable-textarea-wrapper {
  margin-top: 11px;
}

.CreateDeliverable-input-wrapper > div > label,
.CreateDeliverable-select-wrapper > div > label,
.CreateDeliverable-textarea-wrapper > div > label {
  font-size: 12px;
  letter-spacing: 0.4px;
  color: #000;
}

.CreateDeliverable-input-wrapper > div > input,
.CreateDeliverable-input-wrapper > div > input::placeholder {
  height: 40px;
  font-size: 12px;
  letter-spacing: 0.4px;
  color: #000;
  border: 1px solid #bfbfbf;
}

.CreateDeliverable-textarea-wrapper > div > textarea,
.CreateDeliverable-textarea-wrapper > div > textarea::placeholder {
  font-size: 12px;
  letter-spacing: 0.4px;
  color: #000;
  border: 1px solid #bfbfbf;
  height: 100px;
}

.CreateDeliverable-input-wrapper > div > input::placeholder,
.CreateDeliverable-textarea-wrapper > div > textarea::placeholder {
  border: none;
}
