.FormInicioSesion-recordarme {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 0 6px;
  margin: 0;
  font-weight: 500;
  color: #58595c;
}

.FormInicioSesion-restablecer,
.FormInicioSesion-restablecer:hover {
  font-size: 16px;
  text-align: right;
  color: #0091ff;
  text-decoration: none !important;
  font-weight: 300;
}

.FormInicioSesion-btn-container {
  text-align: center;
  height: 64px;
  margin: 0 auto;
}

.FormInicioSesion-btn-container button {
  width: 100%;
  height: 100%;
  border: 2px solid #f47600;
  border-radius: 15px;
  background-color: #fff;
  padding: 6px 79px;
  color: #f47600;
  font-weight: 500;
  font-size: 20px;
}

.FormInicioSesion-btn-container button:focus {
  outline: none !important;
}

.aligning-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.FormInicioSesion-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FormInicioSesion-link-container {
  margin: 8px 0 40px 0;
}

/*Checkbox*/
.FormRegistrarse-check {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px !important;
  height: 16px !important;
  border-radius: 2px;
  border: solid 1px #000;
  margin-right: 12px;
}

.check-input-form:checked
  ~ .FormInicioSesion-recordarme
  .FormRegistrarse-check {
  border-radius: 2px;
  background-color: #0091ff;
  border: solid 1px #0091ff;
}

/*Medía Queries*/
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .FormRegistrarseInput {
    height: 40px;
  }
  .FormInicioSesion-btn-container button {
    background-color: #ececec;
  }
  .FormInicioSesion-link-container {
    margin: 16px 0 40px 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .FormRegistrarseInput {
    height: 40px;
  }
  .FormInicioSesion-btn-container button {
    background-color: #ececec;
  }
  .FormInicioSesion-link-container {
    margin: 16px 0 40px 0;
  }
  .FormInicioSesion-recordarme,
  .FormInicioSesion-restablecer {
    font-size: 14px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .FormRegistrarseInput {
    height: 40px;
  }
  .FormInicioSesion-btn-container {
    height: 48px;
  }
  .FormInicioSesion-btn-container button {
    background-color: #ececec;
    font-size: 16px;
  }
  .FormRegistrarse-wrapper {
    margin-bottom: 10px;
  }
  .FormInicioSesion-restablecer {
    font-size: 14px;
  }
  .FormInicioSesion-link-container {
    margin: 16px 0 40px 0;
  }
  .error-message p {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .FormRegistrarseInput {
    height: 40px;
  }
  .FormInicioSesion-btn-container {
    height: 48px;
  }
  .FormInicioSesion-btn-container button {
    background-color: #ececec;
    font-size: 16px;
  }
  .FormRegistrarse-wrapper {
    margin-bottom: 10px;
  }
  .FormInicioSesion-restablecer {
    font-size: 14px;
  }
  .FormInicioSesion-link-container {
    margin: 16px 0 40px 0;
  }
  .error-message p {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .FormRegistrarseInput {
    height: 40px;
  }
  .FormInicioSesion-btn-container {
    height: 48px;
  }
  .FormInicioSesion-btn-container button {
    background-color: #ececec;
    font-size: 16px;
  }
  .FormRegistrarse-wrapper {
    margin-bottom: 10px;
  }
  .FormInicioSesion-restablecer {
    font-size: 14px;
  }
  .FormInicioSesion-link-container {
    margin: 16px 0 40px 0;
  }
  .error-message p {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .FormRegistrarseInput {
    height: 40px;
  }
  .FormInicioSesion-btn-container {
    height: 48px;
  }
  .FormInicioSesion-btn-container button {
    background-color: #ececec;
    font-size: 16px;
  }
  .FormRegistrarse-wrapper {
    margin-bottom: 10px;
  }
  .FormInicioSesion-restablecer {
    font-size: 14px;
  }
  .FormInicioSesion-link-container {
    margin: 16px 0 40px 0;
  }
  .error-message p {
    font-size: 14px !important;
  }
}
