.Dashboard-AgencyButton {
  width: 360px;
  height: 64px;
  border-radius: 10px;
  border: solid 2px #f47600;
  background-color: #fff;
  color: #f47600;
  font-weight: 500;
  font-size: 20px;
}

.Dashboard-AgencyButton:focus {
  outline: none;
}
