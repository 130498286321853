.DashboardPackageList {
  width: 100%;
  min-height: 100vh;
  background-color: #f2f2f2;
}

.DashboardPackageList-list {
  padding-bottom: 45px;
}

.DashboardPackageList-container {
  max-width: 996px;
  min-height: 100%;
  margin: 0 auto;
  padding: 50px 0;
}

.DashboardPackageList-container > div:first-child {
  padding: 0;
}

.DashboardPackageList-Link-wrapper {
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DashboardPackageList-Link-wrapper > div:first-child {
  width: 135px;
  height: 32px;
  background-color: #999;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DashboardPackageList-linebreak {
  width: 100%;
  height: 1px;
  opacity: 0.5;
  border: solid 1px #979797;
}

.DashboardPackage-mostrar {
  margin: 0;
  font-size: 15px;
  color: #000;
}

/*Media Queries*/
@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .DashboardPackageList-container {
    max-width: 922px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .DashboardPackageList-container {
    max-width: 836px;
  }
}
