.Deliverable-container {
  margin-bottom: 16px;
}

.Deliverable-title {
  font-size: 20px;
  font-weight: 500;
  color: #58595c;
  margin: 0;
}

.Deliverable-desc {
  object-fit: contain;
  font-size: 12px;
  line-height: 1.67;
  color: #b3b3b3;
  margin: 0;
}
