.CardMobile {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #b3b3b3 !important;
  border-radius: 0;
}

.CardMobile-header {
  background-color: #fff;
  border-bottom: none;
  padding: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CardMobile-header-title {
  font-size: 17px;
  font-weight: 500;
  line-height: 1.18;
  letter-spacing: 0.53px;
  color: #58595c;
  margin: 0;
}

.CardMobile-body {
  padding: 0;
}

.CardMobile-body-wrapper {
  padding: 13px;
  border-bottom: 1px solid #b3b3b3 !important;
}

.CardMobile-body-wrapper:first-child {
  border-top: 1px solid #b3b3b3 !important;
}

.CardMobile-body-wrapper:last-child,
.CardMobile-body-wrapper:nth-child(3) {
  border-bottom: none !important;
}

.CardMobile-body-title {
  font-size: 17px;
  font-weight: 500;
  line-height: 1.18;
  letter-spacing: 0.6px;
  color: #58595c;
  margin: 0;
}

.CardMobile-body-subtitle {
  font-size: 15px;
  line-height: 1.47;
  letter-spacing: 0.47px;
  color: #58595c;
  margin: 0;
}

.CardMobile-body-wicon {
  position: relative;
}

.CardMobile-body-icon {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 0;
}

.CardMobile-mgs-num {
  width: 24px;
  height: 24px;
  background-color: #007aff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 25px;
  position: absolute;
}

.CardMobile-mgs-num > p {
  color: #fff;
  margin: 0;
  font-size: 15px;
}
