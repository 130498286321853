.AddLink,
.AddLink:hover {
  font-size: 12px;
  letter-spacing: 0.4px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
