.EntregableDetails {
  padding: 3rem 0;
}

.buttonGoBack {
  background: none;
  border: none;
}

.buttonGoBack:focus {
  outline: none;
}

.EntregableDetails-paragraph p {
  color: #58595c;
  font-size: 14px;
  font-weight: 500;
}

.Dropzone-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.67px;
  color: #58595c;
}

.Dropzone-sec {
  width: 100%;
  height: 100px;
  border: 1px solid #b3b3b3;
  border-radius: 8px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Dropzone-sec > div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Dropzone-text-oragen {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.53px;
  text-align: center;
  color: #f47600;
  margin: 0;
  display: flex;
  align-items: center;
}

.Dropzone-text-grey {
  opacity: 0.25;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.53px;
  text-align: center;
  color: #000;
}

.Dropzone-fileName {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.47px;
  color: #f47600;
}

.UploadBtnWrapper {
  width: 360px;
  height: 64px;
  margin: 40px auto 0 auto;
}

/*Media Queries*/
@media screen and (min-width: 1441px) {
  .EntregableDetails {
    margin-bottom: 23rem;
  }
  .DropzoneIcon {
    display: none;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .EntregableDetails {
    margin-bottom: 17rem;
  }
  .DropzoneIcon {
    display: none;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .EntregableDetails {
    margin-bottom: 14.5rem;
  }
  .DropzoneIcon {
    display: none;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1250px) {
  .EntregableDetails {
    margin-bottom: 11.5rem;
  }
  .DropzoneIcon {
    display: none;
  }
}

@media screen and (max-width: 1249px) and (min-width: 1020px) {
  .EntregableDetails {
    margin-bottom: 17.5rem;
  }
  .DropzoneIcon {
    display: none;
  }
}

@media screen and (max-width: 1019px) and (min-width: 768px) {
  .EntregableDetails {
    margin-bottom: 6.5rem;
  }
  .Dropzone-text-grey {
    display: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .EntregableDetailsUser {
    margin-left: 0 !important;
  }
  .EntregableDetails {
    margin-bottom: 7.5rem;
    margin-left: 30px;
  }
  .Dropzone-text-grey {
    display: none;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .EntregableDetailsUser {
    margin-left: 0 !important;
  }
  .EntregableDetails {
    margin-bottom: 7.5rem;
    margin-left: 30px;
  }
  .Dropzone-text-grey {
    display: none;
  }
  .EntregableDetails {
    margin-bottom: 6rem !important;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .EntregableDetailsUser {
    margin-left: 0 !important;
    padding: 2rem 0;
  }
  .EntregableDetails {
    margin-bottom: 7.5rem;
    margin-left: 30px;
  }
  .Dropzone-text-grey {
    display: none;
  }
  .EntregableDetails {
    margin-bottom: 6rem !important;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .EntregableDetailsUser {
    margin-left: 0 !important;
    padding: 2rem 0;
  }
  .EntregableDetails {
    margin-bottom: 7.5rem;
    margin-left: 30px;
  }
  .Dropzone-text-grey {
    display: none;
  }
  .EntregableDetails {
    margin-bottom: 6rem !important;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .EntregableDetailsUser {
    margin-left: 0 !important;
    padding: 2rem 0;
  }
  .EntregableDetails {
    margin-bottom: 7.5rem;
    margin-left: 30px;
  }
  .Dropzone-text-grey {
    display: none;
  }
  .EntregableDetails {
    margin-bottom: 6rem !important;
  }
}
