.ProjectStagesList {
  max-width: 1200px;
  height: 100%;
  display: grid;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-radius: 15px;
}

.ProjectStagesList > div:nth-child(even) {
  background-color: #f8f8f8;
}
