.AgencyDetailsDash-headers {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  margin-top: 40px;
  padding: 13px;
  background-color: #f8f8f8;
}

.AgencyDetailsDash-title {
  font-size: 15px;
  font-weight: 500;
  color: #000;
  margin: 0;
}
