.EntregableDashDetails-options > div {
  box-shadow: none;
  border-radius: 0;
  margin: 0;
}

.EntregableDashDetails-title > h3 {
  font-size: 21px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: 0.7px;
  color: #58595c;
}
