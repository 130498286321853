.Dropdown {
  background-color: transparent !important;
  border: none !important;
}

.Dropdown:focus {
  outline: none !important;
  box-shadow: none !important;
}

.Dropdown-menu {
  width: 230px;
  transform: translate3d(-95px, 36px, 0px);
  border-radius: 15px;
}

.Dropdown-list {
  padding: 8px 0;
}

.Dropdown-item-container {
  width: 100%;
  text-align: center;
  padding: 7px 0;
}

.Dropdown-item-container:hover {
  background-color: #fde4cc;
}

.Dropdown-item-container:hover .Dropdown-link,
.Dropdown-item-container:hover .Dropdown-btn {
  color: #f47600 !important;
}

.Dropdown-link {
  padding: 10px 24px;
  color: #58595c;
  text-decoration: none !important;
}

.Dropdown-btn {
  border: none;
  background-color: transparent;
  color: #58595c;
}

.Dropdown-btn:focus {
  outline: none !important;
}
