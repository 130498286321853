.PrevNextButtons {
  border: none;
  background-color: transparent;
  font-size: 15px;
  color: #f47600;
}

.PrevNextButtons:focus {
  outline: none;
}

.PrevNextButtons:not(:last-child) {
  margin-right: 8px;
}
