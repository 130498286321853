.ProjectDetails-dash-wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(3, 1fr);
  padding: 40px 0;
}

.ProjectDetails-dash-item {
  display: grid;
  grid-template-columns: 290px auto;
  grid-template-rows: repeat(1, 1fr);
  padding: 13px 20px;
}

.ProjectDetails-dash-item:nth-child(odd) {
  background-color: #f8f8f8;
}

.ProjectDetails-dash-item-title {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.ProjectDetails-dash-item-subtitle {
  opacity: 0.5;
  font-size: 15px;
  color: #000;
}

.ProjectDetails-data,
.ProjectDetails-agency-list-container {
  background-color: #fff;
  padding: 40px 20px 0 20px;
  margin-bottom: 50px;
}

.ProjectDetails-agency-list-container {
  padding: 40px 20px;
  margin-bottom: 0;
}
