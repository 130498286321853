.PaymentDetails {
  margin-top: 32px;
}

.PaymentDetails-search {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.PaymentDetails-btns-dash {
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  margin-top: 28px;
}
