.EditAgency-btns-wrapper {
  max-width: 620px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.EditAgency-cancel,
.EditAgency-cancel:hover {
  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.67px;
  text-align: center;
  color: #ff0000;
}

.EditAgencyFormButton > button {
  background-color: #f47600;
  color: #fff;
}
