.PerksAgency {
  position: relative;
  width: 100%;
  height: 800px;
  padding: 0 0 4rem 0;
  background-color: #f0f0f0;
}

.PerksAgency-title-wrapper {
  transform: translateY(-74px);
}

.PerksAgency-island {
  position: absolute;
  width: 30%;
  height: 788px;
  right: 0;
  bottom: -15px;
  background-image: url('../../img/home-agency/agency-island.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
}

/*Slider*/
.Perks-slider-container {
  max-width: 690px;
}

.Perks-slider-img {
  height: 200px;
  width: 200px;
  background-image: url('../../img//jungle/integral_.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 50%;
}

.Perks-slider-wrapper {
  height: 200px;
  max-width: 600px;
  background-image: url('../../img/home-agency/slider-img.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 155px;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: 200px 400px;
  margin: 0 auto;
}

.Perks-slider-text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Perks-slider-text {
  font-size: 19px;
  letter-spacing: 0.8px;
  text-align: center;
  color: #ffffff;
  margin: 0 auto;
  width: 75%;
}

.Perks-slider-text > span {
  display: block;
}

.Perks-slider-up-arrow,
.Perks-slider-down-arrow {
  width: 40px;
  height: 40px;
  background-image: url('../../icons/agency-up-arrow-orange.svg');
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto;
  cursor: pointer;
}

.Perks-slider-down-arrow {
  background-image: url('../../icons/agency-down-arrow-orange.svg');
  margin-top: 15px;
}

.Perks-slider-wrapper {
  opacity: 0.2;
  transform: scale(0.9);
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .PerksAgency {
    padding: 6rem 0 6rem 0;
    height: 100%;
  }
  .PerksAgency-island {
    display: none;
  }
  .PerksAgency-title-wrapper {
    transform: translateY(0);
  }
  .Perks-slider-container {
    margin: 50px auto;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .PerksAgency {
    padding: 6rem 0 6rem 0;
    height: 100%;
  }
  .PerksAgency-island {
    display: none;
  }
  .PerksAgency-title-wrapper {
    transform: translateY(0);
  }
  .Perks-slider-container {
    margin: 50px auto 0 auto;
    max-width: 510px;
  }
  .Perks-slider-wrapper {
    max-width: 510px;
    grid-template-columns: 510px;
  }
  .Perks-slider-img {
    display: none;
  }
  .Perks-slider-text {
    width: 65%;
    font-size: 17px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .PerksAgency {
    padding: 6rem 0 6rem 0;
    height: 100%;
  }
  .PerksAgency-island {
    display: none;
  }
  .PerksAgency-title-wrapper {
    transform: translateY(0);
  }
  .Perks-slider-container {
    margin: 50px auto 0 auto;
    max-width: 370px;
  }
  .Perks-slider-wrapper {
    max-width: 350px;
    height: 150px;
    grid-template-columns: 350px;
  }
  .Perks-slider-img {
    display: none;
  }
  .Perks-slider-text {
    width: 65%;
    font-size: 15px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .PerksAgency {
    padding: 6rem 0 6rem 0;
    height: 100%;
  }
  .PerksAgency-island {
    display: none;
  }
  .PerksAgency-title-wrapper {
    transform: translateY(0);
  }
  .Perks-slider-container {
    margin: 50px auto 0 auto;
    max-width: 345px;
  }
  .Perks-slider-wrapper {
    max-width: 320px;
    height: 150px;
    grid-template-columns: 320px;
  }
  .Perks-slider-img {
    display: none;
  }
  .Perks-slider-text {
    width: 65%;
    font-size: 15px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .PerksAgency {
    padding: 6rem 0 6rem 0;
    height: 100%;
  }
  .PerksAgency-island {
    display: none;
  }
  .PerksAgency-title-wrapper {
    transform: translateY(0);
  }
  .Perks-slider-container {
    margin: 50px auto 0 auto;
    max-width: 330px;
  }
  .Perks-slider-wrapper {
    max-width: 300px;
    height: 150px;
    grid-template-columns: 300px;
  }
  .Perks-slider-img {
    display: none;
  }
  .Perks-slider-text {
    width: 65%;
    font-size: 13px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .PerksAgency {
    padding: 6rem 0 6rem 0;
    height: 100%;
  }
  .PerksAgency-island {
    display: none;
  }
  .PerksAgency-title-wrapper {
    transform: translateY(0);
  }
  .Perks-slider-container {
    margin: 50px auto 0 auto;
    max-width: 290px;
  }
  .Perks-slider-wrapper {
    max-width: 250px;
    height: 150px;
    grid-template-columns: 250px;
  }
  .Perks-slider-img {
    display: none;
  }
  .Perks-slider-text {
    width: 65%;
    font-size: 13px;
  }
}
