.modal-content {
  border-radius: 15px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.modal-dialog {
  max-width: 588px !important;
}

.modal-body {
  padding: 0 !important;
  margin-bottom: 29px;
}

.SignUp-body {
  max-width: 400px;
  margin: 0 auto;
}

.SignUp-btn-container {
  width: 100%;
  text-align: right;
  margin-top: 20px;
}

.SignUp-btn-container div {
  max-width: 550px;
  margin: 0 auto;
}

.SignUp-btn {
  border: none;
  background-color: #fff;
}

.SignUp-btn img {
  width: 20px;
  height: 20px;
}

.SignUp-btn:focus {
  outline: none !important;
}
