.aligning-items {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #58595c;
  font-weight: 700;
  margin: 8px 0 40px 0;
}

.FormRegistrarseInput {
  height: 50px;
}

.FormRegistrarse-terms {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.FormRegistrarse-btn-container {
  max-width: 360px;
  height: 64px;
  text-align: center;
  margin: 0 auto;
}

.FormRegistrarse-btn-container button {
  height: 100%;
  width: 100%;
  border: 2px solid #f47600;
  border-radius: 15px;
  background-color: #fff;
  padding: 6px 79px;
  color: #f47600;
  font-weight: 500;
  font-size: 20px;
}

.FormRegistrarse-btn-container button:focus {
  outline: none !important;
}

.form-control {
  border: 1px solid #000;
  color: #000000;
  border-radius: 4px;
  padding: 10px 16px;
}

.form-control::-webkit-input-placeholder {
  opacity: 0.24 !important;
  letter-spacing: 0.67px;
  color: #000;
}

.form-control:-ms-input-placeholder {
  opacity: 0.24 !important;
  letter-spacing: 0.67px;
  color: #000;
}

.form-control::placeholder {
  opacity: 0.24 !important;
  letter-spacing: 0.67px;
  color: #000;
}

.FormRegistrarse-wrapper {
  margin-bottom: 16px;
}

/*Checkbox*/
.FormRegistrarse-check {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px !important;
  height: 16px !important;
  border-radius: 2px;
  border: solid 1px #000;
  margin-right: 12px;
}

.check-input-form:checked ~ .FormRegistrarse-terms .FormRegistrarse-check {
  border-radius: 2px;
  background-color: #0091ff;
  border: solid 1px #0091ff;
}

/*Medía Queries*/
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .FormRegistrarseInput {
    height: 40px;
  }
  .FormRegistrarse-btn-container button {
    background-color: #ececec;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .FormRegistrarseInput {
    height: 40px;
  }
  .FormRegistrarse-btn-container button {
    background-color: #ececec;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .FormRegistrarseInput {
    height: 40px;
  }
  .FormRegistrarse-btn-container button {
    background-color: #ececec;
    font-size: 16px;
  }
  .FormRegistrarse-wrapper {
    margin-bottom: 10px;
  }
  .error-message p {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .FormRegistrarseInput {
    height: 40px;
  }
  .FormRegistrarse-btn-container button {
    background-color: #ececec;
    font-size: 16px;
  }
  .FormRegistrarse-wrapper {
    margin-bottom: 10px;
  }
  .error-message p {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .FormRegistrarseInput {
    height: 40px;
  }
  .FormRegistrarse-btn-container button {
    background-color: #ececec;
    font-size: 16px;
  }
  .FormRegistrarse-wrapper {
    margin-bottom: 10px;
  }
  .error-message p {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .FormRegistrarseInput {
    height: 40px;
  }
  .FormRegistrarse-btn-container button {
    background-color: #ececec;
    font-size: 16px;
  }
  .FormRegistrarse-wrapper {
    margin-bottom: 10px;
  }
  .error-message p {
    font-size: 14px !important;
  }
}
