.PackageName h1 {
  text-align: center;
  margin-top: 90px;
  font-size: 48px;
  font-weight: 500;
  line-height: 0.67;
  color: #58595c;
  margin-bottom: 8px;
}

.PackageName p {
  font-size: 20px;
  line-height: 1.6;
  text-align: center;
  color: #58595c;
}

@media screen and (max-width: 1030px) and (min-width: 1024px) {
  .PackageName h1 {
    text-align: center;
    margin-top: 35px;
    font-size: 45px;
    font-weight: 500;
    color: #58595c;
    margin-bottom: 8px;
  }
}
