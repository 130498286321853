.Dashboard-agencies-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Dashboard-agencies-link-wrapper {
  margin-left: 8px;
}

.Dashboard-agencies-search-wrapper {
  margin-top: 41px;
  margin-bottom: 21px;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.Dashboard-agencies-search {
  border: 1px solid #b3b3b3;
  font-size: 15px;
  color: #000;
  padding: 9px;
  border-radius: 5px;
}

.Dashboard-agencies-search:focus {
  outline: none;
}

.Dashboard-agencies-search::placeholder {
  opacity: 0.25;
}

.Dashboard-titles-wrapper,
.Dashboard-agency-option {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  background-color: #f8f8f8;
  padding: 13px 20px;
}

.Dashboard-table-title {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.Dashboard-agency-option:nth-child(odd) {
  background-color: #fff;
}

.Dashboard-agency-option:nth-child(even) {
  background-color: #f8f8f8 !important;
}

.Dashboard-agency-option:hover {
  background-color: #fef2e6;
}

.Dashboard-agency-option-data {
  opacity: 0.5;
  font-size: 15px;
  color: #000;
  margin: 0;
}

.Dashboard-AgencyList-link,
.Dashboard-AgencyList-link:hover {
  text-decoration: none;
}

.Dashboard-agency-list-switch {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Dashboard-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 29px;
}
