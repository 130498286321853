/*Media Queries*/
@media screen and (max-width: 767px) and (min-width: 480px) {
  .Title {
    font-size: 26px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .Title {
    font-size: 23px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .Title {
    font-size: 23px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .Title {
    font-size: 20px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .Title {
    font-size: 20px;
  }
}
