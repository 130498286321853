.FAQS {
  padding: 187px 0;
  background-color: #f0f0f0;
}

.FAQS-title-wrapper {
  margin-bottom: 80px;
}

.FAQS-accordion-wrapper {
  max-width: 800px;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .FAQS {
    padding: 48px 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .FAQS {
    padding: 48px 0;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .FAQS {
    padding: 48px;
  }
  .FAQS-title-wrapper {
    margin-bottom: 48px 0;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .FAQS {
    padding: 48px 0;
  }
  .FAQS-title-wrapper {
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .FAQS {
    padding: 48px 0;
  }
  .FAQS-title-wrapper {
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .FAQS {
    padding: 48px 0;
  }
  .FAQS-title-wrapper {
    margin-bottom: 48px;
  }
}
