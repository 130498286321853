.Header-agency {
  width: 100%;
  min-height: 140vh;
  background-image: url('../../img/home-agency/header-agency-blue.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top;
  background-color: #f0f0f0;
}

.Header-agency-container {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Header-agency-paragraph-wrapper {
  margin: 24px 0 40px 0;
}

.Header-agency-btn-container {
  width: 360px;
  height: 64px;
  border-radius: 15px;
}

/*Media Queries*/
@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .Header-agency {
    min-height: 120vh;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .Header-agency {
    min-height: 130vh;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .Header-agency {
    min-height: 100vh;
    background-size: cover;
  }
  .Header-agency-container {
    height: 100vh;
  }
  .Header-agency-paragraph-wrapper {
    margin: 24px 0 65px 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .Header-agency {
    min-height: 100vh;
    background-size: cover;
  }
  .Header-agency-container {
    height: 76vh;
  }
  .Header-agency-paragraph-wrapper {
    margin: 24px 0 65px 0;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .Header-agency {
    min-height: 100vh;
    background-image: url('../../img/home-agency/header_movil.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .Header-agency-container {
    height: 76vh;
  }
  .Header-agency-paragraph-wrapper {
    margin: 24px 0 65px 0;
  }
  .Header-agency-btn-container {
    max-width: 335px;
    height: 48px;
  }
  .Header-agency-btn-container > button {
    font-size: 18px;
    font-weight: 500;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .Header-agency {
    min-height: 100vh;
    background-image: url('../../img/home-agency/header_movil.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .Header-agency-container {
    height: 76vh;
  }
  .Header-agency-paragraph-wrapper {
    margin: 24px 0 65px 0;
  }
  .Header-agency-btn-container {
    max-width: 335px;
    height: 48px;
  }
  .Header-agency-btn-container > button {
    font-size: 18px;
    font-weight: 500;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .Header-agency {
    min-height: 100vh;
    background-image: url('../../img/home-agency/header_movil.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .Header-agency-container {
    height: 76vh;
  }
  .Header-agency-paragraph-wrapper {
    margin: 24px 0 65px 0;
  }
  .Header-agency-btn-container {
    max-width: 335px;
    height: 48px;
  }
  .Header-agency-btn-container > button {
    font-size: 18px;
    font-weight: 500;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .Header-agency {
    min-height: 100vh;
    background-image: url('../../img/home-agency/header_movil.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .Header-agency-container {
    height: 76vh;
  }
  .Header-agency-paragraph-wrapper {
    margin: 24px 0 65px 0;
  }
  .Header-agency-btn-container {
    max-width: 280px;
    height: 48px;
  }
  .Header-agency-btn-container > button {
    font-size: 18px;
    font-weight: 500;
  }
}
