.FooterAgency {
  width: 100%;
  height: 397px;
  background-image: url('../../img/home-agency/agency_footer.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 130px 0 0 0;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .FooterAgency {
    background-image: url('../../img/home-agency/Footer_movil.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .FooterAgency {
    background-image: url('../../img/home-agency/Footer_movil.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .FooterAgency {
    background-image: url('../../img/home-agency/Footer_movil.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding: 70px 0 0 0;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .FooterAgency {
    background-image: url('../../img/home-agency/Footer_movil.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding: 70px 0 0 0;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .FooterAgency {
    background-image: url('../../img/home-agency/Footer_movil.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding: 70px 0 0 0;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .FooterAgency {
    background-image: url('../../img/home-agency/Footer_movil.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding: 70px 0 0 0;
  }
}
