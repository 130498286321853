body {
  font-family: 'Ubuntu', sans-serif;
  overflow-x: hidden;
}

h1,
h2 {
  font-weight: 700;
}

h2 {
  font-size: 45px;
}

p,
h3 {
  font-weight: 400;
}

p {
  font-size: 18px;
}

/*Media Queries para pantallas mayores a 1920px de ancho*/
@media screen and (min-width: 1921px) {
  .App {
    max-width: 1440px;
    margin: 0 auto;
  }
}
