.Transfer {
  margin-bottom: 64px;
}

.Transfer-btn > div > button {
  background-color: transparent;
}

.Transfer-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.67px;
  color: #58595c;
}

.Transfer-subtitle {
  font-size: 20px;
  line-height: 2;
  letter-spacing: 0.67px;
  color: #58595c;
  margin: 0;
}

.Trasfer-email {
  color: #0091ff;
}

.Transfer-email-details {
  margin-top: 32px;
}

.Transfer-wrapper {
  padding: 0 16px;
}

@media screen and (min-width: 1441px) {
  .Transfer-modal-text {
    display: block;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .Transfer-modal-text {
    display: block;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .Transfer-modal-text {
    display: block;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .Transfer-modal-text {
    display: block;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .Transfer-subtitle {
    font-size: 16px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .Transfer-subtitle {
    font-size: 15px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .Transfer-subtitle {
    font-size: 15px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .Transfer-subtitle {
    font-size: 15px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .Transfer-subtitle {
    font-size: 15px;
  }
}
