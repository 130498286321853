.CreateStage {
  width: 996px;
  margin: 26px auto 0 auto;
  padding-bottom: 26px;
}

/*Media Queries*/
@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .CreateStage {
    width: 922px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .CreateStage {
    width: 836px;
  }
}
