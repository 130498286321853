.StagePayment {
  margin: 8px 8px 8px 0;
  max-width: 462px;
}

/* .StagePaymen:last-child {
  margin-bottom: 25px;
} */

.StagePayment:last-child {
  padding-bottom: 25px !important;
}

.StagePayment > div select,
.StagePayment > div select::placeholder {
  height: 49px;
  font-size: 12px;
  letter-spacing: 0.4px;
  color: #000;
  border: 1px solid #bfbfbf;
}

/*Media Queries*/
@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .StagePayment {
    max-width: 425px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .StagePayment {
    max-width: 382px;
  }
}
