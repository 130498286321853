.ProjectListAgency {
  margin: 0 0 17rem 0;
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .ProjectListAgency {
    margin: 0 0 12rem 0;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .ProjectListAgency {
    margin: 0 0 5.5rem 0;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .ProjectListAgency {
    margin: 0 0 5.5rem 0;
  }
}
