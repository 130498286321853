.Rhino {
  padding: 120px 0;
}

.Rhino-container {
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.Rhino-paragraph {
  max-width: 970px;
  height: 100%;
  margin: 0 auto;
}

.Rhino-paragraph > p,
.Rhino-firm > p {
  font-size: 40px;
  font-weight: 300;
  line-height: 1.65;
  text-align: center;
  color: #58595c;
  margin-top: 50px;
}

.Rhino-firm > p {
  font-size: 32px;
  font-weight: 500;
  text-align: right;
  color: #01355f;
  margin: 0;
}

.Rhino-join {
  margin: 30px 0 40px 0;
}

.Rhino-link {
  width: 360px;
  height: 64px;
  border-radius: 15px;
  background-color: #01355f;
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .Rhino-title {
    width: 93%;
    margin: 0 auto;
  }
  .Rhino-paragraph > p {
    font-size: 28px;
    width: 86%;
    margin: 50px auto 0 auto;
  }
  .Rhino-firm > p {
    width: 83%;
    margin: 0 auto;
    font-size: 28px;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .Rhino-title {
    width: 93%;
    margin: 0 auto;
  }
  .Rhino-paragraph > p {
    font-size: 28px;
    width: 86%;
    margin: 50px auto 0 auto;
  }
  .Rhino-firm > p {
    width: 83%;
    margin: 0 auto;
    font-size: 28px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .Rhino {
    padding: 48px 0;
  }
  .Rhino-title {
    width: 93%;
    margin: 0 auto;
  }
  .Rhino-paragraph > p {
    font-size: 15px;
    width: 86%;
    margin: 50px auto 0 auto;
  }
  .Rhino-firm > p {
    width: 83%;
    margin: 0 auto;
    font-size: 15px;
  }
  .Rhino-link {
    max-width: 335px;
    height: 48px;
  }

  .Rhino-link .AgencyLink {
    font-size: 16px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .Rhino {
    padding: 48px 0;
  }
  .Rhino-title {
    width: 93%;
    margin: 0 auto;
  }
  .Rhino-paragraph > p {
    font-size: 15px;
    width: 86%;
    margin: 50px auto 0 auto;
  }
  .Rhino-firm > p {
    width: 83%;
    margin: 0 auto;
    font-size: 15px;
  }
  .Rhino-link {
    max-width: 335px;
    height: 48px;
  }

  .Rhino-link .AgencyLink {
    font-size: 16px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .Rhino {
    padding: 48px 0;
  }
  .Rhino-title {
    width: 93%;
    margin: 0 auto;
  }
  .Rhino-paragraph > p {
    font-size: 15px;
    width: 86%;
    margin: 50px auto 0 auto;
  }
  .Rhino-firm > p {
    width: 83%;
    margin: 0 auto;
    font-size: 15px;
  }
  .Rhino-link {
    max-width: 335px;
    height: 48px;
  }

  .Rhino-link .AgencyLink {
    font-size: 16px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .Rhino {
    padding: 48px 0;
  }
  .Rhino-title {
    width: 93%;
    margin: 0 auto;
  }
  .Rhino-paragraph > p {
    font-size: 15px;
    width: 86%;
    margin: 50px auto 0 auto;
  }
  .Rhino-firm > p {
    width: 83%;
    margin: 0 auto;
    font-size: 15px;
  }
  .Rhino-link {
    max-width: 280px;
    height: 48px;
  }

  .Rhino-link .AgencyLink {
    font-size: 16px;
  }
}
