.Button-anim-container {
  max-width: 360px !important;
  height: 64px !important;
  margin: 0 auto;
}

.Button-anim {
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.67px;
  text-align: center;
  color: #f47600;
  border: 1px solid #f47600;
}

.Button-anim:focus {
  outline: none;
}

/*Button animation*/
/* Shutter Out Horizontal */
.Button-anim-hvr-shutter-out-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: #fff;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  text-decoration: none !important;
  text-transform: uppercase;
  padding: 12px 50px;
  border-radius: 16px;
  color: #f47600;
}

.Button-anim-hvr-shutter-out-horizontal:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f47600;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  border-radius: 15px;
}

.Button-anim-hvr-shutter-out-horizontal:hover,
.Button-anim-hvr-shutter-out-horizontal:focus,
.Button-anim-hvr-shutter-out-horizontal:active {
  color: white;
}

.Button-anim-hvr-shutter-out-horizontal:hover:before,
.Button-anim-hvr-shutter-out-horizontal:focus:before,
.Button-anim-hvr-shutter-out-horizontal:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

@media screen and (max-width: 767px) and (min-width: 736px) {
  .Button-anim-container {
    max-width: 289px !important;
    height: 48px !important;
  }
  .Button-anim {
    font-size: 16px;
  }
}

@media screen and (max-width: 735px) and (min-width: 667px) {
  .Button-anim-container {
    max-width: 289px !important;
    height: 48px !important;
  }
  .Button-anim {
    font-size: 16px;
  }
}

@media screen and (max-width: 666px) and (min-width: 601px) {
  .Button-anim-container {
    max-width: 289px !important;
    height: 48px !important;
  }
  .Button-anim {
    font-size: 16px;
  }
}

@media screen and (max-width: 568px) and (min-width: 480px) {
  .Button-anim-container {
    max-width: 289px !important;
    height: 48px !important;
  }
  .Button-anim {
    font-size: 16px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .Button-anim-container {
    max-width: 289px !important;
    height: 48px !important;
  }
  .Button-anim {
    font-size: 16px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .Button-anim-container {
    max-width: 289px !important;
    height: 48px !important;
  }
  .Button-anim {
    font-size: 16px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .Button-anim-container {
    max-width: 289px !important;
    height: 48px !important;
  }
  .Button-anim {
    font-size: 16px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .Button-anim-container {
    max-width: 289px !important;
    height: 48px !important;
  }
  .Button-anim {
    font-size: 16px;
  }
}
