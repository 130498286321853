.List {
  list-style: none;
  padding: 0;
}

.List-item {
  object-fit: contain;
  font-size: 12px;
  line-height: 1.67;
  color: #b3b3b3;
}
