.ProgressBar-ball {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #f47600;
  opacity: 0.2;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .ProgressBar-ball {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    opacity: 1;
    margin: 0 auto;
    color: #fff;
  }

  .ProgressBar-ball span {
    font-size: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .ProgressBar-ball {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    opacity: 1;
    margin: 0 auto;
    color: #fff;
  }

  .ProgressBar-ball span {
    font-size: 30px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .ProgressBar-ball {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    opacity: 1;
    margin: 0 auto;
    color: #fff;
  }

  .ProgressBar-ball span {
    font-size: 24px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .ProgressBar-ball {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    opacity: 1;
    margin: 0 auto;
    color: #fff;
  }

  .ProgressBar-ball span {
    font-size: 24px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .ProgressBar-ball {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    opacity: 1;
    margin: 0 auto;
    color: #fff;
  }

  .ProgressBar-ball span {
    font-size: 24px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .ProgressBar-ball {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    opacity: 1;
    margin: 0 auto;
    color: #fff;
  }

  .ProgressBar-ball span {
    font-size: 24px;
  }
}
