.TitleH5 {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.67px;
}

/*Media Queries*/
@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .TitleH5 {
    font-size: 19px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .TitleH5 {
    font-size: 19px;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .TitleH5 {
    font-size: 20px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .TitleH5 {
    font-size: 15px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .TitleH5 {
    font-size: 15px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .TitleH5 {
    font-size: 15px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .TitleH5 {
    font-size: 15px;
  }
}
