.DashboardAccordion-card {
  border: none;
  border-radius: 0;
}

.DashboardAccordion-title {
  font-size: 15px;
  letter-spacing: 0.5px;
  color: #000;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.DashboardAccordionMain{
  display: flex;
  justify-content: space-between;
}

.DashboardAccordion-header {
  background-color: #fff;
  border-bottom: none;
  padding: 0;
}

.DashboardAccordion-card-body {
  padding: 16px 0 0 0;
}

.DashboardAccordion-input-wrapper {
  max-width: 462px;
}

.DashboardAccordion-input-wrapper > div > label {
  font-size: 12px;
  letter-spacing: 0.4px;
  color: #000;
}

.DashboardAccordion-input-wrapper > div > input,
.DashboardAccordion-input-wrapper > div > input::placeholder {
  height: 49px;
  font-size: 12px;
  letter-spacing: 0.4px;
  color: #000;
  border: 1px solid #bfbfbf;
}

.DashboardAccordion-input-wrapper > div > input::placeholder {
  border: none;
}

.DashboardAccordion-deliverables-title {
  font-size: 15px;
  letter-spacing: 0.5px;
  color: #000;
  margin: 24px 0 0 0;
}

/*Media Queries*/
@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .DashboardAccordion-input-wrapper {
    max-width: 425px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .DashboardAccordion-input-wrapper {
    max-width: 382px;
  }
}
