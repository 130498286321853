.SingleProject {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  padding: 13px;
}

.SingleProject-title {
  opacity: 0.5;
  font-size: 15px;
  color: #000;
  margin: 0;
}

.SingleProject-link:nth-child(even) > div {
  background-color: #f8f8f8;
}

.SingleProject:hover,
.SingleProject-link:hover {
  background-color: #fef2e6;
}

.SingleProject-link {
  text-decoration: none !important;
}
