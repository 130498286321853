.EntregableOptions {
  padding: 15px 0;
  margin-bottom: 40px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.option {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px 24px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.53px;
  color: #58595c;
}

.optionWidth {
  width: 17%;
  font-weight: 500;
}

.option:nth-child(even) {
  background-color: #f8f8f8;
}

.option-title {
  padding: 10px 26px 21px 26px;
}

.option-title h5 {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: #58595c;
}

.file-link,
.file-link:hover,
.file-link:active {
  color: #f47600;
  text-decoration: none;
  font-weight: 600;
}

.EntregableOptions-subtitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.53px;
  color: #58595c;
  margin: 0;
}

.optionGap {
  max-width: 500px;
}

.EntregableOptions-list > ul li {
  color: #58595c;
}

.EntregableOptions-subtitle-mobile,
.optionGap-mobile {
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.47px;
  color: #58595c;
  margin: 0;
}

.EntregableOptions-subtitle-mobile > span {
  font-weight: 700;
}

.Delete-file-btn {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.53px;
  color: #ff0000;
  border: none;
  background-color: transparent;
  position: absolute;
  right: 53px;
}

.Delete-file-btn:focus {
  outline: none;
}

.file-link-mobile,
.file-link-mobile:hover {
  color: #f47600 !important;
}

.download-icon {
  position: absolute;
  right: 10%;
}

/*Media Queries*/
@media screen and (min-width: 1441px) {
  .EntregableOptions-subtitle-mobile,
  .optionGap-mobile,
  .Delete-file-btn-mobile {
    display: none;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .EntregableOptions-subtitle-mobile,
  .optionGap-mobile,
  .Delete-file-btn-mobile {
    display: none;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .EntregableOptions-subtitle-mobile,
  .optionGap-mobile,
  .Delete-file-btn-mobile {
    display: none;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .EntregableOptions-subtitle-mobile,
  .optionGap-mobile,
  .Delete-file-btn-mobile {
    display: none;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .EntregableOptions {
    border: 1px solid #b3b3b3;
    box-shadow: none;
  }
  .EntregableOptions-subtitle,
  .optionGap,
  .optionWidth,
  .Delete-file-btn-desktop {
    display: none;
  }
  .option {
    padding: 0 24px;
    text-align: left;
  }
  .option:first-child {
    flex-direction: column;
    padding: 16px 24px 0 24px;
  }
  .option:last-child {
    flex-direction: column;
    padding: 0px 24px 16px 24px;
  }
  .option:nth-child(even) {
    background-color: #fff;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .EntregableOptions {
    border: 1px solid #b3b3b3;
    box-shadow: none;
  }
  .EntregableOptions-subtitle,
  .optionGap,
  .optionWidth,
  .Delete-file-btn-desktop {
    display: none;
  }
  .option {
    padding: 0 24px;
    text-align: left;
  }
  .option:first-child {
    flex-direction: column;
    padding: 16px 24px 0 24px;
  }
  .option:last-child {
    flex-direction: column;
    padding: 0px 24px 16px 24px;
  }
  .option:nth-child(even) {
    background-color: #fff;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .EntregableOptions {
    border: 1px solid #b3b3b3;
    box-shadow: none;
  }
  .EntregableOptions-subtitle,
  .optionGap,
  .optionWidth,
  .Delete-file-btn-desktop {
    display: none;
  }
  .option {
    padding: 0 24px;
    text-align: left;
  }
  .option:first-child {
    flex-direction: column;
    padding: 16px 24px 0 24px;
  }
  .option:last-child {
    flex-direction: column;
    padding: 0px 24px 16px 24px;
  }
  .option:nth-child(even) {
    background-color: #fff;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .EntregableOptions {
    border: 1px solid #b3b3b3;
    box-shadow: none;
  }
  .EntregableOptions-subtitle,
  .optionGap,
  .optionWidth,
  .Delete-file-btn-desktop {
    display: none;
  }
  .option {
    padding: 0 24px;
    text-align: left;
  }
  .option:first-child {
    flex-direction: column;
    padding: 16px 24px 0 24px;
  }
  .option:last-child {
    flex-direction: column;
    padding: 0px 24px 16px 24px;
  }
  .option:nth-child(even) {
    background-color: #fff;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .EntregableOptions {
    border: 1px solid #b3b3b3;
    box-shadow: none;
  }
  .EntregableOptions-subtitle,
  .optionGap,
  .optionWidth,
  .Delete-file-btn-desktop {
    display: none;
  }
  .option {
    padding: 0 24px;
    text-align: left;
  }
  .option:first-child {
    flex-direction: column;
    padding: 16px 24px 0 24px;
  }
  .option:last-child {
    flex-direction: column;
    padding: 0px 24px 16px 24px;
  }
  .option:nth-child(even) {
    background-color: #fff;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .EntregableOptions {
    border: 1px solid #b3b3b3;
    box-shadow: none;
  }
  .EntregableOptions-subtitle,
  .optionGap,
  .optionWidth,
  .Delete-file-btn-desktop {
    display: none;
  }
  .option {
    padding: 0 24px;
    text-align: left;
  }
  .option:first-child {
    flex-direction: column;
    padding: 16px 24px 0 24px;
  }
  .option:last-child {
    flex-direction: column;
    padding: 0px 24px 16px 24px;
  }
  .option:nth-child(even) {
    background-color: #fff;
  }
}
