.RecurrentDeliverables {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px;
  border-bottom: 1px solid #b3b3b3;
}

.RecurrentDeliverables-title {
  margin: 0;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.18;
  letter-spacing: 0.53px;
  color: #58595c;
}
