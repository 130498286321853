.MenuButton {
  width: 100%;
  border: none;
  background-color: transparent;
  padding: 10px;
  font-size: 15px;
  color: #f47600;
  border-bottom: 4px solid #f47600;
}

.MenuButton:disabled {
  width: 100%;
  opacity: 0.25;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  border-bottom: 1px solid #e6e6e6;
}

.MenuButton:focus {
  outline: none;
}
