.Payment-project {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(4, 1fr);
  padding: 24px;
}

.Payment-project:nth-child(even) {
  background-color: #f8f8f8;
}

.Payment-project:first-child {
  border-radius: 15px 15px 0 0;
}

.Payment-project:last-child {
  border-radius: 0 0 15px 15px;
}

.Payment-title-project {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.67px;
  color: #58595c;
  margin: 0;
}

.Payment-subtitle-project {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.53px;
  color: #58595c;
  margin: 0;
}

.Payment-align-project {
  text-align: left;
}

.Payment-status-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Payment-status {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.53px;
  text-align: center;
  color: #58595c;
  margin: 0;
}

.Payment-link-underline-container {
  width: 100%;
  text-align: center;
}

.Payment-link-underline,
.Payment-link-underline:hover {
  color: #f47600;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.53px;
  text-decoration: underline;
}

.Payment-title-proyect-movil {
  font-size: 14px;
  margin: 0;
  color: #58595c;
}

.Payment-link-underline-movil,
.Payment-link-underline-movil:hover {
  color: #f47600;
  text-decoration: underline;
}

.Payment-title-proyect-movil > span {
  font-weight: 700;
}

.font-movil {
  font-size: 18px;
}

/*Media Queries*/
@media screen and (min-width: 1441px) {
  .Payment-title-proyect-movil,
  .Payment-link-underline-movil {
    display: none;
  }
}
@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .Payment-title-proyect-movil,
  .Payment-link-underline-movil {
    display: none;
  }
}
@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .Payment-title-proyect-movil,
  .Payment-link-underline-movil {
    display: none;
  }
}
@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .Payment-title-proyect-movil,
  .Payment-link-underline-movil {
    display: none;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .Payment-title-proyect-movil,
  .Payment-link-underline-movil {
    display: none;
  }
}
@media screen and (max-width: 767px) and (min-width: 480px) {
  .Payment-project {
    grid-template-columns: repeat(1, 1fr);
    border: 1px solid #b3b3b3;
    border-radius: 15px;
    margin-bottom: 16px;
    padding: 16px 24px;
  }
  .Payment-project:first-child,
  .Payment-project:last-child {
    border-radius: 15px;
  }
  .Payment-project > div > div:last-child {
    margin-bottom: 10px;
  }
  .Payment-project:nth-child(even) {
    background-color: #fff;
  }
  .Payment-title-project,
  .Payment-subtitle-project,
  .Payment-status,
  .Payment-link-underline {
    display: none;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .Payment-project {
    grid-template-columns: repeat(1, 1fr);
    border: 1px solid #b3b3b3;
    border-radius: 15px;
    margin-bottom: 16px;
    padding: 16px 24px;
  }
  .Payment-project:first-child,
  .Payment-project:last-child {
    border-radius: 15px;
  }
  .Payment-project > div > div:last-child {
    margin-bottom: 10px;
  }
  .Payment-project:nth-child(even) {
    background-color: #fff;
  }
  .Payment-title-project,
  .Payment-subtitle-project,
  .Payment-status,
  .Payment-link-underline {
    display: none;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .Payment-project {
    grid-template-columns: repeat(1, 1fr);
    border: 1px solid #b3b3b3;
    border-radius: 15px;
    margin-bottom: 16px;
    padding: 16px 24px;
  }
  .Payment-project:first-child,
  .Payment-project:last-child {
    border-radius: 15px;
  }
  .Payment-project > div > div:last-child {
    margin-bottom: 10px;
  }
  .Payment-project:nth-child(even) {
    background-color: #fff;
  }
  .Payment-title-project,
  .Payment-subtitle-project,
  .Payment-status,
  .Payment-link-underline {
    display: none;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .Payment-project {
    grid-template-columns: repeat(1, 1fr);
    border: 1px solid #b3b3b3;
    border-radius: 15px;
    margin-bottom: 16px;
    padding: 16px 24px;
  }
  .Payment-project:first-child,
  .Payment-project:last-child {
    border-radius: 15px;
  }
  .Payment-project > div > div:last-child {
    margin-bottom: 10px;
  }
  .Payment-project:nth-child(even) {
    background-color: #fff;
  }
  .Payment-title-project,
  .Payment-subtitle-project,
  .Payment-status,
  .Payment-link-underline {
    display: none;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .Payment-project {
    grid-template-columns: repeat(1, 1fr);
    border: 1px solid #b3b3b3;
    border-radius: 15px;
    margin-bottom: 16px;
    padding: 16px 24px;
  }
  .Payment-project:first-child,
  .Payment-project:last-child {
    border-radius: 15px;
  }
  .Payment-project > div > div:last-child {
    margin-bottom: 10px;
  }
  .Payment-project:nth-child(even) {
    background-color: #fff;
  }
  .Payment-title-project,
  .Payment-subtitle-project,
  .Payment-status,
  .Payment-link-underline {
    display: none;
  }
}
