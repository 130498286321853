.PackageUser {
  width: 100%;
  height: 100%;
  background-color: #f47600;
  border-radius: 249px 249px 15px 15px;
}

.PackageUser-img {
  width: 100%;
  height: 486px;
  border-radius: 50%;
  background-color: #eee;
  transform: translateY(-4px);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.PackageUser-info-container {
  width: 100%;
  height: 40%;
  padding: 24px 63px 56px 63px;
  color: #fff;
  text-align: center;
}

.PackageUser-info-container p {
  font-weight: 300;
  font-size: 21px;
}

.PackageUser-info-container h4 {
  font-weight: 400;
  font-size: 32px;
}

.PackageUser-price {
  font-size: 32px;
}

.PackageUser-link-container {
  margin-top: 21px;
  text-align: center;
  max-width: 360px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PackageUser-link,
.PackageUser-bnt {
  color: #f47600;
  background-color: #fff;
  border-radius: 15px;
  font-weight: 500;
  text-decoration: none !important;
  font-size: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PackageUser-link:hover {
  color: #f47600;
  text-decoration: none !important;
}

.PackageUser-bnt {
  border: none;
}

.PackageUser-bnt:focus {
  outline: none;
}

.PackageUder-payments-details {
  margin-bottom: 24px;
  font-size: 21px;
  font-weight: 500 !important;
  letter-spacing: 0.7px;
  text-align: center;
  color: #ffffff;
}

/*Media Queries*/
@media screen and (min-width: 1441px) {
  .PackageUser-main-header {
    display: none;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .PackageUser-main-header {
    display: none;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .PackageUser-main-header {
    display: none;
  }
  .PackageUser-img {
    height: 465px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1250px) {
  .PackageUser-main-header {
    display: none;
  }
  .PackageUser-img {
    height: 473px;
  }
}

@media screen and (max-width: 1249px) and (min-width: 1020px) {
  .PackageUser-main-header {
    display: none;
  }
}

@media screen and (max-width: 1019px) and (min-width: 768px) {
  .PackageUser-main-header span {
    font-size: 32px;
    margin-right: 6px;
  }
  .PackageUser-info-container {
    padding: 12px 20px;
    text-align: left;
  }
  .PackageUser-link-container {
    margin: 45px auto 0 auto;
  }
  .PackageUser-link,
  ..PackageUser-bnt {
    border-radius: 12px;
  }
  .PackageUder-payments-details {
    text-align: left;
  }
}

@media screen and (max-width: 767px) and (min-width: 736px) {
  .PackageUser-main-header span {
    font-size: 24px;
    margin-right: 6px;
    display: block;
  }
  .PackageUser-info-container {
    max-width: 320px;
    margin: 0 auto;
    padding: 16px 0;
    text-align: left;
  }
  .PackageUser-price {
    font-size: 19px;
  }
  .PackageUser-link-container {
    margin: 24px auto 0 auto;
    height: 48px;
    max-width: 320px;
  }
  .PackageUser-link,
  .PackageUser-bnt {
    border-radius: 12px;
    font-size: 16px;
  }
  .PackageUser-info-container h4 {
    font-size: 19px;
  }
  .PackageUser-info-container p {
    font-size: 15px;
  }
  .PackageInterface-header,
  .PackageInterface-stages div h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .PackageInterface-paragraph {
    font-size: 17px;
  }
  .PackageUder-payments-details {
    text-align: left;
  }
}

@media screen and (max-width: 735px) and (min-width: 667px) {
  .PackageUser-main-header span {
    font-size: 24px;
    margin-right: 6px;
    display: block;
  }
  .PackageUser-info-container {
    max-width: 281px;
    margin: 0 auto;
    padding: 16px 0;
    text-align: left;
  }
  .PackageUser-price {
    font-size: 19px;
  }
  .PackageUser-link-container {
    margin: 24px auto 0 auto;
    height: 48px;
    max-width: 289px;
  }
  .PackageUser-link,
  .PackageUser-bnt {
    border-radius: 12px;
    font-size: 16px;
  }
  .PackageUser-info-container h4 {
    font-size: 19px;
  }
  .PackageUser-info-container p {
    font-size: 15px;
  }
  .PackageInterface-header,
  .PackageInterface-stages div h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .PackageInterface-paragraph {
    font-size: 17px;
  }
  .PackageUder-payments-details {
    text-align: left;
  }
}

@media screen and (max-width: 666px) and (min-width: 640px) {
  .PackageUser-main-header span {
    font-size: 24px;
    margin-right: 6px;
    display: block;
  }
  .PackageUser-info-container {
    max-width: 266px;
    margin: 0 auto;
    padding: 16px 0;
    text-align: left;
  }
  .PackageUser-price {
    font-size: 19px;
  }
  .PackageUser-link-container {
    margin: 24px auto 0 auto;
    height: 48px;
    max-width: 289px;
  }
  .PackageUser-link,
  .PackageUser-bnt {
    border-radius: 12px;
    font-size: 16px;
  }
  .PackageUser-info-container h4 {
    font-size: 19px;
  }
  .PackageUser-info-container p {
    font-size: 15px;
  }
  .PackageInterface-header,
  .PackageInterface-stages div h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .PackageInterface-paragraph {
    font-size: 17px;
  }
  .PackageUder-payments-details {
    text-align: left;
  }
}

@media screen and (max-width: 639px) and (min-width: 569px) {
  .PackageUser-main-header span {
    font-size: 24px;
    margin-right: 6px;
  }
  .PackageUser-info-container {
    padding: 12px 24px;
    text-align: left;
  }
  .PackageUser-price {
    font-size: 19px;
  }
  .PackageUser-link-container {
    margin: 24px auto 0 auto;
    height: 48px;
  }
  .PackageUser-link,
  .PackageUser-bnt {
    border-radius: 12px;
    font-size: 16px;
  }
  .PackageUser-info-container h4 {
    font-size: 19px;
  }
  .PackageUser-info-container p {
    font-size: 15px;
  }
  .PackageUder-payments-details {
    text-align: left;
  }
}

@media screen and (max-width: 568px) and (min-width: 480px) {
  .PackageUser-main-header span {
    font-size: 24px;
    margin-right: 6px;
    display: block;
  }
  .PackageUser-info-container {
    max-width: 226px;
    margin: 0 auto;
    padding: 16px 0;
    text-align: left;
  }
  .PackageUser-price {
    font-size: 19px;
  }
  .PackageUser-link-container {
    margin: 24px auto 0 auto;
    height: 48px;
    max-width: 289px;
  }
  .PackageUser-link,
  .PackageUser-bnt {
    border-radius: 12px;
    font-size: 16px;
  }
  .PackageUser-info-container h4 {
    font-size: 19px;
  }
  .PackageUser-info-container p {
    font-size: 15px;
  }
  .PackageInterface-header,
  .PackageInterface-stages div h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .PackageInterface-paragraph {
    font-size: 17px;
  }
  .PackageUder-payments-details {
    text-align: left;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .PackageUser-main-header span {
    font-size: 24px;
    margin-right: 6px;
    display: block;
  }
  .PackageUser-info-container {
    max-width: 320px;
    margin: 0 auto;
    padding: 16px 0;
    text-align: left;
  }
  .PackageUser-price {
    font-size: 19px;
  }
  .PackageUser-link-container {
    margin: 24px auto 0 auto;
    height: 48px;
    max-width: 289px;
  }
  .PackageUser-link,
  .PackageUser-bnt {
    border-radius: 12px;
    font-size: 16px;
  }
  .PackageUser-info-container h4 {
    font-size: 19px;
  }
  .PackageUser-info-container p {
    font-size: 15px;
    margin-bottom: 16px;
  }
  .PackageInterface-header,
  .PackageInterface-stages div h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .PackageInterface-paragraph {
    font-size: 17px;
  }
  .PackageUder-payments-details {
    text-align: left;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .PackageUser-main-header span {
    font-size: 24px;
    margin-right: 6px;
    display: block;
  }
  .PackageUser-info-container {
    max-width: 281px;
    margin: 0 auto;
    padding: 16px 0;
    text-align: left;
  }
  .PackageUser-price {
    font-size: 19px;
  }
  .PackageUser-link-container {
    margin: 24px auto 0 auto;
    height: 48px;
    max-width: 289px;
  }
  .PackageUser-link,
  .PackageUser-bnt {
    border-radius: 12px;
    font-size: 16px;
  }
  .PackageUser-info-container h4 {
    font-size: 19px;
  }
  .PackageUser-info-container p {
    font-size: 15px;
  }
  .PackageInterface-header,
  .PackageInterface-stages div h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .PackageInterface-paragraph {
    font-size: 17px;
  }
  .PackageUder-payments-details {
    text-align: left;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .PackageUser-main-header span {
    font-size: 24px;
    margin-right: 6px;
    display: block;
  }
  .PackageUser-info-container {
    max-width: 266px;
    margin: 0 auto;
    padding: 16px 0;
    text-align: left;
  }
  .PackageUser-price {
    font-size: 19px;
  }
  .PackageUser-link-container {
    margin: 24px auto 0 auto;
    height: 48px;
    max-width: 289px;
  }
  .PackageUser-link,
  .PackageUser-bnt {
    border-radius: 12px;
    font-size: 16px;
  }
  .PackageUser-info-container h4 {
    font-size: 19px;
  }
  .PackageUser-info-container p {
    font-size: 15px;
  }
  .PackageInterface-header,
  .PackageInterface-stages div h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .PackageInterface-paragraph {
    font-size: 17px;
  }
  .PackageUder-payments-details {
    text-align: left;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .PackageUser-main-header span {
    font-size: 24px;
    margin-right: 6px;
    display: block;
  }
  .PackageUser-info-container {
    max-width: 226px;
    margin: 0 auto;
    padding: 16px 0;
    text-align: left;
  }
  .PackageUser-price {
    font-size: 19px;
  }
  .PackageUser-link-container {
    margin: 24px auto 0 auto;
    height: 48px;
    max-width: 289px;
  }
  .PackageUser-link,
  .PackageUser-bnt {
    border-radius: 12px;
    font-size: 16px;
  }
  .PackageUser-info-container h4 {
    font-size: 19px;
  }
  .PackageUser-info-container p {
    font-size: 15px;
  }
  .PackageInterface-header,
  .PackageInterface-stages div h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .PackageInterface-paragraph {
    font-size: 17px;
  }
  .PackageUder-payments-details {
    text-align: left;
    margin-bottom: 16px;
  }
}
