.Header-categories {
  position: relative;
  width: 100%;
  height: 525px;
}

.Header-categories-bg-grey {
  position: absolute;
  height: 525px;
  top: 0;
  width: 100%;
  background-color: #b3b3b3;
  opacity: 0.2;
}

.Header-categories-bg {
  width: 100%;
  height: 60px;
  background-color: #f47600;
}

.Header-categories-island {
  position: absolute;
  width: 780px;
  height: 355px;
  bottom: 0;
  left: 0;
  background-image: url('../../../img/categories/categories-island.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom left;
}

/*Media Queries*/
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .Header-categories {
    display: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .Header-categories {
    display: none;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .Header-categories {
    display: none;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .Header-categories {
    display: none;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .Header-categories {
    display: none;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .Header-categories {
    display: none;
  }
}
