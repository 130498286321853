.Input-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.Input-label {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.67px;
  color: #58595c;
}

.Input {
  width: 100%;
  height: 50px;
  padding: 10px 16px;
  border-radius: 4px;
  border: solid 1px #000;
  background-color: #fff;
  font-size: 20px;
  color: #58595c;
}

.Input:focus {
  border-color: #f47600;
  box-shadow: 0 0 0 0.2rem rgba(244, 118, 0, 0.25);
  outline: none;
}

.Input-message {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.6px;
  color: #58595c;
  margin-top: 16px;
}

.Input::-webkit-input-placeholder {
  opacity: 0.24;
  font-size: 20px;
  letter-spacing: 0.67px;
  color: #000;
}

.Input:-ms-input-placeholder {
  opacity: 0.24;
  font-size: 20px;
  letter-spacing: 0.67px;
  color: #000;
}

.Input::placeholder {
  opacity: 0.24;
  font-size: 20px;
  letter-spacing: 0.67px;
  color: #000;
}

.Input-error {
  margin-top: 9px;
  color: red;
}

/*Media Queries*/
@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .Input-label {
    font-size: 19px;
  }
  .Input {
    font-size: 19px;
  }
  .Input::-webkit-input-placeholder {
    font-size: 19px;
  }

  .Input:-ms-input-placeholder {
    font-size: 19px;
  }

  .Input::placeholder {
    font-size: 19px;
  }
  .Input-message {
    font-size: 17px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .Input-label {
    font-size: 19px;
  }
  .Input {
    font-size: 19px;
  }
  .Input::-webkit-input-placeholder {
    font-size: 19px;
  }

  .Input:-ms-input-placeholder {
    font-size: 19px;
  }

  .Input::placeholder {
    font-size: 19px;
  }
  .Input-message {
    font-size: 17px;
  }
}

@media screen and (max-width: 767px) and (min-width: 736px) {
  .Input-label,
  .Input-message {
    font-size: 14px !important;
  }
  .Input {
    padding: 10px 11px;
    font-size: 16px !important;
  }
  .Input::-webkit-input-placeholder {
    font-size: 16px;
  }

  .Input:-ms-input-placeholder {
    font-size: 16px;
  }

  .Input::placeholder {
    font-size: 16px;
  }
}

@media screen and (max-width: 735px) and (min-width: 667px) {
  .Input-label,
  .Input-message {
    font-size: 14px !important;
  }
  .Input {
    padding: 10px 11px;
    font-size: 16px !important;
    height: 40px;
  }
  .Input::-webkit-input-placeholder {
    font-size: 16px;
  }

  .Input:-ms-input-placeholder {
    font-size: 16px;
  }

  .Input::placeholder {
    font-size: 16px;
  }
}

@media screen and (max-width: 666px) and (min-width: 601px) {
  .Input-label,
  .Input-message {
    font-size: 14px !important;
  }
  .Input {
    padding: 10px 11px;
    font-size: 16px !important;
    height: 40px;
  }
  .Input::-webkit-input-placeholder {
    font-size: 16px;
  }

  .Input:-ms-input-placeholder {
    font-size: 16px;
  }

  .Input::placeholder {
    font-size: 16px;
  }
}

@media screen and (max-width: 568px) and (min-width: 480px) {
  .Input-label,
  .Input-message {
    font-size: 14px !important;
  }
  .Input {
    padding: 10px 11px;
    font-size: 16px !important;
    height: 40px;
  }
  .Input::-webkit-input-placeholder {
    font-size: 16px;
  }

  .Input:-ms-input-placeholder {
    font-size: 16px;
  }

  .Input::placeholder {
    font-size: 16px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .Input-label,
  .Input-message {
    font-size: 14px !important;
  }
  .Input {
    padding: 10px 11px;
    font-size: 16px !important;
  }
  .Input::-webkit-input-placeholder {
    font-size: 16px;
  }

  .Input:-ms-input-placeholder {
    font-size: 16px;
  }

  .Input::placeholder {
    font-size: 16px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .Input-label,
  .Input-message {
    font-size: 14px !important;
  }
  .Input {
    padding: 10px 11px;
    font-size: 16px !important;
    height: 40px;
  }
  .Input::-webkit-input-placeholder {
    font-size: 16px;
  }

  .Input:-ms-input-placeholder {
    font-size: 16px;
  }

  .Input::placeholder {
    font-size: 16px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .Input-label,
  .Input-message {
    font-size: 14px !important;
  }
  .Input {
    padding: 10px 11px;
    font-size: 16px !important;
    height: 40px;
  }
  .Input::-webkit-input-placeholder {
    font-size: 16px;
  }

  .Input:-ms-input-placeholder {
    font-size: 16px;
  }

  .Input::placeholder {
    font-size: 16px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .Input-label,
  .Input-message {
    font-size: 14px !important;
  }
  .Input {
    padding: 10px 11px;
    font-size: 16px !important;
    height: 40px;
  }
  .Input::-webkit-input-placeholder {
    font-size: 16px;
  }

  .Input:-ms-input-placeholder {
    font-size: 16px;
  }

  .Input::placeholder {
    font-size: 16px;
  }
}
