.ShowDocs-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ShowDocs-title {
  opacity: 0.75;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  margin-bottom: 0;
}

.ShowDocs-title:first-child {
  margin-right: 18px;
}

.ShowDocs-select {
  width: 50px;
  height: 35px;
  margin-right: 18px;
  font-size: 15px;
}
