.StageModal {
  padding: 0 !important;
}

.StageModal-body {
  width: 620px;
  height: 100%;
  border-radius: 15px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin: 0;
  padding: 40px 0 !important;
}

.StageModal-paragraphs-wrapper {
  width: 80%;
  margin: 0 auto 48px auto;
}

.StageModal-message {
  font-size: 20px;
  letter-spacing: 0.67px;
  text-align: center;
  color: #58595c;
}

.StageModal-cancel-wrapper {
  text-align: center;
  margin-top: 32px;
}

.StageModal-finished > div > button {
  background-color: #f47600 !important;
  color: #fff;
}

.StageModal-cancel {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.67px;
  text-align: center;
  color: #f47600;
  border: none;
  background-color: #fff;
}

.StageModal-cancel:focus {
  outline: none;
}

/*Queries*/
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .StageModal > .modal-dialog {
    max-width: 620px;
  }
  .StageModal > .modal-dialog > .modal-content {
    width: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .StageModal-body {
    max-width: 560px;
  }
  .StageModal > .modal-dialog {
    max-width: 560px;
  }
  .StageModal > .modal-dialog > .modal-content {
    width: 100%;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .StageModal-body {
    max-width: 100%;
  }
  .StageModal > .modal-dialog {
    max-width: 374px;
  }
  .StageModal > .modal-dialog > .modal-content {
    width: 100%;
  }
  .StageModal-message,
  .StageModal-cancel {
    font-size: 16px;
  }
  .StageModal-paragraphs-wrapper {
    margin-bottom: 40px;
  }
  .StageModal-cancel-wrapper {
    margin-top: 24px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .StageModal-body {
    max-width: 100%;
  }
  .StageModal > .modal-dialog {
    max-width: 335px;
  }
  .StageModal > .modal-dialog > .modal-content {
    width: 100%;
  }
  .StageModal-message,
  .StageModal-cancel {
    font-size: 16px;
  }
  .StageModal-paragraphs-wrapper {
    margin-bottom: 40px;
  }
  .StageModal-cancel-wrapper {
    margin-top: 24px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .StageModal-body {
    max-width: 100%;
  }
  .StageModal > .modal-dialog {
    max-width: 320px;
  }
  .StageModal > .modal-dialog > .modal-content {
    width: 100%;
  }
  .StageModal-message,
  .StageModal-cancel {
    font-size: 16px;
  }
  .StageModal-paragraphs-wrapper {
    width: 85%;
    margin-bottom: 40px;
  }
  .StageModal-cancel-wrapper {
    margin-top: 24px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .StageModal-body {
    max-width: 100%;
  }
  .StageModal > .modal-dialog {
    max-width: 280px;
  }
  .StageModal > .modal-dialog > .modal-content {
    width: 100%;
  }
  .StageModal-message,
  .StageModal-cancel {
    font-size: 16px;
  }
  .StageModal-paragraphs-wrapper {
    width: 85%;
    margin-bottom: 40px;
  }
  .StageModal-cancel-wrapper {
    margin-top: 24px;
  }
  .StageModal-finished {
    max-width: 250px;
    margin: 0 auto;
  }
}
