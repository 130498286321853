.ConfirmButton {
  width: 117px;
  height: 32px;
  border-radius: 4px;
  border: none;
  background-color: #f47600;
  color: #fff;
  font-size: 12px;
  letter-spacing: 0.4px;
  margin-top: 20px;
}

.ConfirmButton:focus {
  outline: none;
}
