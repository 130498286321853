.ProgressBar-line {
  position: relative;
  width: 9%;
  height: 3px;
  background-color: transparent;
  opacity: 1;
}

.ProgressBar-line-one,
.ProgressBar-line-two {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f47600;
  top: 0;
  left: 0;
  z-index: 5;
  opacity: 0.2;
}

.ProgressBar-line-two {
  width: 0;
  background-color: #f47600;
  z-index: 10;
  opacity: 1;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .ProgressBar-line {
    opacity: 1;
    width: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .ProgressBar-line {
    opacity: 1;
    width: 100%;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .ProgressBar-line {
    opacity: 1;
    width: 100%;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .ProgressBar-line {
    opacity: 1;
    width: 100%;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .ProgressBar-line {
    opacity: 1;
    width: 100%;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .ProgressBar-line {
    opacity: 1;
    width: 100%;
  }
}
