.Footer {
  position: relative;
  padding: 80px 0;
  background-color: #01355f;
}

.Footer-cuerno,
.Footer-cuerno-movil {
  max-width: 78px;
  height: 82px;
}

.Footer-cuerno-movil {
  margin: 49px auto;
}

.Footer-item {
  margin-bottom: 10px;
}

.Footer-cuerno img,
.Footer-cuerno-movil img {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.Footer-animation {
  width: 100%;
  position: absolute;
  top: -69%;
  z-index: 0;
}

.Footer-animation img {
  width: 100%;
  height: 100%;
}

.Footer-list {
  list-style: none;
  margin: 0;
}

.Footer-link,
.Footer-item-title {
  color: #fff;
}

.Footer-item-title {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 16px;
}

.Footer-link {
  text-decoration: none !important;
  font-size: 18px;
  opacity: 0.6;
  font-weight: 500;
  letter-spacing: 0.6px;
  line-height: 1.56;
}

.Footer-link:hover {
  color: #fff;
}

.Footer-pasto-estatico {
  position: absolute;
  width: 100%;
  top: -60%;
}

.Footer-pasto-estatico-img {
  width: 100%;
}

/*MediaQueries*/

@media screen and (min-width: 1921px) {
  .Footer-animation {
    top: -85%;
  }

  .Footer-cuerno-movil {
    display: none;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1441px) {
  .Footer-animation {
    top: -92%;
  }

  .Footer-cuerno-movil {
    display: none;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .Footer-animation {
    top: -70%;
  }
  .Footer-cuerno-movil {
    display: none;
  }
  .Footer-pasto-estatico {
    top: -46%;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .Footer-animation {
    top: -70%;
  }
  .Footer-cuerno-movil {
    display: none;
  }
  .Footer-link {
    font-size: 17px;
  }
  .Footer-pasto-estatico {
    top: -48%;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1250px) {
  .Footer-cuerno-movil {
    display: none;
  }
  .Footer-link {
    font-size: 17px;
  }
  .Footer-pasto-estatico {
    top: -45%;
  }
}

@media screen and (max-width: 1249px) and (min-width: 1024px) {
  .Footer-animation {
    top: -47%;
  }
  .Footer-cuerno-movil {
    display: none;
  }
  .Footer-pasto-estatico {
    top: -29%;
  }
  .Footer-cuerno {
    max-width: 53px;
    height: 57px;
  }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .Footer {
    padding: 3.2rem 0;
  }

  .Footer-animation {
    top: -15%;
  }

  .Footer-animation img {
    height: 260px;
  }

  .Footer-cuerno {
    display: none;
  }
  .Footer-list {
    padding: 0;
    margin-bottom: 16px;
  }
  .Footer-item-title {
    margin-bottom: 8px;
  }
  .pd0 {
    padding: 0;
  }
  .Footer-container {
    max-width: 722px;
    margin: 0 auto;
  }
  .Footer-pasto-estatico {
    top: -11%;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .Footer {
    padding: 3.2rem 0;
  }

  .Footer-animation {
    top: -15%;
  }

  .Footer-animation img {
    height: 260px;
  }

  .Footer-cuerno {
    display: none;
  }
  .Footer-list {
    padding: 0;
    margin-bottom: 16px;
  }
  .Footer-item-title {
    margin-bottom: 8px;
  }
  .pd0 {
    padding: 0;
  }
  .Footer-container {
    max-width: 554px;
    margin: 0 auto;
  }
  .Footer-pasto-estatico {
    top: -9%;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .Footer {
    padding: 1.2rem 0;
  }

  .Footer-animation {
    top: -18%;
  }

  .Footer-animation img {
    height: 260px;
  }

  .Footer-cuerno {
    display: none;
  }
  .Footer-list {
    padding: 0;
    margin-bottom: 16px;
  }
  .Footer-item-title {
    margin-bottom: 8px;
  }
  .pd0 {
    padding: 0;
  }
  .Footer-container {
    max-width: 368px;
    margin: 0 auto;
  }
  .mg-row {
    margin-left: 0;
    margin-right: 0;
  }
  .Footer-pasto-estatico {
    top: -13%;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .Footer {
    padding: 1.2rem 0;
  }

  .Footer-animation {
    top: -18%;
  }

  .Footer-animation img {
    height: 260px;
  }

  .Footer-cuerno {
    display: none;
  }
  .Footer-list {
    padding: 0;
    margin-bottom: 16px;
  }
  .Footer-item-title {
    margin-bottom: 8px;
  }
  .pd0 {
    padding: 0;
  }
  .Footer-container {
    max-width: 329px;
    margin: 0 auto;
  }
  .mg-row {
    margin-left: 0;
    margin-right: 0;
  }
  .Footer-pasto-estatico {
    top: -13%;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .Footer {
    padding: 25px 0;
  }
  .Footer-animation {
    top: -18%;
  }
  .Footer-cuerno-movil {
    margin: 30px auto;
  }
  .Footer-animation img {
    height: 260px;
  }

  .Footer-cuerno {
    display: none;
  }
  .Footer-list {
    padding: 0;
    margin-bottom: 16px;
  }
  .Footer-item-title {
    margin-bottom: 8px;
  }
  .pd0 {
    padding: 0;
  }
  .Footer-container {
    max-width: 314px;
    margin: 0 auto;
  }
  .mg-row {
    margin-left: 0;
    margin-right: 0;
  }
  .Footer-pasto-estatico {
    top: -13%;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .Footer {
    padding: 25px 0;
  }
  .Footer-animation {
    top: -18%;
  }
  .Footer-cuerno-movil {
    margin: 30px auto;
  }
  .Footer-animation img {
    height: 260px;
  }

  .Footer-cuerno {
    display: none;
  }
  .Footer-list {
    padding: 0;
    margin-bottom: 16px;
  }
  .Footer-item-title {
    margin-bottom: 8px;
  }
  .pd0 {
    padding: 0;
  }
  .Footer-container {
    max-width: 274px;
    margin: 0 auto;
  }
  .mg-row {
    margin-left: 0;
    margin-right: 0;
  }
  .Footer-pasto-estatico {
    top: -12%;
  }
}
