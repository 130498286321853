.CustomCard {
  border: none;
}

.CustomCard-main-content {
  border-bottom: 1px solid #b3b3b3;
  padding: 15px;
}

.CustomCard-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.CustomCard-details {
  opacity: 0.5;
  font-size: 15px;
  line-height: 1.6;
  color: #000;
  margin: 0;
}

.CustomCard-amount {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.53px;
  text-align: right;
  color: #0f9358;
}

.CustomCard-arrow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.CustomCard-body {
  padding: 0;
  margin-top: 16px;
}

.CustomCard-body-title,
.CustomCard-body-subtitle,
.CustomCard-details-email {
  margin: 0;
  opacity: 0.5;
  font-size: 15px;
  line-height: 1.6;
  color: var(--black);
}

.CustomCard-body-title {
  opacity: 1;
}

.CustomCard-details-email {
  color: #31abff;
}

.CustomCar-link,
.CustomCard-link:hover {
  font-size: 15px;
  line-height: 1.6;
  color: #f47600 !important;
  text-decoration: underline;
}
