.MessageChat {
  width: 65%;
  margin: 15px 10px 15px 10px;
}

.MessageChat-container {
  width: 100%;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(0, 0, 0, 0.5);
  padding: 12px;
}

.MessageChat-paragraph {
  margin: 0;
  font-size: 13px;
}

.MessageChat-time {
  margin: 0;
  font-size: 12px;
  text-align: right;
  margin-right: 12px;
}

.MessageChat-details-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.download-btn {
  font-size: 12px;
  color: #58595c;
  text-decoration: underline;
}

.download-btn:hover,
.download-btn:link,
.download-btn:active,
.download-btn:visited {
  font-size: 12px;
  color: #58595c;
  font-weight: 500;
  letter-spacing: 0.4px;
}

/*Media Queries*/
@media screen and (max-width: 1149px) and (min-width: 1020px) {
  .MessageChat-paragraph {
    font-size: 12px;
  }
}

@media screen and (max-width: 1019px) and (min-width: 768px) {
  .MessageChat-paragraph {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .MessageChat-paragraph {
    font-size: 12px;
  }
  .MessageChat-time {
    font-size: 11px;
    margin-right: 0;
  }
  .MessageChat-details-container {
    flex-direction: column;
    align-items: flex-end;
  }
  .download-btn .download-btn:hover,
  .download-btn:link,
  .download-btn:active,
  .download-btn:visited {
    font-size: 11px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .MessageChat-paragraph {
    font-size: 12px;
  }
  .MessageChat-time {
    font-size: 11px;
    margin-right: 0;
  }
  .MessageChat-container {
    padding: 8px;
  }
  .Message-clip-icon {
    margin-left: 6px;
  }
  .MessageChat-details-container {
    flex-direction: column;
    align-items: flex-end;
  }
  .download-btn .download-btn:hover,
  .download-btn:link,
  .download-btn:active,
  .download-btn:visited {
    font-size: 11px;
  }
  .MessageChat-container {
    border-radius: 5.5px;
    border: none;
    box-shadow: none;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .MessageChat-paragraph {
    font-size: 12px;
  }
  .MessageChat-time {
    font-size: 11px;
    margin-right: 0;
  }
  .MessageChat-container {
    padding: 8px;
  }
  .Message-clip-icon {
    margin-left: 6px;
  }
  .MessageChat-details-container {
    flex-direction: column;
    align-items: flex-end;
  }
  .download-btn .download-btn:hover,
  .download-btn:link,
  .download-btn:active,
  .download-btn:visited {
    font-size: 11px;
  }
  .MessageChat-container {
    border-radius: 5.5px;
    border: none;
    box-shadow: none;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .MessageChat-paragraph {
    font-size: 12px;
  }
  .MessageChat-time {
    font-size: 11px;
    margin-right: 0;
  }
  .MessageChat-container {
    padding: 8px;
  }
  .Message-clip-icon {
    margin-left: 6px;
  }
  .MessageChat-details-container {
    flex-direction: column;
    align-items: flex-end;
  }
  .download-btn .download-btn:hover,
  .download-btn:link,
  .download-btn:active,
  .download-btn:visited {
    font-size: 11px;
  }
  .MessageChat-container {
    border-radius: 5.5px;
    border: none;
    box-shadow: none;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .MessageChat-paragraph {
    font-size: 12px;
  }
  .MessageChat-time {
    font-size: 11px;
    margin-right: 0;
  }
  .MessageChat-container {
    padding: 8px;
  }
  .Message-clip-icon {
    margin-left: 6px;
  }
  .MessageChat-details-container {
    flex-direction: column;
    align-items: flex-end;
  }
  .download-btn .download-btn:hover,
  .download-btn:link,
  .download-btn:active,
  .download-btn:visited {
    font-size: 11px;
  }
  .MessageChat-container {
    border-radius: 5.5px;
    border: none;
    box-shadow: none;
  }
}
