.Testimonals {
  position: relative;
  padding: 106px 0 30rem 0;
}

.Testimonials-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
  background-color: #f0f0f0;
  top: 0;
  opacity: 0;
}

.Testimonials-title {
  width: fit-content;
  margin: 0 auto;
}

.Testimonials-title h2 {
  color: #58595c;
  text-align: center;
  font-size: 64px;
  margin-bottom: 24px;
  white-space: nowrap;
  border-right: 2px solid;
  width: 0%;
  overflow: hidden;
  visibility: hidden;
}

.Testimonials-container {
  position: relative;
  width: 70%;
  height: 250px;
  margin: 25px auto 0 auto;
  z-index: 10;
}

.Testimonials-slides {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.Testimonials-comment {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 30px;
  outline: none !important;
}

.Testimonials-comment h3 {
  text-align: center;
  line-height: 1.65;
  font-weight: 300;
  color: #58595c;
  font-size: 32px;
}

.quoteIcon {
  color: #f47600;
  font-weight: 600;
  font-size: 54px;
}

.Testimonials-comment p {
  text-align: right;
  color: #01355f;
  font-size: 19px;
}

.Testionials-animation {
  position: absolute;
  width: 70%;
  bottom: -9%;
  left: 0;
  z-index: 0;
}

.Testionials-animation img {
  width: 100%;
}

/*SliderButtons*/
.slick-dots li button:before {
  font-size: 16px;
  opacity: 1;
  color: #c3c8d0;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #364962;
}

/*Media Queries*/
@media screen and (min-width: 1921px) {
  .Testionials-animation {
    width: 47%;
    bottom: -18%;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1441px) {
  .Testimonals {
    padding: 106px 0 37rem 0;
  }
  .Testionials-animation {
    width: 63%;
    bottom: -12%;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .Testimonals {
    padding: 106px 0 33rem 0;
  }
  .Testionials-animation {
    width: 70%;
    bottom: -10%;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .Testimonals {
    padding: 106px 0 33rem 0;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .Testimonials-title h2 {
    font-size: 54px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .Testimonals {
    padding: 48px 0 18rem 0;
  }
  .Testimonials-container {
    width: 100%;
  }

  .Testimonials-title h2 {
    font-size: 35px;
  }

  .Testimonials-comment h3 {
    font-size: 24px;
  }

  .Testionials-animation {
    width: 69%;
    bottom: -11%;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .Testimonals {
    padding: 48px 0 8rem 0;
  }

  .Testimonials-container {
    width: 100%;
  }

  .Testimonials-title h2 {
    font-size: 35px;
  }

  .Testimonials-comment h3 {
    font-size: 17px;
    line-height: normal;
  }

  .Testimonials-comment p {
    font-size: 15px;
  }

  .Testionials-animation {
    width: 69%;
    bottom: -11%;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .Testimonals {
    padding: 48px 0 8rem 0;
  }

  .Testimonials-container {
    width: 100%;
  }

  .Testimonials-title h2 {
    font-size: 35px;
  }

  .Testimonials-comment h3 {
    font-size: 17px;
    line-height: normal;
  }

  .Testimonials-comment p {
    font-size: 15px;
  }

  .Testionials-animation {
    width: 69%;
    bottom: -11%;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .Testimonals {
    padding: 48px 0 8rem 0;
  }

  .Testimonials-container {
    width: 100%;
  }

  .Testimonials-title h2 {
    font-size: 35px;
  }

  .Testimonials-comment h3 {
    font-size: 17px;
    line-height: normal;
  }

  .Testimonials-comment p {
    font-size: 15px;
  }

  .Testionials-animation {
    width: 69%;
    bottom: -11%;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .Testimonals {
    padding: 48px 0 8rem 0;
  }

  .Testimonials-container {
    width: 100%;
  }

  .Testimonials-title h2 {
    font-size: 35px;
  }

  .Testimonials-comment h3 {
    font-size: 17px;
    line-height: normal;
  }

  .Testimonials-comment p {
    font-size: 15px;
  }

  .Testionials-animation {
    width: 69%;
    bottom: -11%;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .Testimonals {
    padding: 48px 0 10rem 0;
  }

  .Testimonials-container {
    width: 100%;
  }

  .Testimonials-title h2 {
    font-size: 25px;
  }

  .Testimonials-comment h3 {
    font-size: 16px;
  }

  .Testimonials-comment p {
    font-size: 15px;
  }

  .Testionials-animation {
    width: 78%;
    bottom: -7%;
  }
}
