.Stage-card {
  border: 1px solid#b3b3b3;
  margin-bottom: 30px;
}

.Stage-card:first-child {
  margin-top: 30px;
}

.Stage-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: none;
}

.Stage-card-paragraph {
  font-size: 14px;
  color: #b3b3b3;
}

.Stage-card-title {
  font-size: 20px;
  font-weight: 500;
}

/*Media Queries*/
@media screen and (max-width: 479px) and (min-width: 414px) {
  .Stage-card-title {
    font-size: 20px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .Stage-card-title {
    font-size: 20px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .Stage-card-title {
    font-size: 20px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .Stage-card-title {
    font-size: 20px;
  }
}
