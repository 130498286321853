.PackageDetails {
  margin: 30px auto;
  max-width: 231px;
  height: 420px;
}

.PackageDetails-container {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0 auto;
  border-radius: 140px;
  overflow: hidden;
  border: 1px #d9d9d9 solid;
}

.PackageDetails-container:hover {
  box-shadow: rgba(92, 84, 122, 0.14) 0px 4px 18px;
  transition: all 0.5s ease-in 0s;
}

.PackageDetails-container:hover
  .PackageDetails-info
  .PackageDetails-link-container {
  background-color: #f47600;
}

.PackageDetails-container:hover
  .PackageDetails-info
  .PackageDetails-link-container
  .PackageDetails-link {
  color: #fff;
  transition: all 0.5s ease-in 0s;
}

.PackageDetails-img {
  max-width: 231px;
  height: 175px;
  background-color: #eee;
  border-radius: 50%;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.PackageDetails-info-container {
  width: 100%;
  height: 50%;
}

.PackageDetails-info {
  width: 90%;
  margin: 25px auto 0 auto;
}

.PackageDetails-info-main-paragraph {
  text-align: center;
  font-size: 16px;
  color: #58595c;
  font-weight: 400;
  margin: 15px 0 0 0;
}

.PackageDetails-info h5 {
  margin: 0;
  text-align: center;
  color: #f47600;
  font-weight: 500;
}

.PackageDetails-info-paragraph {
  color: #58595c;
  text-align: center;
  font-size: 12px;
  margin: 15px 0 0 0;
  font-weight: 500;
}

.PackageDetails-link-container {
  position: absolute;
  text-align: center;
  padding: 9px 5px;
  border-radius: 50%;
  bottom: 0;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.PackageDetails-link {
  color: #f47600;
  padding: 16px 0px;
  text-decoration: none !important;
  font-size: 18px;
}

/*Media Queries*/
@media screen and (min-width: 1441px) {
  .PackageDetails {
    height: 455px;
  }
  .PackageDetails-info {
    margin: 20px auto 0 auto;
  }
  .PackageDetails-img {
    height: 227px;
  }
}
@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .PackageDetails-info {
    margin: 20px auto 0 auto;
  }
  .PackageDetails-img {
    height: 213px;
  }
}
@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .PackageDetails-img {
    height: 199px;
  }
}
@media screen and (max-width: 1249px) and (min-width: 1030px) {
  .PackageDetails-container {
    width: 100%;
    height: 300px;
  }
  .PackageDetails-link-container {
    padding: 14px 5px;
  }
  .PackageDetails-link {
    padding: 0px 28px;
  }
  .PackageDetails-info-main-paragraph {
    font-size: 14px;
  }
  .PackageDetails-info h5 {
    font-size: 15px;
  }
  .PackageDetails-info-paragraph {
    font-size: 12px;
    margin: 3px 0 0 0;
  }
}

@media screen and (max-width: 1029px) and (min-width: 1020px) {
  .PackageDetails {
    height: 305px;
  }
  .PackageDetails-container {
    width: 100%;
    height: 100%;
  }
  .PackageDetails-link-container {
    padding: 14px 5px;
  }
  .PackageDetails-link {
    padding: 0px 14px;
    font-size: 13px;
  }
  .PackageDetails-info-main-paragraph {
    font-size: 14px;
  }
  .PackageDetails-info h5 {
    font-size: 15px;
  }
  .PackageDetails-info-paragraph {
    font-size: 12px;
    margin: 3px 0 0 0;
  }
  .PackageDetails-img {
    height: 134px;
  }
}

@media screen and (max-width: 1019px) and (min-width: 768px) {
  .PackageDetails {
    max-width: 335px;
    height: 425px;
    border-radius: 15px;
    border: solid 1px #979797;
    background-color: #ffffff;
    margin: 0 0 24px 0;
  }
  .PackageDetails-container {
    width: 100%;
    border: none;
    border-radius: 0;
  }
  .PackageDetails-img {
    border-radius: 15px 15px 0 0;
    max-width: initial;
  }
  .PackageDetails-info-main-paragraph {
    margin: 16px 0 0 0;
    font-size: 20px;
  }
  .PackageDetails-info h5 {
    font-size: 20px;
  }
  .PackageDetails-info-paragraph {
    font-size: 15px;
    margin: 24px 0 24px 0;
  }
  .PackageDetails-link-container {
    position: initial;
    border: 1px solid #f47600;
    max-width: 289px;
    height: 48px;
    border-radius: 12px;
    padding: 0;
    margin: 0 auto;
  }
  .PackageDetails-link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .PackageDetails {
    max-width: 335px;
    margin: 0 auto 24px auto;
    height: 100%;
  }
  .PackageDetails-container {
    height: 485px;
    border-radius: 15px;
    border: solid 1px #979797;
    background-color: #ffffff;
  }
  .PackageDetails-img {
    border-radius: 15px 15px 0 0;
    height: 55%;
    max-width: initial;
  }
  .PackageDetails-link-container {
    max-width: 289px;
    height: 48px;
    position: initial;
    border-radius: 12px;
    border: solid 2px #f47600;
    margin: 0 auto;
    padding: 0;
  }
  .PackageDetails-link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: 0;
  }
  .PackageDetails-container:hover
    .PackageDetails-info
    .PackageDetails-link-container {
    background-color: transparent;
  }
  .PackageDetails-container:hover
    .PackageDetails-info
    .PackageDetails-link-container
    .PackageDetails-link {
    color: #f47600;
  }
  .PackageDetails-info-main-paragraph,
  .PackageDetails-info h5 {
    font-size: 20px;
  }

  .PackageDetails-info-paragraph {
    font-size: 15px;
    margin: 24px 0 24px 0;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .PackageDetails {
    max-width: 368px;
    margin: 0 0 24px 0;
    height: 100%;
  }
  .PackageDetails-container {
    width: 100%;
    border-radius: 15px;
    border: solid 1px #979797;
    background-color: #ffffff;
    height: 445px;
  }
  .PackageDetails-img {
    border-radius: 15px 15px 0 0;
    height: 50%;
    max-width: initial;
  }
  .PackageDetails-link-container {
    max-width: 289px;
    height: 48px;
    position: initial;
    border-radius: 12px;
    border: solid 2px #f47600;
    margin: 0 auto;
    padding: 0;
  }
  .PackageDetails-link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: 0;
    border: none;
  }
  .PackageDetails-container:hover
    .PackageDetails-info
    .PackageDetails-link-container {
    background-color: transparent;
  }
  .PackageDetails-container:hover
    .PackageDetails-info
    .PackageDetails-link-container
    .PackageDetails-link {
    color: #f47600;
  }
  .PackageDetails-info-main-paragraph,
  .PackageDetails-info h5 {
    font-size: 20px;
  }

  .PackageDetails-info-paragraph {
    font-size: 15px;
    margin: 24px 0 24px 0;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .PackageDetails {
    max-width: 329px;
    margin: 0 0 24px 0;
    height: 100%;
  }
  .PackageDetails-container {
    width: 100%;
    border-radius: 15px;
    border: solid 1px #979797;
    background-color: #ffffff;
    height: 445px;
  }
  .PackageDetails-img {
    border-radius: 15px 15px 0 0;
    height: 50%;
    max-width: initial;
  }
  .PackageDetails-link-container {
    max-width: 289px;
    height: 48px;
    position: initial;
    border-radius: 12px;
    border: solid 2px #f47600;
    margin: 0 auto;
    padding: 0;
  }
  .PackageDetails-link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: 0;
    border: none;
  }
  .PackageDetails-container:hover
    .PackageDetails-info
    .PackageDetails-link-container {
    background-color: transparent;
  }
  .PackageDetails-container:hover
    .PackageDetails-info
    .PackageDetails-link-container
    .PackageDetails-link {
    color: #f47600;
  }
  .PackageDetails-info-main-paragraph,
  .PackageDetails-info h5 {
    font-size: 20px;
  }
  .PackageDetails-info-paragraph {
    font-size: 15px;
    margin: 24px 0 24px 0;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .PackageDetails {
    max-width: 314px;
    margin: 0 0 24px 0;
    height: 100%;
  }
  .PackageDetails-container {
    width: 100%;
    border-radius: 15px;
    border: solid 1px #979797;
    background-color: #ffffff;
    height: 445px;
  }
  .PackageDetails-img {
    border-radius: 15px 15px 0 0;
    height: 50%;
    max-width: initial;
  }
  .PackageDetails-link-container {
    max-width: 289px;
    height: 48px;
    position: initial;
    border-radius: 12px;
    border: solid 2px #f47600;
    margin: 0 auto;
    padding: 0;
  }
  .PackageDetails-link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: 0;
    border: none;
  }
  .PackageDetails-container:hover
    .PackageDetails-info
    .PackageDetails-link-container {
    background-color: transparent;
  }
  .PackageDetails-container:hover
    .PackageDetails-info
    .PackageDetails-link-container
    .PackageDetails-link {
    color: #f47600;
  }
  .PackageDetails-info-main-paragraph,
  .PackageDetails-info h5 {
    font-size: 20px;
  }
  .PackageDetails-info-paragraph {
    font-size: 15px;
    margin: 24px 0 24px 0;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .PackageDetails {
    max-width: 274px;
    margin: 0 0 24px 0;
    height: 100%;
  }
  .PackageDetails-container {
    width: 100%;
    border-radius: 15px;
    border: solid 1px #979797;
    background-color: #ffffff;
    height: 445px;
  }
  .PackageDetails-img {
    border-radius: 15px 15px 0 0;
    height: 50%;
    max-width: initial;
  }
  .PackageDetails-link-container {
    max-width: 289px;
    height: 48px;
    position: initial;
    border-radius: 12px;
    border: solid 2px #f47600;
    margin: 0 auto;
    padding: 0;
  }
  .PackageDetails-link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: 0;
    border: none;
  }
  .PackageDetails-container:hover
    .PackageDetails-info
    .PackageDetails-link-container {
    background-color: transparent;
  }
  .PackageDetails-container:hover
    .PackageDetails-info
    .PackageDetails-link-container
    .PackageDetails-link {
    color: #f47600;
  }
  .PackageDetails-info-main-paragraph,
  .PackageDetails-info h5 {
    font-size: 20px;
  }
  .PackageDetails-info-paragraph {
    font-size: 15px;
    margin: 24px 0 24px 0;
  }
}
