.CreatePackage-title {
  font-size: 15px;
  letter-spacing: 0.5px;
  color: #000;
}

.CreatePackage-form-wrapper {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.CreatePackage-textarea-wrapper {
  margin-top: 11px;
}

.CreatePackage-input-wrapper > div > label,
.CreatePackage-select-wrapper > div > label,
.CreatePackage-textarea-wrapper > div > label {
  font-size: 12px;
  letter-spacing: 0.4px;
  color: #000;
}

.CreatePackage-input-wrapper > div > input,
.CreatePackage-select-wrapper > div > select,
.CreatePackage-input-wrapper > div > input::placeholder {
  height: 49px;
  font-size: 12px;
  letter-spacing: 0.4px;
  color: #000;
  border: 1px solid #bfbfbf;
}

.CreatePackage-textarea-wrapper > div > textarea,
.CreatePackage-textarea-wrapper > div > textarea::placeholder {
  font-size: 12px;
  letter-spacing: 0.4px;
  color: #000;
  border: 1px solid #bfbfbf;
}

.CreatePackage-input-wrapper > div > input::placeholder,
.CreatePackage-textarea-wrapper > div > textarea::placeholder {
  border: none;
}

.CreatePackage-file-wrapper {
  margin-top: 8px;
}
