.LoginAdmin {
  width: 100%;
  height: 100vh;
  background-image: url('../../../img/dashboard/login_fondo_admin.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.LoginAdmin-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.LoginAdmin-wrapper-form {
  width: 600px;
  object-fit: contain;
  border-radius: 15px;
  box-shadow: 0 8px 16px 0 var(--black-30);
  background-color: #ffffff;
  padding: 50px;
  margin: 0 auto;
}

.Login-rhino-logo-dash {
  margin-bottom: 49px;
}

.Login-rhino-logo-dash > img {
  display: block;
  margin: 0 auto;
}
