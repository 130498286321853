.Switch-container {
  width: 48px;
  height: 24px;
  border: 1px solid #00c914;
  border-radius: 12px;
  display: flex;
  align-items: center;
  margin-right: 13px;
}

.Switch-icon {
  width: 24px;
  height: 24px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #979797;
  background-color: #ffffff;
  border-radius: 50%;
}
