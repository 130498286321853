.DropdownAgency {
  background-color: transparent !important;
  border: none !important;
}

.DropdownAgency:focus {
  outline: none !important;
  box-shadow: none !important;
}

.Dropdown-menu-agency {
  width: 230px;
  transform: translate3d(-95px, 36px, 0px);
  border-radius: 15px;
}

.Dropdown-list-agency {
  padding: 8px 0;
}

.Dropdown-item-container-agency {
  width: 100%;
  text-align: center;
  padding: 7px 0;
}

.Dropdown-item-container-agency:hover {
  background-color: #ccd7df;
}

.Dropdown-item-container-agency:hover .Dropdown-link-agency,
.Dropdown-item-container-agency:hover .Dropdown-btn-agency {
  color: #01355f !important;
}

.Dropdown-link-agency {
  padding: 10px 24px;
  color: #58595c;
  text-decoration: none !important;
}

.Dropdown-btn-agency {
  border: none;
  background-color: transparent;
  color: #58595c;
}

.Dropdown-btn-agency:focus {
  outline: none !important;
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .Dropdown-menu-agency {
    transform: translate3d(-32px, 36px, 0px) !important;
  }
}
