.SignUp-container h3 {
  margin-bottom: 40px;
  margin-top: 2px;
  font-size: 32px;
}

.google-signup-container {
  max-width: 400px;
  height: 64px;
  margin-bottom: 24px;
}

.google-signup-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 15px;
  border: solid 2px #004b88;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.67px;
  text-align: center;
  color: #58595c;
  text-transform: uppercase;
}

.google-signup-btn:focus {
  outline: none !important;
}

.google-icon-container {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.google-icon-container img {
  width: 100%;
  height: 100%;
}

.line-container {
  max-width: 320px;
  height: 2px;
  margin: 0 auto 23px auto;
  background-color: #f47600;
}

.btn-con-cuenta {
  color: #f47600;
  text-decoration: underline;
  border: none;
  background-color: #fff;
}

.btn-con-cuenta:focus {
  outline: none !important;
}

.link-con-cuenta,
.link-con-cuenta:hover {
  color: #f47600;
  text-decoration: underline;
  border: none;
  display: block;
}

/*Media Queries*/
@media screen and (min-width: 1441px) {
  .google-movil,
  .link-con-cuenta {
    display: none;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .google-movil,
  .link-con-cuenta {
    display: none;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .google-movil,
  .link-con-cuenta {
    display: none;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .google-movil,
  .link-con-cuenta {
    display: none;
  }
}

@media screen and (max-width: 1024px) and (min-width: 961px) {
  .google-desk,
  .btn-con-cuenta {
    display: none;
  }
  .SignUp {
    background-color: #ececec;
    height: 100vh;
  }
  .SignUp-container {
    max-width: 728px;
    margin: 0 auto;
  }
  .google-signup-container,
  .FormRegistrarse-btn-container,
  .FormInicioSesion-btn-container {
    max-width: 728px;
  }
  .google-signup-btn {
    border-radius: 12px;
    border: solid 2px #004b88;
    background-color: #004b88;
    color: #fff;
  }
  .aligning-items {
    justify-content: flex-start !important;
    margin: 16px 0 40px 0;
  }
  .SignUp-container h3 {
    padding-top: 40px;
    margin-top: 0;
  }
  .google-desk {
    display: none;
  }
  .SignUp-paragraph {
    margin-top: 32px !important;
  }
}

@media screen and (max-width: 960px) and (min-width: 800px) {
  .google-desk,
  .btn-con-cuenta {
    display: none;
  }
  .SignUp {
    background-color: #ececec;
    height: 100%;
  }
  .SignUp-container {
    max-width: 560px;
    margin: 0 auto;
  }
  .google-signup-container,
  .FormRegistrarse-btn-container,
  .FormInicioSesion-btn-container {
    max-width: 560px;
    height: 48px;
  }
  .google-signup-btn {
    border-radius: 12px;
    border: solid 2px #004b88;
    background-color: #004b88;
    color: #fff;
  }
  .aligning-items {
    justify-content: flex-start !important;
    margin: 16px 0 40px 0;
  }
  .SignUp-container h3 {
    padding-top: 40px;
    margin-top: 0;
  }
  .google-desk {
    display: none;
  }
  .SignUp-paragraph {
    margin-top: 32px !important;
    background-color: #ececec;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 799px) and (min-width: 768px) {
  .google-desk,
  .btn-con-cuenta {
    display: none;
  }
  .SignUp {
    background-color: #ececec;
    height: 100vh;
  }
  .SignUp-container {
    max-width: 728px;
    margin: 0 auto;
  }
  .google-signup-container,
  .FormRegistrarse-btn-container,
  .FormInicioSesion-btn-container {
    max-width: 728px;
  }
  .google-signup-btn {
    border-radius: 12px;
    border: solid 2px #004b88;
    background-color: #004b88;
    color: #fff;
  }
  .aligning-items {
    justify-content: flex-start !important;
    margin: 16px 0 40px 0;
  }
  .SignUp-container h3 {
    padding-top: 40px;
    margin-top: 0;
  }
  .google-desk {
    display: none;
  }
  .SignUp-paragraph {
    margin-top: 32px !important;
  }
}

@media screen and (max-width: 767px) and (min-width: 700px) {
  .google-desk,
  .btn-con-cuenta {
    display: none;
  }
  .SignUp {
    background-color: #ececec;
    height: 100%;
  }
  .SignUp-container {
    max-width: 374px;
    margin: 0 auto;
  }
  .google-signup-container,
  .FormRegistrarse-btn-container,
  .FormInicioSesion-btn-container {
    max-width: 374px;
    height: 48px;
  }
  .google-signup-container {
    margin-bottom: 15.5px;
  }
  .google-signup-btn {
    border-radius: 12px;
    border: solid 2px #004b88;
    background-color: #004b88;
    color: #fff;
    font-size: 16px;
  }
  .aligning-items {
    justify-content: flex-start !important;
    margin: 16px 0 40px 0;
  }
  .SignUp-container h3 {
    padding-top: 40px;
    margin-top: 0;
    font-size: 24px;
  }
  .google-desk {
    display: none;
  }
  .SignUp-paragraph {
    margin-top: 32px !important;
    background-color: #ececec;
    padding-bottom: 40px;
  }
  .line-container {
    margin: 0 auto 15.5px auto;
    max-width: 294px;
  }
  .SignUp-paragraph p {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 699px) and (min-width: 640px) {
  .google-desk,
  .btn-con-cuenta {
    display: none;
  }
  .SignUp {
    background-color: #ececec;
    height: 100%;
  }
  .SignUp-container {
    max-width: 335px;
    margin: 0 auto;
  }
  .google-signup-container,
  .FormRegistrarse-btn-container,
  .FormInicioSesion-btn-container {
    max-width: 335px;
    height: 48px;
  }
  .google-signup-container {
    margin-bottom: 15.5px;
  }
  .google-signup-btn {
    border-radius: 12px;
    border: solid 2px #004b88;
    background-color: #004b88;
    color: #fff;
    font-size: 16px;
  }
  .aligning-items {
    justify-content: flex-start !important;
    margin: 16px 0 40px 0;
  }
  .SignUp-container h3 {
    padding-top: 40px;
    margin-top: 0;
    font-size: 24px;
  }
  .google-desk {
    display: none;
  }
  .SignUp-paragraph {
    margin-top: 32px !important;
    background-color: #ececec;
    padding-bottom: 40px;
  }
  .line-container {
    margin: 0 auto 15.5px auto;
    max-width: 255px;
  }
  .SignUp-paragraph p {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 639px) and (min-width: 580px) {
  .google-desk,
  .btn-con-cuenta {
    display: none;
  }
  .SignUp {
    background-color: #ececec;
    height: 100vh;
  }
  .SignUp-container {
    max-width: 560px;
    margin: 0 auto;
  }
  .google-signup-container,
  .FormRegistrarse-btn-container,
  .FormInicioSesion-btn-container {
    max-width: 560px;
    height: 48px;
  }
  .google-signup-btn {
    border-radius: 12px;
    border: solid 2px #004b88;
    background-color: #004b88;
    color: #fff;
  }
  .aligning-items {
    justify-content: flex-start !important;
    margin: 16px 0 40px 0;
  }
  .SignUp-container h3 {
    padding-top: 40px;
    margin-top: 0;
  }
  .google-desk {
    display: none;
  }
  .SignUp-paragraph {
    margin-top: 32px !important;
    background-color: #ececec;
  }
}

@media screen and (max-width: 579px) and (min-width: 500px) {
  .google-desk,
  .btn-con-cuenta {
    display: none;
  }
  .SignUp {
    background-color: #ececec;
    height: 100%;
  }
  .SignUp-container {
    max-width: 280px;
    margin: 0 auto;
  }
  .google-signup-container,
  .FormRegistrarse-btn-container,
  .FormInicioSesion-btn-container {
    max-width: 280px;
    height: 48px;
  }
  .google-signup-container {
    margin-bottom: 15.5px;
  }
  .google-signup-btn {
    border-radius: 12px;
    border: solid 2px #004b88;
    background-color: #004b88;
    color: #fff;
    font-size: 16px;
  }
  .aligning-items {
    justify-content: flex-start !important;
    margin: 16px 0 40px 0;
  }
  .SignUp-container h3 {
    padding-top: 40px;
    margin-top: 0;
    font-size: 24px;
  }
  .google-desk {
    display: none;
  }
  .SignUp-paragraph {
    margin-top: 32px !important;
    background-color: #ececec;
    padding-bottom: 40px;
  }
  .line-container {
    margin: 0 auto 15.5px auto;
    max-width: 200px;
  }
  .SignUp-paragraph p {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 499px) and (min-width: 480px) {
  .google-desk,
  .btn-con-cuenta {
    display: none;
  }
  .SignUp {
    background-color: #ececec;
    height: 100%;
  }
  .SignUp-container {
    max-width: 440px;
    margin: 0 auto;
  }
  .google-signup-container,
  .FormRegistrarse-btn-container,
  .FormInicioSesion-btn-container {
    max-width: 440px;
    height: 48px;
  }
  .google-signup-container {
    margin-bottom: 15.5px;
  }
  .google-signup-btn {
    border-radius: 12px;
    border: solid 2px #004b88;
    background-color: #004b88;
    color: #fff;
    font-size: 16px;
  }
  .aligning-items {
    justify-content: flex-start !important;
    margin: 16px 0 40px 0;
  }
  .SignUp-container h3 {
    padding-top: 40px;
    margin-top: 0;
    font-size: 24px;
  }
  .google-desk {
    display: none;
  }
  .SignUp-paragraph {
    margin-top: 32px !important;
    background-color: #ececec;
    padding-bottom: 40px;
  }
  .line-container {
    margin: 0 auto 15.5px auto;
    max-width: 200px;
  }
  .SignUp-paragraph p {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .google-desk,
  .btn-con-cuenta {
    display: none;
  }
  .SignUp {
    background-color: #ececec;
    height: 100vh;
  }
  .SignUp-container {
    max-width: 374px;
    margin: 0 auto;
  }
  .google-signup-container,
  .FormRegistrarse-btn-container,
  .FormInicioSesion-btn-container {
    max-width: 374px;
    height: 48px;
  }
  .google-signup-container {
    margin-bottom: 15.5px;
  }
  .google-signup-btn {
    border-radius: 12px;
    border: solid 2px #004b88;
    background-color: #004b88;
    color: #fff;
    font-size: 16px;
  }
  .aligning-items {
    justify-content: flex-start !important;
    margin: 16px 0 40px 0;
  }
  .SignUp-container h3 {
    padding-top: 40px;
    margin-top: 0;
    font-size: 24px;
  }
  .google-desk {
    display: none;
  }
  .SignUp-paragraph {
    margin-top: 32px !important;
    background-color: #ececec;
  }
  .line-container {
    margin: 0 auto 15.5px auto;
    max-width: 294px;
  }
  .SignUp-paragraph p {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .google-desk,
  .btn-con-cuenta {
    display: none;
  }
  .SignUp {
    background-color: #ececec;
    height: 100vh;
  }
  .SignUp-container {
    max-width: 335px;
    margin: 0 auto;
  }
  .google-signup-container,
  .FormRegistrarse-btn-container,
  .FormInicioSesion-btn-container {
    max-width: 335px;
    height: 48px;
  }
  .google-signup-container {
    margin-bottom: 15.5px;
  }
  .google-signup-btn {
    border-radius: 12px;
    border: solid 2px #004b88;
    background-color: #004b88;
    color: #fff;
    font-size: 16px;
  }
  .aligning-items {
    justify-content: flex-start !important;
    margin: 16px 0 40px 0;
  }
  .SignUp-container h3 {
    padding-top: 40px;
    margin-top: 0;
    font-size: 24px;
  }
  .google-desk {
    display: none;
  }
  .SignUp-paragraph {
    margin-top: 32px !important;
    background-color: #ececec;
  }
  .line-container {
    margin: 0 auto 15.5px auto;
    max-width: 255px;
  }
  .SignUp-paragraph p {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .google-desk,
  .btn-con-cuenta {
    display: none;
  }
  .SignUp {
    background-color: #ececec;
    height: 100%;
  }
  .SignUp-container {
    max-width: 320px;
    margin: 0 auto;
  }
  .google-signup-container,
  .FormRegistrarse-btn-container,
  .FormInicioSesion-btn-container {
    max-width: 320px;
    height: 48px;
  }
  .google-signup-container {
    margin-bottom: 15.5px;
  }
  .google-signup-btn {
    border-radius: 12px;
    border: solid 2px #004b88;
    background-color: #004b88;
    color: #fff;
    font-size: 16px;
  }
  .aligning-items {
    justify-content: flex-start !important;
    margin: 16px 0 40px 0;
  }
  .SignUp-container h3 {
    padding-top: 40px;
    margin-top: 0;
    font-size: 24px;
  }
  .google-desk {
    display: none;
  }
  .SignUp-paragraph {
    margin-top: 32px !important;
    background-color: #ececec;
    padding-bottom: 40px;
  }
  .Login-paragraph {
    padding-bottom: 155px;
  }
  .line-container {
    margin: 0 auto 15.5px auto;
    max-width: 240px;
  }
  .SignUp-paragraph p {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .google-desk,
  .btn-con-cuenta {
    display: none;
  }
  .SignUp {
    background-color: #ececec;
    height: 100%;
  }
  .SignUp-container {
    max-width: 280px;
    margin: 0 auto;
  }
  .google-signup-container,
  .FormRegistrarse-btn-container,
  .FormInicioSesion-btn-container {
    max-width: 280px;
    height: 48px;
  }
  .google-signup-container {
    margin-bottom: 15.5px;
  }
  .google-signup-btn {
    border-radius: 12px;
    border: solid 2px #004b88;
    background-color: #004b88;
    color: #fff;
    font-size: 16px;
  }
  .aligning-items {
    justify-content: flex-start !important;
    margin: 16px 0 40px 0;
  }
  .SignUp-container h3 {
    padding-top: 40px;
    margin-top: 0;
    font-size: 24px;
  }
  .google-desk {
    display: none;
  }
  .SignUp-paragraph {
    margin-top: 32px !important;
    background-color: #ececec;
    padding-bottom: 40px;
  }
  .Login-paragraph {
    padding-bottom: 70px;
  }
  .line-container {
    margin: 0 auto 15.5px auto;
    max-width: 200px;
  }
  .SignUp-paragraph p {
    font-size: 14px !important;
  }
}
