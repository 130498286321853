.ProjectsList-container-innerNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.ProjectsList-innerNav {
  opacity: 0.25;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  color: #000;
  margin: 0;
}

.ProjectsList-wrapper {
  width: 100%;
  padding: 13px;
  border-bottom: 1px solid rgb(191, 191, 191);
  cursor: pointer;
}

.ProjectsList-headers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  margin-top: 24px;
  padding: 13px;
  background-color: #f8f8f8;
}

.ProjectsList-header-title {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.ProjectsList-alter-msg-wrapper {
  padding: 13px;
}

.ProjectsList-alter-msg {
  opacity: 0.5;
  font-size: 15px;
  color: #000;
  text-align: center;
}

.ProjectList-dash-pag-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 29px;
}

.ProjectList-dash-showDocs {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 24px 0 0 0;
}
