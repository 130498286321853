.AddAgencyInputWrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 25px;
}

.AddAgencyFormLabel {
  font-size: 14px;
  letter-spacing: 0.47px;
  color: #000;
  margin-bottom: 10px;
}

.AddAgencyFormInput {
  width: 100%;
  height: 48px;
  border: 1px solid #b3b3b3;
  padding: 16px;
  font-size: 15px;
  letter-spacing: 0.5px;
  color: #000;
}

.AddAgencyFormInput:focus {
  outline: none;
}

.AddAgencyFormInput::placeholder {
  opacity: 0.5;
}

.AddAgencyFormButton {
  width: 100%;
  text-align: center;
}

.AddAgencyRadioWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-bottom: 48px;
}

.AddAgencyRadioTitle {
  font-size: 14px;
  letter-spacing: 0.47px;
  color: #000;
  margin-bottom: 25px;
}

.AddAgencyOptionsRadio:not(:last-child) {
  margin-right: 32px;
}

.AddAgencyOptionInput {
  display: none;
}

.AddAgencyOptionLabel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.AddAgencyOptionLabel > span {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: solid 1px #b3b3b3;
  margin-right: 8px;
}

.selected-icon-span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #ffffff;
}

.AddAgencyOptionInput:checked ~ .AddAgencyOptionLabel > span {
  background-color: #f47600;
  border: 1px solid #f47600;
}
