.EntregableDashList-title > h3 {
  font-size: 21px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: 0.7px;
  color: #58595c;
}

.EntregableDashList-title > h3 > span {
  color: #f47600;
}

.EntregableDashList-wrapper > table {
  box-shadow: none;
}

.EntregableDashList-wrapper > div > div {
  border-radius: 0;
}
