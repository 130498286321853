.Steps {
  position: relative;
  padding: 96px 0;
}

.Steps-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
  background-color: #f0f0f0;
  top: 0;
  opacity: 0;
}

.Steps-title {
  width: fit-content;
  margin: 0 auto 15px auto;
}

.Steps-title h2 {
  color: #58595c;
  text-align: center;
  font-size: 64px;
  margin-bottom: 24px;
  white-space: nowrap;
  border-right: 2px solid;
  width: 0%;
  overflow: hidden;
  visibility: hidden;
}

.Steps-title h2 strong span {
  display: inline-block;
  opacity: 0;
  transform: translate(20px, 15px);
}

.Steps-text {
  width: 100%;
  display: flex;
  align-items: center;
}

.Steps-text:not(:last-child) {
  margin-bottom: 25px;
}

.Steps-text-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background-image: url('../../img/steps/huella_.png');
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 25px;
  opacity: 0;
}

.Steps-text-img-m {
  transform: translateX(-40px);
}

.Steps-text-img h2 {
  text-align: center;
  color: #01355f;
  font-size: 64px;
  font-weight: 500;
}

.Steps-text-info {
  width: 88%;
  opacity: 0;
  transform: translateY(50%);
}

.Steps-text h3,
.Steps-text p {
  color: #01355f;
}

.Steps-text h3 {
  font-size: 32px;
  font-weight: 500;
}

.Steps-text p {
  font-size: 24px;
}

.Steps-animation {
  position: absolute;
  width: 72%;
  right: 0;
  bottom: -60%;
}

.Steps-animation img {
  width: 100%;
  height: 100%;
}

/*Media Queries*/
@media screen and (min-width: 1921px) {
  .Steps-animation {
    bottom: -20%;
    z-index: 20;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1441px) {
  .Steps-animation {
    width: 45%;
    bottom: -64%;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .Steps-animation {
    bottom: -64%;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .Steps-title h2,
  .Steps-text-img h2 {
    font-size: 54px;
  }
  .Steps-text p {
    font-size: 19px;
  }
  .Steps-text h3 {
    font-size: 28px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .Steps-title h2,
  .Steps-text-img h2 {
    font-size: 54px;
  }
  .Steps-text p {
    font-size: 19px;
  }
  .Steps-text h3 {
    font-size: 28px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .Steps-animation {
    display: none;
  }

  .Steps-title h2 {
    font-size: 35px;
    margin-bottom: 30px;
  }

  .Steps-text p {
    font-size: 17px;
  }

  .Steps-text-img-m {
    transform: translateX(0px);
  }

  .Steps-text-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    background-size: 100% 100%;
  }

  .Steps-text h3 {
    font-size: 20px;
  }
  .Steps-text-img h2 {
    font-size: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .Steps-animation {
    display: none;
  }

  .Steps-title h2 {
    font-size: 35px;
    margin-bottom: 30px;
  }

  .Steps-text p {
    font-size: 17px;
  }

  .Steps-text-img-m {
    transform: translateX(0px);
  }

  .Steps-text-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    background-size: 100% 100%;
  }

  .Steps-text h3 {
    font-size: 20px;
  }
  .Steps-text-img h2 {
    font-size: 50px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .Steps {
    padding: 48px 0;
  }
  .Steps-animation {
    display: none;
  }

  .Steps-title h2 {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .Steps-text p {
    font-size: 17px;
  }

  .Steps-text-img-m {
    transform: translateX(0px);
  }

  .Steps-text-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    background-size: 100% 100%;
  }

  .Steps-text h3 {
    font-size: 20px;
  }
  .Steps-text-img h2 {
    font-size: 50px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .Steps {
    padding: 48px 0;
  }
  .Steps-animation {
    display: none;
  }

  .Steps-title h2 {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .Steps-text p {
    font-size: 17px;
  }

  .Steps-text-img-m {
    transform: translateX(0px);
  }

  .Steps-text-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
    background-size: 100% 100%;
  }

  .Steps-text h3 {
    font-size: 20px;
  }
  .Steps-text-img h2 {
    font-size: 40px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .Steps {
    padding: 48px 0;
  }
  .Steps-animation {
    display: none;
  }

  .Steps-title h2 {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .Steps-text p {
    font-size: 17px;
  }

  .Steps-text-img-m {
    transform: translateX(0px);
  }

  .Steps-text-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
    background-size: 100% 100%;
  }

  .Steps-text h3 {
    font-size: 20px;
  }
  .Steps-text-img h2 {
    font-size: 40px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .Steps {
    padding: 3rem 0;
  }
  .Steps-animation {
    display: none;
  }

  .Steps-title h2 {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .Steps-text-img-m {
    transform: translateX(0px);
  }

  .Steps-text-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 62px;
    background-size: 100% 100%;
  }

  .Steps-text h3 {
    font-size: 18px;
  }

  .Steps-text p {
    font-size: 14px;
  }

  .Steps-text-img h2 {
    font-size: 35px;
    margin: 0;
  }
}
