.DropdownFilter .dropdown button {
  background-color: transparent;
  border: none !important;
  color: #58595c;
}

.DropdownFilter .dropdown-toggle::after {
  content: none !important;
}

.DropdownFilter .dropdown button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.DropdownFilter .btn-primary:not(:disabled):not(.disabled).active,
.DropdownFilter .btn-primary:not(:disabled):not(.disabled):active,
.DropdownFilter .show > .btn-primary.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
  color: #58595c;
}

.DropdownFilter-menu {
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: none !important;
}

.DropdownFilter-container {
  padding: 8px 15px;
}

.DropdownFilter-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  color: #58595c;
  font-size: 15px;
}

.DropdownFilter-radio {
  display: none;
}

.radio-icon {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 8px;
  border: solid 1px #979797;
}

.DropdownFilter-radio:checked ~ .radio-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  background-color: #f47600;
  border-radius: 50%;
  margin-right: 8px;
  border: none;
}

.DropdownFilter-radio:checked ~ .radio-icon .radio-icon-dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #fff;
  border-radius: 50%;
}
