.StageDetails {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(4, 1fr);
}

.StageDetails:first-child {
  border-radius: 15px 15px 0 0;
}

.StageDetails:last-child {
  border-radius: 0 0 15px 15px;
}

.StageDetails-container {
  padding: 16px 24px;
}

.StageDetails-num,
.StageDetails-days,
.StageDetails-deliverables {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.67px;
  color: #58595c;
  margin-bottom: 8px;
}

.StageDetails-name,
.StageDetails-duration,
.StageDetails-deliverables-count,
.StageDetails-unavailable {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.53px;
  color: #58595c;
  margin-bottom: 0;
}

.StageDetails-unavailable {
  font-weight: 500;
}

.StageDetails-details-container {
  width: 60%;
}

.StageDetails-link,
.StageDetails-link:hover {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.53px;
  text-align: center;
  color: #f47600;
  text-decoration: underline;
  text-align: center;
}

.StageDetails-duration,
.StageDetails-days,
.StageDetails-link-container,
.StageDetails-deliverables,
.StageDetails-deliverables-count {
  text-align: center;
}

.StageDetails-deliverables-movil,
.StageDetails-duration-movil,
.StageDetails-status-movil {
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.47px;
  color: #58595c;
  margin: 0;
}

.StageDetails-link-movil,
.StageDetails-link-movil:hover {
  color: #f47600;
  text-decoration: underline;
}

.StageDetails-deliverables-movil > span,
.StageDetails-duration-movil > span,
.StageDetails-status-movil > span {
  font-weight: 700;
}

/*Media Queries*/
@media screen and (min-width: 1441px) {
  .StageDetails-deliverables-movil,
  .StageDetails-duration-movil,
  .StageDetails-status-movil {
    display: none;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .StageDetails-deliverables-movil,
  .StageDetails-duration-movil,
  .StageDetails-status-movil {
    display: none;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .StageDetails-num,
  .StageDetails-days,
  .StageDetails-deliverables {
    font-size: 19px;
  }
  .StageDetails-deliverables-movil,
  .StageDetails-duration-movil,
  .StageDetails-status-movil {
    display: none;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .StageDetails-num,
  .StageDetails-days,
  .StageDetails-deliverables {
    font-size: 19px;
  }
  .StageDetails-deliverables-movil,
  .StageDetails-duration-movil,
  .StageDetails-status-movil {
    display: none;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .StageDetails-deliverables-movil,
  .StageDetails-duration-movil,
  .StageDetails-status-movil {
    display: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .ProjectStagesList {
    max-width: 510px;
    box-shadow: none !important;
  }
  .ProjectStagesList > div:nth-child(even) {
    background-color: #fff !important;
  }
  .StageDetails {
    grid-template-columns: repeat(1, 1fr);
    border: 1px solid #b3b3b3;
    margin-bottom: 16px;
    border-radius: 15px;
  }
  .StageDetails:first-child,
  .StageDetails:last-child {
    border-radius: 15px;
  }
  .StageDetails-container:not(:first-child):nth-child(1) {
    padding: 16px 24px 0 24px;
  }
  .StageDetails-container:not(:first-child):nth-child(2),
  .StageDetails-container:not(:first-child):nth-child(3) {
    padding: 0 24px;
  }
  .StageDetails-container:not(:first-child):nth-child(4) {
    padding: 0 24px 16px 24px;
  }
  .StageDetails-duration,
  .StageDetails-days,
  .StageDetails-link-container,
  .StageDetails-deliverables,
  .StageDetails-deliverables-count,
  .StageDetails-unavailable,
  .StageDetails-link {
    display: none;
  }
  .StageDetails-num {
    font-size: 18px;
  }
  .StageDetails-name {
    font-size: 14px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .ProjectStagesList {
    max-width: 510px;
    box-shadow: none !important;
  }
  .ProjectStagesList > div:nth-child(even) {
    background-color: #fff !important;
  }
  .StageDetails {
    grid-template-columns: repeat(1, 1fr);
    border: 1px solid #b3b3b3;
    margin-bottom: 16px;
    border-radius: 15px;
  }
  .StageDetails:first-child,
  .StageDetails:last-child {
    border-radius: 15px;
  }
  .StageDetails-container:not(:first-child):nth-child(1) {
    padding: 16px 24px 0 24px;
  }
  .StageDetails-container:not(:first-child):nth-child(2),
  .StageDetails-container:not(:first-child):nth-child(3) {
    padding: 0 24px;
  }
  .StageDetails-container:not(:first-child):nth-child(4) {
    padding: 0 24px 16px 24px;
  }
  .StageDetails-duration,
  .StageDetails-days,
  .StageDetails-link-container,
  .StageDetails-deliverables,
  .StageDetails-deliverables-count,
  .StageDetails-unavailable,
  .StageDetails-link {
    display: none;
  }
  .StageDetails-num {
    font-size: 18px;
    margin: 0;
  }
  .StageDetails-name {
    font-size: 14px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .ProjectStagesList {
    max-width: 510px;
    box-shadow: none !important;
  }
  .ProjectStagesList > div:nth-child(even) {
    background-color: #fff !important;
  }
  .StageDetails {
    grid-template-columns: repeat(1, 1fr);
    border: 1px solid #b3b3b3;
    margin-bottom: 16px;
    border-radius: 15px;
  }
  .StageDetails:first-child,
  .StageDetails:last-child {
    border-radius: 15px;
  }
  .StageDetails-container:not(:first-child):nth-child(1) {
    padding: 16px 24px 0 24px;
  }
  .StageDetails-container:not(:first-child):nth-child(2),
  .StageDetails-container:not(:first-child):nth-child(3) {
    padding: 0 24px;
  }
  .StageDetails-container:not(:first-child):nth-child(4) {
    padding: 0 24px 16px 24px;
  }
  .StageDetails-duration,
  .StageDetails-days,
  .StageDetails-link-container,
  .StageDetails-deliverables,
  .StageDetails-deliverables-count,
  .StageDetails-unavailable,
  .StageDetails-link {
    display: none;
  }
  .StageDetails-num {
    font-size: 18px;
    margin: 0;
  }
  .StageDetails-name {
    font-size: 14px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .ProjectStagesList {
    max-width: 510px;
    box-shadow: none !important;
  }
  .ProjectStagesList > div:nth-child(even) {
    background-color: #fff !important;
  }
  .StageDetails {
    grid-template-columns: repeat(1, 1fr);
    border: 1px solid #b3b3b3;
    margin-bottom: 16px;
    border-radius: 15px;
  }
  .StageDetails:first-child,
  .StageDetails:last-child {
    border-radius: 15px;
  }
  .StageDetails-container:not(:first-child):nth-child(1) {
    padding: 16px 24px 0 24px;
  }
  .StageDetails-container:not(:first-child):nth-child(2),
  .StageDetails-container:not(:first-child):nth-child(3) {
    padding: 0 24px;
  }
  .StageDetails-container:not(:first-child):nth-child(4) {
    padding: 0 24px 16px 24px;
  }
  .StageDetails-duration,
  .StageDetails-days,
  .StageDetails-link-container,
  .StageDetails-deliverables,
  .StageDetails-deliverables-count,
  .StageDetails-unavailable,
  .StageDetails-link {
    display: none;
  }
  .StageDetails-num {
    font-size: 18px;
    margin: 0;
  }
  .StageDetails-name {
    font-size: 14px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .ProjectStagesList {
    max-width: 510px;
    box-shadow: none !important;
  }
  .ProjectStagesList > div:nth-child(even) {
    background-color: #fff !important;
  }
  .StageDetails {
    grid-template-columns: repeat(1, 1fr);
    border: 1px solid #b3b3b3;
    margin-bottom: 16px;
    border-radius: 15px;
  }
  .StageDetails:first-child,
  .StageDetails:last-child {
    border-radius: 15px;
  }
  .StageDetails-container:not(:first-child):nth-child(1) {
    padding: 16px 24px 0 24px;
  }
  .StageDetails-container:not(:first-child):nth-child(2),
  .StageDetails-container:not(:first-child):nth-child(3) {
    padding: 0 24px;
  }
  .StageDetails-container:not(:first-child):nth-child(4) {
    padding: 0 24px 16px 24px;
  }
  .StageDetails-duration,
  .StageDetails-days,
  .StageDetails-link-container,
  .StageDetails-deliverables,
  .StageDetails-deliverables-count,
  .StageDetails-unavailable,
  .StageDetails-link {
    display: none;
  }
  .StageDetails-num {
    font-size: 16px;
    margin: 0;
  }
  .StageDetails-name,
  .StageDetails-deliverables-movil,
  .StageDetails-duration-movil,
  .StageDetails-status-movil {
    font-size: 12px;
  }
}
