/* .Dashboard {
  min-height: 100vh;
  width: 100%;
  background-color: #f2f2f2;
}

.Dashboard-details {
  height: 100vh;
}

.Dashboad-pannel-options {
  max-width: 200px !important;
  height: 100%;
}

.Dashboard-left-pannel {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: -11px 0px 20px 1px #000;
  z-index: 10;
}

.Dashboard-top-pannel {
  width: 100%;
  height: 70px;
  background-color: #f7f7f7;
  z-index: 0;
  box-shadow: 5px 0px 25px -7px #000;
}

.Dashboard-left-pannel-options {
  position: absolute;
  top: 20%;
}

.Dashboard-left-pannel-list {
  padding: 0;
  list-style: none;
}

.Dashboard-left-pannel-link {
  display: inline-block;
  width: 100%;
  text-decoration: none !important;
  color: #bfbfbf;
  font-weight: 500;
  padding: 8px 50px 8px 50px;
  border-left: 4px solid transparent;
}

.Dashboard-left-pannel-link:hover {
  color: #f47600;
  border-left: 4px solid #f47600;
}

.Dashboard-left-pannel-link div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Dashboard-left-pannel-link div span {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #bfbfbf;
  border-radius: 50%;
  margin-right: 10px;
}

.Dashboard-active-link {
  color: #f47600;
  border-left: 4px solid #f47600;
}

.Dashboard-left-img {
  width: 80%;
  height: 21px;
  background-image: url('../../img/dashboard/rhino_logo_dash.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 14% auto 0 auto;
}
 */
.Dashboard {
  width: 100%;
  min-height: 100vh;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: row;
  /* display: grid;
  grid-template-columns: 200px auto;
  grid-template-rows: repeat(1, 1fr); */
}

.Dashboard-left-panel {
  /* max-width: 200px; */
  width: 200px;
  min-height: 100vh;
  background-color: #fff;
  box-shadow: 8px 0 16px 0 rgba(0, 0, 0, 0.1);
}
.Dashboard-right-panel {
  width: 100%;
  min-height: 100vh;
  background-color: #f2f2f2;
}

.Dashboard-navbar {
  height: 75px;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Dashboard-navbar-icon-line {
  width: 20px;
  height: 1px;
  opacity: 0.5;
  border: solid 1px #000;
}

.Dashboar-navbar-hamburguer {
  cursor: pointer;
}

.Dashboard-navbar-icon-line:not(:last-child) {
  margin-bottom: 7px;
}

.Dashboard-left-paner-img {
  margin-top: 18px;
  padding: 0 45px;
}

/*Left Panel Options*/
.Dashboard-left-pannel-options {
  position: absolute;
  top: 20%;
}

.Dashboard-left-pannel-list {
  padding: 0;
  list-style: none;
}

.Dashboard-left-pannel-link {
  display: inline-block;
  width: 100%;
  text-decoration: none !important;
  color: #bfbfbf;
  font-weight: 500;
  padding: 8px 50px 8px 50px;
  border-left: 4px solid transparent;
  font-size: 14px;
}

.Dashboard-left-pannel-link:hover {
  color: #f47600;
  border-left: 4px solid #f47600;
}

.Dashboard-left-pannel-link div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Dashboard-left-pannel-link div span {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #bfbfbf;
  border-radius: 50%;
  margin-right: 20px;
}

.Dashboard-active-link {
  color: #f47600;
  border-left: 4px solid #f47600;
}

/*Dashboar right container*/
.Dashboar-right-panel-detail-container {
  width: 1140px;
  height: auto;
  border-radius: 3px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin: 50px auto 50px auto;
  padding: 40px 20px;
}

/*Media Queries*/
@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .Dashboar-right-panel-detail-container {
    max-width: 1066px;
    height: auto;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .Dashboar-right-panel-detail-container {
    max-width: 980px;
    height: auto;
  }
}
