.EntregableDetailsUser {
  padding: 80px 0;
  margin-bottom: 13rem;
}

.EntregableDetailsUser-btn-container {
  text-align: center;
}

.EntregableDetailsUser-btn {
  background-color: transparent;
  border-radius: 18px;
  padding: 9px 71px;
  border: 1px solid #f47600;
  color: #f47600;
  font-weight: 500;
  margin-top: 25px;
}

.EntregableDetailsUser-btn:focus {
  outline: none;
}

/*Media Queries*/
@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .EntregableDetailsUser {
    padding: 80px 0;
    margin-bottom: 10rem;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .EntregableDetailsUser {
    padding: 80px 0;
    margin-bottom: 7rem;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .EntregableDetailsUser {
    padding: 80px 0;
    margin-bottom: 6rem;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .EntregableDetailsUser {
    padding: 80px 0 0 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .EntregableDetailsUser {
    padding: 80px 0 0 0;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .EntregableDetailsUser {
    padding: 80px 0 0 0;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .EntregableDetailsUser {
    padding: 80px 0 0 0;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .EntregableDetailsUser {
    padding: 80px 0 0 0;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .EntregableDetailsUser {
    padding: 80px 0 0 0;
  }
}
