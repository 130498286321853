.EntregablesList {
  padding: 80px 0;
  margin-bottom: 6rem;
}

.EntregableList-status-link,
.EntregableList-status-link:hover {
  color: #58595c;
}

@media screen and (min-width: 1441px) {
  .EntregablesList {
    margin-bottom: 13rem;
  }
  .EntregableList-wrapper-mobile {
    display: none;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .EntregablesList {
    margin-bottom: 10rem;
  }
  .EntregableList-wrapper-mobile {
    display: none;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .EntregablesList {
    margin-bottom: 8rem;
  }
  .EntregableList-wrapper-mobile {
    display: none;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .EntregableList-wrapper-mobile {
    display: none;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .EntregableList-wrapper {
    display: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .EntregableList-wrapper {
    display: none;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .EntregableList-wrapper {
    display: none;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .EntregableList-wrapper {
    display: none;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .EntregableList-wrapper {
    display: none;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .EntregableList-wrapper {
    display: none;
  }
}
