.Add-button {
  font-size: 12px;
  letter-spacing: 0.4px;
  color: #000;
  background-color: transparent;
  border: none;
}

.Add-button:focus {
  outline: none;
}
