.BriefDashboard-btns {
  width: 35px;
  height: 35px;
  text-align: center;
  border-radius: 10px;
  border: none;
  color: #f47600;
  cursor: pointer;
  background-color: transparent;
}

.BriefDashboard-btns:focus,
.BriefDashboard-other-btns:focus {
  outline: none;
}

.BriefDashboard-btns:not(:last-child) {
  margin-right: 6px;
}

.BriefDashboard-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.BriefDashboard-other-btns {
  font-size: 15px;
  font-weight: 700;
  color: #f47600;
  border: none;
  background-color: transparent;
}
