.EditUserProfile {
  padding: 80px 0 0 0;
  margin-bottom: 25rem;
}

.UserProfile-isEdited {
  position: relative;
  max-width: 1200px;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 24px 20px 40px 20px;
}

.UserProfile-isEdited-paragraph {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.67px;
  color: #58595c;
  margin-bottom: 45px;
}

.UserProfile-input-wrapper {
  margin-bottom: 24px;
}

.UserProfile-input-wrapper > div label {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.53px;
  color: #58595c;
}

.UserProfile-input-wrapper > div input {
  height: 48px;
  border-radius: 8px;
  border: solid 1px #c0c0c0;
  font-size: 16px;
}

.UserProfile-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
  position: absolute;
  width: 100%;
  left: 0;
}

.UserProfile-link-cancel-wrapper {
  margin-left: 125px;
}

.UserProfile-link-cancel,
.UserProfile-link-cancel:hover {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.67px;
  color: #ff0000;
  border: none;
  background-color: transparent;
  text-decoration: none;
}

.UserProfile-btn-save-wrapper {
  width: 360px;
  height: 64px;
}

.UserProfile-btn-save-wrapper > div button {
  background-color: #f47600;
  color: #fff;
}

/*Media Queries*/
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .UserProfile-isEdited {
    max-width: 100%;
    box-shadow: none;
    padding: 0;
    margin: 32px 0 0 0;
  }
  .UserProfile-isEdited-paragraph {
    display: none;
  }
  .UserProfile-input-wrapper {
    border-bottom: 1px solid #b3b3b3;
    padding: 0 10px;
  }
  .UserProfile-input-wrapper > div {
    flex-direction: row;
    align-items: flex-end;
  }
  .UserProfile-input-wrapper > div > label {
    width: 20%;
    font-size: 14px;
    color: #58595c;
  }
  .UserProfile-input-wrapper > div > input {
    width: 80%;
    border: none;
    font-size: 17px;
  }
  .UserProfile-input-wrapper > div > input:focus {
    border-color: transparent;
    box-shadow: none;
  }
  .UserProfile-btns {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    margin-top: 41px;
  }
  .UserProfile-link-cancel-wrapper {
    margin: 0;
    grid-row-start: 2;
    grid-row-end: -1;
    text-align: center;
  }
  .UserProfile-btn-save-wrapper {
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .UserProfile-isEdited {
    max-width: 100%;
    box-shadow: none;
    padding: 0;
    margin: 32px 0 0 0;
  }
  .UserProfile-isEdited-paragraph {
    display: none;
  }
  .UserProfile-input-wrapper {
    border-bottom: 1px solid #b3b3b3;
    padding: 0 10px;
  }
  .UserProfile-input-wrapper > div {
    flex-direction: row;
    align-items: flex-end;
  }
  .UserProfile-input-wrapper > div > label {
    width: 20%;
    font-size: 14px;
    color: #58595c;
  }
  .UserProfile-input-wrapper > div > input {
    width: 80%;
    border: none;
    font-size: 17px;
  }
  .UserProfile-input-wrapper > div > input:focus {
    border-color: transparent;
    box-shadow: none;
  }
  .UserProfile-btns {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    margin-top: 41px;
  }
  .UserProfile-link-cancel-wrapper {
    margin: 0;
    grid-row-start: 2;
    grid-row-end: -1;
    text-align: center;
  }
  .UserProfile-btn-save-wrapper {
    margin: 0 auto;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .EditUserProfile {
    margin-bottom: 17rem;
  }
  .UserProfile-isEdited {
    max-width: 100%;
    box-shadow: none;
    padding: 0;
    margin: 32px 0 0 0;
  }
  .UserProfile-isEdited-paragraph {
    display: none;
  }
  .UserProfile-input-wrapper {
    border-bottom: 1px solid #b3b3b3;
    padding: 0 10px;
  }
  .UserProfile-input-wrapper > div {
    flex-direction: row;
    align-items: flex-end;
  }
  .UserProfile-input-wrapper > div > label {
    width: 24%;
    font-size: 14px;
    color: #58595c;
  }
  .UserProfile-input-wrapper > div > input {
    width: 76%;
    border: none;
    font-size: 17px;
  }
  .UserProfile-input-wrapper > div > input:focus {
    border-color: transparent;
    box-shadow: none;
  }
  .UserProfile-btns {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    margin-top: 41px;
  }
  .UserProfile-link-cancel-wrapper {
    margin: 0;
    grid-row-start: 2;
    grid-row-end: -1;
    text-align: center;
  }
  .UserProfile-link-cancel {
    font-size: 16px;
  }
  .UserProfile-btn-save-wrapper {
    margin: 0 auto;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .EditUserProfile {
    margin-bottom: 17rem;
  }
  .UserProfile-isEdited {
    max-width: 100%;
    box-shadow: none;
    padding: 0;
    margin: 32px 0 0 0;
  }
  .UserProfile-isEdited-paragraph {
    display: none;
  }
  .UserProfile-input-wrapper {
    border-bottom: 1px solid #b3b3b3;
    padding: 0 10px;
  }
  .UserProfile-input-wrapper > div {
    flex-direction: row;
    align-items: flex-end;
  }
  .UserProfile-input-wrapper > div > label {
    width: 30%;
    font-size: 14px;
    color: #58595c;
  }
  .UserProfile-input-wrapper > div > input {
    width: 70%;
    border: none;
    font-size: 17px;
  }
  .UserProfile-input-wrapper > div > input:focus {
    border-color: transparent;
    box-shadow: none;
  }
  .UserProfile-btns {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    margin-top: 41px;
  }
  .UserProfile-link-cancel-wrapper {
    margin: 0;
    grid-row-start: 2;
    grid-row-end: -1;
    text-align: center;
  }
  .UserProfile-link-cancel {
    font-size: 16px;
  }
  .UserProfile-btn-save-wrapper {
    margin: 0 auto;
    width: 100%;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .EditUserProfile {
    margin-bottom: 17rem;
  }
  .UserProfile-isEdited {
    max-width: 100%;
    box-shadow: none;
    padding: 0;
    margin: 32px 0 0 0;
  }
  .UserProfile-isEdited-paragraph {
    display: none;
  }
  .UserProfile-input-wrapper {
    border-bottom: 1px solid #b3b3b3;
    padding: 0 10px;
  }
  .UserProfile-input-wrapper > div {
    flex-direction: row;
    align-items: flex-end;
  }
  .UserProfile-input-wrapper > div > label {
    width: 30%;
    font-size: 14px;
    color: #58595c;
  }
  .UserProfile-input-wrapper > div > input {
    width: 70%;
    border: none;
    font-size: 17px;
  }
  .UserProfile-input-wrapper > div > input:focus {
    border-color: transparent;
    box-shadow: none;
  }
  .UserProfile-btns {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    margin-top: 41px;
  }
  .UserProfile-link-cancel-wrapper {
    margin: 0;
    grid-row-start: 2;
    grid-row-end: -1;
    text-align: center;
  }
  .UserProfile-link-cancel {
    font-size: 16px;
  }
  .UserProfile-btn-save-wrapper {
    margin: 0 auto;
    width: 100%;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .EditUserProfile {
    margin-bottom: 17rem;
  }
  .UserProfile-isEdited {
    max-width: 100%;
    box-shadow: none;
    padding: 0;
    margin: 32px 0 0 0;
  }
  .UserProfile-isEdited-paragraph {
    display: none;
  }
  .UserProfile-input-wrapper {
    border-bottom: 1px solid #b3b3b3;
    padding: 0 10px;
  }
  .UserProfile-input-wrapper > div {
    flex-direction: row;
    align-items: flex-end;
  }
  .UserProfile-input-wrapper > div > label {
    width: 30%;
    font-size: 14px;
    color: #58595c;
  }
  .UserProfile-input-wrapper > div > input {
    width: 70%;
    border: none;
    font-size: 17px;
  }
  .UserProfile-input-wrapper > div > input:focus {
    border-color: transparent;
    box-shadow: none;
  }
  .UserProfile-btns {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    margin-top: 41px;
  }
  .UserProfile-link-cancel-wrapper {
    margin: 0;
    grid-row-start: 2;
    grid-row-end: -1;
    text-align: center;
  }
  .UserProfile-link-cancel {
    font-size: 16px;
  }
  .UserProfile-btn-save-wrapper {
    margin: 0 auto;
    width: 100%;
  }
}
