.StepProgress {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Steps-line-container {
  width: 15%;
  margin-left: 20px;
}

@media screen and (min-width: 1921px) {
  .StepProgress {
    display: none;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1441px) {
  .StepProgress {
    display: none;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .StepProgress {
    display: none;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .StepProgress {
    display: none;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .StepProgress {
    display: none;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .StepProgress {
    padding: 24px 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .StepProgress {
    padding: 24px 0;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .StepProgress {
    padding: 24px 0;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .StepProgress {
    padding: 24px 0;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .StepProgress {
    padding: 24px 0;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .StepProgress {
    padding: 24px 0;
  }
}
