.CollapsableItems-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.CollapsableItems-card {
  border: none;
  background-color: transparent;
}

.CollapsableItems-header {
  background-color: transparent;
  border-bottom: none;
  padding: 0 0 7px 0;
}

.CollapsableItems-paragraph-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin: 0 auto;
}

.CollapsableItems-body {
  padding: 5px 0;
}

.CollapsableItems-paragraph {
  margin-bottom: 0;
  color: #58595c;
  font-size: 18px;
  font-weight: 500;
}

.card {
  border-radius: 15px;
}
