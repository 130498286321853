.DashboardPackage {
  width: 100%;
  padding: 12px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.DashboardPackage-title {
  font-size: 13px;
  color: #000;
  margin: 0;
  flex-grow: 1;
}

.DashboardPackage-line {
  max-width: 948px;
  height: 1px;
  margin: 0 auto;
  background-color: #979797;
  opacity: 0.5;
}

/*Media Queries*/
@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .DashboardPackage-line {
    max-width: 874px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .DashboardPackage-line {
    max-width: 788px;
  }
}
