.DashboardPackages {
  width: 100%;
  min-height: 100vh;
  background-color: #f2f2f2;
}

.DashboardPackages-main-container {
  max-width: 996px;
  height: 100%;
  margin: 0 auto;
}

.DashboardPackages-package {
  width: 100%;
  height: 100%;
  margin: 50px auto 0 auto;
}

.DashboardPackages-btns {
  width: 100%;
  margin: 0 auto;
  padding: 15px 0 64px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DashboardPackages-inner-menu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 32px;
}

.DashboardPackages-btn-stages,
.DashboardPackages-btn-payment {
  width: 50%;
}

.Continue-btn {
  border: 1px solid #f47600;
  font-size: 14px;
  padding: 6px 32px;
  color: #f47600;
  border-radius: 6px;
}

.Continue-btn:focus {
  outline: none;
}

/*Media Queries*/
@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .DashboardPackages-main-container {
    max-width: 922px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .DashboardPackages-main-container {
    max-width: 836px;
  }
}
