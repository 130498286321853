.Categories-list-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.Categories-list-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #b3b3b3;
  opacity: 0.2;
}

.Categories-container {
  width: 60%;
  margin: 0 auto;
}

.Categories-title {
  color: #58595c;
  padding-top: 130px;
  margin-bottom: 40px;
  font-size: 27px;
}

.Categories-packages-container {
  padding: 64px 120px 64px 24px;
  margin: 0 0 25rem 0;
}

.Categories-title-packages {
  color: #f47600;
  font-weight: 500;
  font-size: 32px;
}

/*Button*/
.Categories-btn-container {
  text-align: right;
}

.Categories-btn {
  border: none;
  background-color: transparent;
  color: #f47600;
  font-size: 20px;
}

.Categories-btn:focus {
  outline: none;
}

/*Media Queries*/
@media screen and (min-width: 1441px) {
  .Categories-title-packages-movil {
    display: none;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .Categories-packages-container {
    margin: 0 0 17rem 0;
  }
  .Categories-title-packages-movil {
    display: none;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .Categories-packages-container {
    margin: 0 0 18rem 0;
  }
  .Categories-title-packages-movil {
    display: none;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .Categories-packages-container {
    margin: 0 0 14rem 0;
  }
  .Categories-title-packages-movil {
    display: none;
  }
}

@media screen and (max-width: 1024px) and (min-width: 1020px) {
  .Categories-packages-container {
    margin: 0 0 14rem 0;
  }
  .Categories-title-packages-movil {
    display: none;
  }
  .Categories-nav-bg {
    display: none;
  }
}

@media screen and (max-width: 1019px) and (min-width: 768px) {
  .Cotegories-list-movil {
    display: none;
  }
  .Categories-packages-container {
    margin: 0 0 7rem 0;
    padding: 132px 50px;
  }
  .Categories-title-packages-desk {
    display: none;
  }
  .Categories-title-packages {
    color: #f47600;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 17px;
  }
  .Categories-nav-bg {
    display: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .Cotegories-list-movil {
    display: none;
  }
  .Categories-title-packages {
    color: #58595c;
    margin: 32px 0 17px 0;
  }
  .Categories-packages-container {
    margin: 0 0 10rem 0;
    padding: 110px 24px 64px 24px;
  }
  .Categories-title-packages-desk {
    display: none;
  }
  .Categories-nav-bg {
    display: none;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .Cotegories-list-movil {
    display: none;
  }
  .Categories-title-packages {
    color: #58595c;
    font-size: 24px;
    margin: 80px 0 17px 0;
  }
  .Categories-packages-container {
    padding: 80px 23px;
    margin: 0 0 10rem 0;
  }
  .Categories-title-packages-desk {
    display: none;
  }
  .Categories-nav-bg {
    display: none;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .Cotegories-list-movil {
    display: none;
  }
  .Categories-title-packages {
    color: #58595c;
    font-size: 24px;
    margin: 80px 0 17px 0;
  }
  .Categories-packages-container {
    padding: 80px 23px;
    margin: 0 0 10rem 0;
  }
  .Categories-title-packages-desk {
    display: none;
  }
  .Categories-nav-bg {
    display: none;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .Cotegories-list-movil {
    display: none;
  }
  .Categories-title-packages {
    color: #58595c;
    font-size: 24px;
    margin: 80px 0 17px 0;
  }
  .Categories-packages-container {
    padding: 80px 23px;
    margin: 0 0 10rem 0;
  }
  .Categories-title-packages-desk {
    display: none;
  }
  .Categories-nav-bg {
    display: none;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .Categories-title-packages {
    color: #58595c;
    font-size: 24px;
    margin: 80px 0 17px 0;
  }
  .Cotegories-list-movil {
    display: none;
  }
  .Categories-packages-container {
    padding: 80px 23px;
    margin: 0 0 10rem 0;
  }
  .Categories-title-packages-desk {
    display: none;
  }
  .Categories-nav-bg {
    display: none;
  }
}
