.BriefForm {
  padding: 80px 0;
}

.BriefForm-details {
  max-width: 792px;
  margin: 0 auto;
}

.BriefForm-details h3 {
  font-size: 32px;
  margin-bottom: 24px;
}

.BriefForm-details-paragraph {
  text-align: center;
  color: #58595c;
  font-size: 20px;
  margin-top: 10px;
  font-weight: 400;
}

.centerForm {
  margin: 48px auto 0 auto;
  max-width: 792px;
  padding: 0;
}

.col-max-1 {
  max-width: 690px;
  padding: 0;
}

.col-max-2 {
  max-width: 486px;
  -ms-flex: 2 0 33.333333%;
  flex: 2 0 33.333333%;
}

.PackageDetails-purchase-details {
  max-width: 374px;
  margin: 0 auto;
}

.PackageDetails-purchase {
  position: relative;
  max-width: 486px;
  height: 393px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.PackageDetails-purchase-title h3 {
  font-size: 32px;
  letter-spacing: 1.07px;
  text-align: center;
  color: #58595c;
  padding: 32px 0 48px 0;
  margin-bottom: 0;
}

.PackageDetails-purchase-noPackage p {
  margin: 0;
  font-size: 20px;
  color: #58595c;
  text-align: center;
}

.PackageDetails-purchase-link-container {
  margin-top: 30px;
}

.PackageDetails-purchase-paragraph {
  font-size: 16px;
  letter-spacing: 0.53px;
  color: #58595c;
  margin-bottom: 0;
}

.PackageDetails-purchase-paragraph:nth-child(2) {
  font-size: 20px;
  letter-spacing: 0.53px;
  color: #58595c;
  margin-bottom: 12px;
}

.PackageDetails-delete-button {
  font-size: 18px;
  line-height: 1.42;
  letter-spacing: 0.6px;
  color: #ff0000;
  background-color: #fff;
  border: none;
  padding: 0;
}

.PackageDetails-delete-button:focus {
  outline: none;
}

.mgTop {
  margin-top: 20px;
}

.online {
  width: 85%;
  justify-self: flex-end;
}

.price-package-paragraph-container {
  max-width: 374px;
  position: absolute;
  bottom: 0;
  margin: 0 auto;
}

.price-package-paragraph {
  margin: 0;
  padding: 0 0 40px 0;
  font-size: 20.8px;
  font-weight: 500;
  letter-spacing: 0.67px;
  color: #58595c;
}

.Total-price-header {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 1.07px;
  color: #58595c;
  margin-bottom: 24px;
  max-width: 690px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-btn-8 {
  margin-top: 80px;
}

/*Styles for details*/
.Brief-product-details-mobile {
  max-width: 642px;
  height: 100%;
  background-color: #ffffff;
  border: 1px solid #b3b3b3;
  padding: 16px 24px;
  border-radius: 15px;
  margin: 0 auto;
}

.Brief-product-movil-title {
  font-size: 20px;
  letter-spacing: 0.67px;
  text-align: center;
  color: #58595c;
  margin: 0 0 24px 0;
}

.Brief-product-movil-package {
  font-size: 15px;
  letter-spacing: 0.5px;
  color: #58595c;
  margin: 0;
}

.Brief-product-movil-price {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.64px;
  color: #58595c;
  margin: 0;
}

.PackageDetails-delete-button-movil {
  font-size: 20px;
  line-height: 1.28;
  letter-spacing: 0.67px;
  color: red;
  border: none;
  background-color: #fff;
  margin: 16px 0 48px 0;
}

.Brief-product-total-movil {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.64px;
  text-align: center;
  color: #58595c;
  margin: 0;
}

.No-package-found {
  font-size: 20px;
  letter-spacing: 0.67px;
  text-align: center;
  color: #58595c;
}

/*Tranferencia*/
.BriefForm-transfer-wrapper {
  padding: 0 24px;
}

.BriefForm-transfer-btn-wrapper > div > button {
  background-color: transparent;
}

/*Media Queries*/
@media screen and (min-width: 1441px) {
  .Brief-product-details-mobile {
    display: none;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .Brief-product-details-mobile {
    display: none;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .BriefForm-details-paragraph {
    font-size: 19px;
  }
  .Brief-product-details-mobile {
    display: none;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1250px) {
  .BriefForm-details-paragraph {
    font-size: 19px;
  }
  .Brief-product-details-mobile {
    display: none;
  }
}

@media screen and (max-width: 1249px) and (min-width: 1024px) {
  .Brief-product-details-mobile {
    display: none;
  }
  .Total-price-header {
    max-width: 652px;
  }
  .Payment-container {
    padding: 40px 24px;
  }
  .PackageDetails-purchase {
    padding: 0 24px;
  }
  .Brief-main-title {
    max-width: 743px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .Brief-section {
    padding: 50px 0;
  }
  .BriefForm {
    padding: 36px 0;
  }
  .centerForm {
    max-width: 728px;
  }
  .BriefForm-details {
    width: 70%;
  }
  .col-max-1,
  .Total-price-header,
  .col-max-2,
  .PackageDetails-purchase,
  .Payment-container,
  .Brief-main-title {
    max-width: 728px;
    margin: 0 auto;
  }

  .Total-price-header,
  .Payment-imgs {
    display: none;
  }

  .col-max-2 {
    padding: 0;
    margin-top: 24px;
  }
  .Payment-container {
    background-color: #fff;
    border: none;
    box-shadow: none;
  }
  .special-btn-bg div button {
    background-color: #fff;
  }
  .PackageDetails-purchase {
    display: none;
  }
  .back-btn-8 {
    margin: 0;
  }
  .No-package-found {
    font-size: 16px;
    margin: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 736px) {
  .Brief-section {
    padding: 50px 0;
  }
  .BriefForm {
    padding: 36px 0;
  }
  .centerForm {
    max-width: 374px;
    margin: 24px auto 0 auto;
  }
  .Brief-form-container {
    margin-bottom: 24px;
  }
  .BriefForm-details {
    width: 85%;
  }
  .BriefForm-details > h3 {
    font-size: 20px;
    margin-bottom: 16px;
  }
  .Brief-main-title > h6 {
    margin: 0;
  }
  .BriefForm-details > p {
    font-size: 14px;
    margin: 0;
  }
  .col-max-1,
  .Total-price-header,
  .col-max-2,
  .PackageDetails-purchase,
  .Payment-container,
  .Brief-main-title {
    max-width: 374px;
    margin: 0 auto;
  }
  .Total-price-header {
    display: none;
  }
  .col-max-2 {
    padding: 0;
    margin-top: 24px;
  }
  .price-package-paragraph-container,
  .PackageDetails-purchase-details {
    max-width: 326px;
  }
  .back-btn-8 {
    margin-top: 24px;
  }
  .noMargin {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Brief-product-details-mobile {
    max-width: 374px;
  }
  .Payment-container {
    background-color: #fff;
    border: none;
    box-shadow: none;
  }
  .Payment-container form {
    margin: 0;
    max-width: 374px;
  }
  .special-btn-bg div button {
    background-color: #fff;
  }
  .PackageDetails-purchase,
  .Payment-imgs {
    display: none;
  }
  .back-btn-8 {
    margin: 0;
  }
  .No-package-found {
    font-size: 16px;
    margin: 0;
  }
}

@media screen and (max-width: 735px) and (min-width: 667px) {
  .Brief-section {
    padding: 50px 0;
  }
  .BriefForm {
    padding: 36px 0;
  }
  .centerForm {
    max-width: 335px;
    margin: 24px auto 0 auto;
  }
  .Brief-form-container {
    margin-bottom: 24px;
  }
  .BriefForm-details {
    width: 65%;
  }
  .BriefForm-details > h3 {
    font-size: 20px;
    margin-bottom: 16px;
  }
  .Brief-main-title > h6 {
    margin: 0;
  }
  .BriefForm-details > p {
    font-size: 14px;
    margin: 0;
  }

  .Total-price-header,
  .col-max-2,
  .PackageDetails-purchase,
  .Payment-container,
  .Brief-main-title {
    max-width: 335px;
    margin: 0 auto;
  }
  .Total-price-header {
    display: none;
  }
  .col-max-2 {
    padding: 0;
    margin-top: 24px;
  }
  .price-package-paragraph-container,
  .PackageDetails-purchase-details {
    max-width: 278px;
  }
  .noMargin {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Brief-product-details-mobile {
    max-width: 335px;
  }
  .Payment-container {
    background-color: #fff;
    border: none;
    box-shadow: none;
  }
  .Payment-container form {
    margin: 0;
    max-width: 335px;
  }
  .special-btn-bg div button {
    background-color: #fff;
  }
  .PackageDetails-purchase,
  .Payment-imgs {
    display: none;
  }
  .back-btn-8 {
    margin: 0;
  }
  .No-package-found {
    font-size: 16px;
    margin: 0;
  }
}

@media screen and (max-width: 666px) and (min-width: 601px) {
  .Brief-section {
    padding: 50px 0;
  }
  .BriefForm {
    padding: 36px 0;
  }
  .centerForm {
    max-width: 320px;
    margin: 24px auto 0 auto;
  }
  .Brief-form-container {
    margin-bottom: 24px;
  }
  .BriefForm-details {
    width: 65%;
  }
  .BriefForm-details > h3 {
    font-size: 20px;
    margin-bottom: 16px;
  }
  .Brief-main-title > h6 {
    margin: 0;
  }
  .BriefForm-details > p {
    font-size: 14px;
    margin: 0;
  }

  .Total-price-header,
  .col-max-2,
  .PackageDetails-purchase,
  .Payment-container,
  .Brief-main-title {
    max-width: 320px;
    margin: 0 auto;
  }
  .Total-price-header {
    display: none;
  }
  .col-max-2 {
    padding: 0;
    margin-top: 24px;
  }
  .price-package-paragraph-container,
  .PackageDetails-purchase-details {
    max-width: 278px;
  }
  .noMargin {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .Brief-product-details-mobile {
    max-width: 320px;
  }
  .Payment-container {
    background-color: #fff;
    border: none;
    box-shadow: none;
  }
  .Payment-container form {
    margin: 0;
    max-width: 320px;
  }
  .special-btn-bg div button {
    background-color: #fff;
  }
  .PackageDetails-purchase,
  .Payment-imgs {
    display: none;
  }
  .back-btn-8 {
    margin: 0;
  }
  .No-package-found {
    font-size: 16px;
    margin: 0;
  }
}

@media screen and (max-width: 600px) and (min-width: 569px) {
  .Brief-section {
    padding: 50px 0;
  }
  .BriefForm {
    padding: 36px 0;
  }
  .centerForm {
    max-width: 560px;
  }
  .BriefForm-details {
    width: 70%;
  }
  .col-max-1,
  .Total-price-header,
  .col-max-2,
  .PackageDetails-purchase,
  .Payment-container,
  .Brief-main-title {
    max-width: 560px;
    margin: 0 auto;
  }

  .Total-price-header {
    display: none;
  }

  .col-max-2 {
    padding: 0;
    margin-top: 24px;
  }

  .Payment-container {
    background-color: #fff;
    border: none;
    box-shadow: none;
  }
  .special-btn-bg div button {
    background-color: #fff;
  }
  .PackageDetails-purchase,
  .Payment-imgs {
    display: none;
  }
  .back-btn-8 {
    margin: 0;
  }
  .two-btns {
    flex-direction: column;
  }
  .two-btns > div:first-child {
    margin-bottom: 20px;
  }
  .No-package-found {
    font-size: 16px;
    margin: 0;
  }
}

@media screen and (max-width: 568px) and (min-width: 480px) {
  .Brief-section {
    padding: 50px 0;
  }
  .BriefForm {
    padding: 36px 0;
  }
  .centerForm {
    max-width: 280px;
    margin: 24px auto 0 auto;
  }
  .Brief-form-container {
    margin-bottom: 24px;
  }
  .BriefForm-details {
    width: 65%;
  }
  .BriefForm-details > h3 {
    font-size: 20px;
    margin-bottom: 16px;
  }
  .Brief-main-title > h6 {
    margin: 0;
  }
  .BriefForm-details > p {
    font-size: 14px;
    margin: 0;
  }
  .Total-price-header,
  .col-max-2,
  .PackageDetails-purchase,
  .Payment-container,
  .Brief-main-title {
    max-width: 280px;
    margin: 0 auto;
  }
  .Total-price-header {
    display: none;
  }
  .col-max-2 {
    padding: 0;
    margin-top: 24px;
  }
  .price-package-paragraph-container,
  .PackageDetails-purchase-details {
    max-width: 232px;
  }
  .noMargin {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .Brief-product-details-mobile {
    max-width: 280px;
  }
  .Payment-container {
    background-color: #fff;
    border: none;
    box-shadow: none;
  }
  .Payment-container form {
    margin: 0;
    max-width: 280px;
  }
  .special-btn-bg div button {
    background-color: #fff;
  }
  .PackageDetails-purchase,
  .Payment-imgs {
    display: none;
  }
  .back-btn-8 {
    margin: 0;
  }
  .No-package-found {
    font-size: 16px;
    margin: 0;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .Brief-section {
    padding: 50px 0;
  }
  .BriefForm {
    padding: 36px 0;
  }
  .centerForm {
    max-width: 374px;
    margin: 24px auto 0 auto;
  }
  .Brief-form-container {
    margin-bottom: 24px;
  }
  .BriefForm-details {
    width: 85%;
  }
  .BriefForm-details > h3 {
    font-size: 20px;
    margin-bottom: 16px;
  }
  .Brief-main-title > h6 {
    margin: 0;
  }
  .BriefForm-details > p {
    font-size: 14px;
    margin: 0;
  }
  .col-max-1,
  .Total-price-header,
  .col-max-2,
  .PackageDetails-purchase,
  .Payment-container,
  .Brief-main-title {
    max-width: 374px;
    margin: 0 auto;
  }
  .Total-price-header {
    display: none;
  }
  .col-max-2 {
    padding: 0;
    margin-top: 24px;
  }
  .price-package-paragraph-container,
  .PackageDetails-purchase-details {
    max-width: 326px;
  }
  .noMargin {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .Brief-product-details-mobile {
    max-width: 374px;
  }
  .Payment-container {
    background-color: #fff;
    border: none;
    box-shadow: none;
  }
  .Payment-container form {
    margin: 0;
    max-width: 374px;
  }
  .special-btn-bg div button {
    background-color: #fff;
  }
  .PackageDetails-purchase,
  .Payment-imgs {
    display: none;
  }
  .back-btn-8 {
    margin: 0;
  }
  .No-package-found {
    font-size: 16px;
    margin: 0;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .Brief-section {
    padding: 50px 0;
  }
  .BriefForm {
    padding: 36px 0;
  }
  .centerForm {
    max-width: 335px;
    margin: 24px auto 0 auto;
  }
  .Brief-form-container {
    margin-bottom: 24px;
  }
  .BriefForm-details {
    width: 85%;
  }
  .BriefForm-details > h3 {
    font-size: 20px;
    margin-bottom: 16px;
  }
  .Brief-main-title > h6 {
    margin: 0;
  }
  .BriefForm-details > p {
    font-size: 14px;
    margin: 0;
  }
  .col-max-1,
  .Total-price-header,
  .col-max-2,
  .PackageDetails-purchase,
  .Payment-container,
  .Brief-main-title {
    max-width: 335px;
    margin: 0 auto;
  }
  .Total-price-header {
    display: none;
  }
  .col-max-2 {
    padding: 0;
    margin-top: 24px;
  }
  .price-package-paragraph-container,
  .PackageDetails-purchase-details {
    max-width: 278px;
  }
  .noMargin {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .Brief-product-details-mobile {
    max-width: 335px;
  }
  .Payment-container {
    background-color: #fff;
    border: none;
    box-shadow: none;
  }
  .Payment-container form {
    margin: 0;
    max-width: 335px;
  }
  .special-btn-bg div button {
    background-color: #fff;
  }
  .PackageDetails-purchase,
  .Payment-imgs {
    display: none;
  }
  .back-btn-8 {
    margin: 0;
  }
  .No-package-found {
    font-size: 16px;
    margin: 0;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .Brief-section {
    padding: 50px 0;
  }
  .BriefForm {
    padding: 36px 0;
  }
  .centerForm {
    max-width: 320px;
    margin: 24px auto 0 auto;
  }
  .Brief-form-container {
    margin-bottom: 24px;
  }
  .BriefForm-details {
    width: 90%;
  }
  .BriefForm-details > h3 {
    font-size: 20px;
    margin-bottom: 16px;
  }
  .Brief-main-title > h6 {
    margin: 0;
  }
  .BriefForm-details > p {
    font-size: 14px;
    margin: 0;
  }
  .col-max-1,
  .Total-price-header,
  .col-max-2,
  .PackageDetails-purchase,
  .Payment-container,
  .Brief-main-title {
    max-width: 320px;
    margin: 0 auto;
  }
  .Total-price-header {
    display: none;
  }
  .col-max-2 {
    padding: 0;
    margin-top: 24px;
  }
  .price-package-paragraph-container,
  .PackageDetails-purchase-details {
    max-width: 278px;
  }
  .noMargin {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Brief-product-details-mobile {
    max-width: 320px;
  }
  .Payment-container {
    background-color: #fff;
    border: none;
    box-shadow: none;
  }
  .Payment-container form {
    margin: 0;
    max-width: 320px;
  }
  .special-btn-bg div button {
    background-color: #fff;
  }
  .PackageDetails-purchase,
  .Payment-imgs {
    display: none;
  }
  .back-btn-8 {
    margin: 0;
  }
  .No-package-found {
    font-size: 16px;
    margin: 0;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .Brief-section {
    padding: 50px 0;
  }
  .BriefForm {
    padding: 36px 0;
  }
  .centerForm {
    max-width: 280px;
    margin: 24px auto 0 auto;
  }
  .Brief-form-container {
    margin-bottom: 24px;
  }
  .BriefForm-details {
    width: 90%;
  }
  .BriefForm-details > h3 {
    font-size: 20px;
    margin-bottom: 16px;
  }
  .Brief-main-title > h6 {
    margin: 0;
  }
  .BriefForm-details > p {
    font-size: 14px;
    margin: 0;
  }
  .col-max-1,
  .Total-price-header,
  .col-max-2,
  .PackageDetails-purchase,
  .Payment-container,
  .Brief-main-title {
    max-width: 280px;
    margin: 0 auto;
  }
  .Total-price-header {
    display: none;
  }
  .col-max-2 {
    padding: 0;
    margin-top: 24px;
  }
  .price-package-paragraph-container,
  .PackageDetails-purchase-details {
    max-width: 232px;
  }
  .noMargin {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Brief-product-details-mobile {
    max-width: 280px;
  }
  .Payment-container {
    background-color: #fff;
    border: none;
    box-shadow: none;
  }
  .Payment-container form {
    margin: 0;
    max-width: 280px;
  }
  .special-btn-bg div button {
    background-color: #fff;
  }
  .PackageDetails-purchase,
  .Payment-imgs {
    display: none;
  }
  .back-btn-8 {
    margin: 0;
  }
  .No-package-found {
    font-size: 16px;
    margin: 0;
  }
}
