/* Beggining Inputs styles*/
.Brief-form-container {
  margin-bottom: 48px;
}
.Brief-form-container-online div {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.Brief-form-container-online div label {
  font-size: 18px;
  font-weight: 400;
  width: 12%;
}

.Brief-form-container-online div input {
  max-width: 85%;
}
.online-links {
  margin-bottom: 16px;
}
.online-paragraph {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.67px;
  color: #58595c;
  margin-bottom: 24px;
}

.form-check-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  line-height: 1.44;
  letter-spacing: 0.6px;
  color: #58595c;
}

.form-check-label-remember {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.63;
  letter-spacing: 0.53px;
  color: #58595c;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.check-container {
  width: 17px;
  height: 17px;
  border-radius: 2px;
  border: solid 1px #000;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 13px;
}

.form-check-input:checked ~ .form-check-label .check-container {
  width: 17px;
  height: 17px;
  border-radius: 2px;
  background-color: #0091ff;
  border: solid 1px #0091ff;
}

.form-check-input:checked ~ .form-check-label-remember .check-container {
  width: 17px;
  height: 17px;
  border-radius: 2px;
  background-color: #0091ff;
  border: solid 1px #0091ff;
}

.online-small-text {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.6px;
  color: #58595c;
  margin-bottom: 48px;
}

.Brief-form-container-card {
  margin-bottom: 24px;
}

.Brief-form-container-card-inner {
  display: flex;
}

.Brief-form-container-card-inner div {
  width: 50%;
}

.Brief-form-container-card-inner div:nth-child(1) {
  margin-right: 34px;
}

.date-card-paragraph {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.67px;
  color: #58595c;
}

.cvv-container {
  width: 47%;
}

.two-btns {
  display: flex;
  justify-content: center;
  align-items: center;
}

.two-btns div button {
  width: 360px;
  height: 64px;
}

.special-btn-bg {
  margin-top: 24px;
}

.special-btn-bg div button {
  background-color: #f0f0f0;
}

/* End Inputs styles*/

label {
  color: #58595c;
}

.form-control:focus {
  border-color: #f47600;
  box-shadow: 0 0 0 0.2rem rgba(244, 118, 0, 0.25);
}

.label-links {
  margin: 0 10px 0 0;
}

.links-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 25px;
}

.Payment-container {
  max-width: 690px;
  background-color: #f0f0f0;
  border-radius: 15px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding: 40px 0;
}

.Payment-container form {
  max-width: 642px;
  margin: 0 auto;
}

.Payment-details-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mgRight {
  margin-right: 25px;
}

.Payment-imgs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0;
}

.Payment-img {
  width: 35px;
  height: 23px;
  margin-right: 5px;
}
.Payment-img-1 {
  background-image: url('../../img/payment/visa_icon.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.Payment-img-2 {
  background-image: url('../../img/payment/mastercard_icon.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.Payment-img-3 {
  background-image: url('../../img/payment/american_icon.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.isRemembered {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.isRemembered input {
  margin-top: 0;
}

.isRemembered label {
  font-size: 14px;
}

/*Stripe inputs*/
.Brief-form-cardStripe {
  background-color: #fff;
  border: solid 1px #000;
  border-radius: 4px;
  padding: 10px 16px;
  height: 50px;
}

/*Media Queries*/
@media screen and (max-width: 767px) and (min-width: 736px) {
  label,
  input,
  textarea,
  .online-small-text {
    font-size: 14px !important;
  }
  small {
    font-size: 11px;
  }
  .two-btns {
    flex-direction: column;
  }
  .two-btns div {
    margin-bottom: 24px;
  }
  .two-btns div button {
    width: 289px;
    height: 48px;
  }
  .online-paragraph {
    font-size: 14px;
    margin-bottom: 16px;
  }
  .online-small-text {
    margin-bottom: 24px;
  }
  .Brief-form-container-online div input {
    width: 69%;
  }
  .Payment-container form {
    max-width: 326px;
  }
  .date-card-paragraph {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 735px) and (min-width: 667px) {
  label,
  input,
  textarea,
  .online-small-text {
    font-size: 14px !important;
  }
  small {
    font-size: 11px;
  }
  .two-btns {
    flex-direction: column;
  }
  .two-btns div {
    margin-bottom: 24px;
  }
  .two-btns div button {
    width: 289px;
    height: 48px;
  }
  .online-paragraph {
    font-size: 14px;
    margin-bottom: 16px;
  }
  .online-small-text {
    margin-bottom: 24px;
  }
  .Brief-form-container-online div input {
    width: 69%;
  }
  .Payment-container form {
    max-width: 287px;
  }
  .date-card-paragraph {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 666px) and (min-width: 601px) {
  label,
  input,
  textarea,
  .online-small-text {
    font-size: 14px !important;
  }
  small {
    font-size: 11px;
  }
  .two-btns {
    flex-direction: column;
  }
  .two-btns div {
    margin-bottom: 24px;
  }
  .two-btns div button {
    width: 289px;
    height: 48px;
  }
  .online-paragraph {
    font-size: 14px;
    margin-bottom: 16px;
  }
  .online-small-text {
    margin-bottom: 24px;
  }
  .Brief-form-container-online div input {
    width: 69%;
  }
  .Payment-container form {
    max-width: 272px;
  }
  .date-card-paragraph {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 568px) and (min-width: 480px) {
  label,
  input,
  textarea,
  .online-small-text {
    font-size: 14px !important;
  }
  small {
    font-size: 11px;
  }
  .two-btns {
    flex-direction: column;
  }
  .two-btns div {
    margin-bottom: 24px;
  }
  .two-btns div button {
    width: 289px;
    height: 48px;
  }
  .online-paragraph {
    font-size: 14px;
    margin-bottom: 16px;
  }
  .online-small-text {
    margin-bottom: 24px;
  }
  .Brief-form-container-online div input {
    width: 69%;
  }
  .Payment-container form {
    max-width: 232px;
  }
  .special-btn-bg > div {
    max-width: 232px !important;
    margin: 0 auto;
  }
  .special-btn-bg > div > button {
    font-size: 15px;
  }
  .date-card-paragraph {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  label,
  input,
  textarea,
  .online-small-text {
    font-size: 14px !important;
  }
  small {
    font-size: 11px;
  }
  .two-btns {
    flex-direction: column;
  }
  .two-btns div {
    margin-bottom: 24px;
  }
  .two-btns div button {
    width: 289px;
    height: 48px;
  }
  .online-paragraph {
    font-size: 14px;
    margin-bottom: 16px;
  }
  .online-small-text {
    margin-bottom: 24px;
  }
  .Brief-form-container-online div input {
    width: 69%;
  }
  .Payment-container form {
    max-width: 326px;
  }
  .date-card-paragraph {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 413px) and (min-width: 375px) {
  label,
  input,
  textarea,
  .online-small-text {
    font-size: 14px !important;
  }
  small {
    font-size: 11px;
  }
  .two-btns {
    flex-direction: column;
  }
  .two-btns div {
    margin-bottom: 24px;
  }
  .two-btns div button {
    width: 289px;
    height: 48px;
  }
  .online-paragraph {
    font-size: 14px;
    margin-bottom: 16px;
  }
  .online-small-text {
    margin-bottom: 24px;
  }
  .Brief-form-container-online div input {
    width: 69%;
  }
  .Payment-container form {
    max-width: 287px;
  }
  .date-card-paragraph {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  label,
  input,
  textarea,
  .online-small-text {
    font-size: 14px !important;
  }
  small {
    font-size: 11px;
  }
  .two-btns {
    flex-direction: column;
  }
  .two-btns div {
    margin-bottom: 24px;
  }
  .two-btns div button {
    width: 289px;
    height: 48px;
  }
  .online-paragraph {
    font-size: 14px;
    margin-bottom: 16px;
  }
  .online-small-text {
    margin-bottom: 24px;
  }
  .Brief-form-container-online div input {
    width: 69%;
  }
  .Payment-container form {
    max-width: 272px;
  }
  .date-card-paragraph {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  label,
  input,
  textarea,
  .online-small-text {
    font-size: 14px !important;
  }
  small {
    font-size: 11px;
  }
  .two-btns {
    flex-direction: column;
  }
  .two-btns div {
    margin-bottom: 24px;
  }
  .two-btns div button {
    width: 289px;
    height: 48px;
  }
  .online-paragraph {
    font-size: 14px;
    margin-bottom: 16px;
  }
  .online-small-text {
    margin-bottom: 24px;
  }
  .Brief-form-container-online div input {
    width: 69%;
  }
  .Payment-container form {
    max-width: 232px;
  }
  .special-btn-bg > div {
    max-width: 232px !important;
    margin: 0 auto;
  }
  .special-btn-bg > div > button {
    font-size: 15px;
  }
  .date-card-paragraph {
    font-size: 14px !important;
  }
}
