.AgencyProfile-wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(5, 1fr);
  padding: 40px 0;
}

.AgencyProfile-item {
  display: grid;
  grid-template-columns: 290px auto;
  grid-template-rows: repeat(1, 1fr);
  padding: 13px 20px;
}

.AgencyProfile-item:nth-child(odd) {
  background-color: #f8f8f8;
}

.AgencyProfile-item-title {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.AgencyProfile-item-subtitle {
  opacity: 0.5;
  font-size: 15px;
  color: #000;
}

.AgencyProfile-innerLinks {
  margin-top: 38px;
}

.AgencyProfile-innerLinks-list {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AgencyProfile-innerLinks-item {
  width: 100%;
  text-align: center;
  padding: 10px;
}

.AgencyProfile-projects-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  padding: 13px;
  background-color: #f8f8f8;
}

.AgencyProfile-project-title {
  font-size: 15px;
  font-weight: 500;
  color: #000;
  margin: 0;
}

.AgencyProfile-project-details-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  padding: 13px;
}

.AgencyProfile-project-details-container:nth-child(odd) {
  background-color: #f8f8f8;
}

.AgencyProfile-proyect-details-subtitle,
.AgencyProfile-noProjects {
  opacity: 0.5;
  font-size: 15px;
  color: #000;
  margin: 0;
}

.AgencyProfile-noProjects {
  text-align: center;
  padding: 13px;
}

.AgencyProfile-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AgencyProfile-icon-link {
  margin-right: 28px;
}

.AgencyProfile-icon-btn {
  border: none;
  background-color: transparent;
}

.AgencyProfile-icon-btn:focus {
  outline: none;
}

.AgencyProfile-icons-wrapper {
  display: none;
}

.AgencyProfile-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 28px;
}
