.PackagesPerCategory-title h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 1;
  color: #f47600;
  margin-bottom: 18px;
}

.PackagesPerCategory-title:not(:first-child) h3 {
  margin-top: 80px;
}

.PackagesPerCategory-btn-container {
  text-align: right;
}

.PackagesPerCategory-btn {
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: 0.67px;
  text-align: center;
  color: #f47600;
  background-color: #fff;
  border: none;
  text-decoration: underline;
}

.PackagesPerCategory-btn:focus {
  outline: none;
}

/*Media Queries*/
@media screen and (max-width: 1019px) and (min-width: 768px) {
  .PackagesPerCategory-title h3 {
    font-size: 24px;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .PackagesPerCategory-title {
    max-width: 335px;
    margin: 0 auto;
  }
  .PackagesPerCategory-title h3 {
    font-size: 24px;
  }
  .PackagesPerCategory-btn-container {
    text-align: center;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .PackagesPerCategory-title h3 {
    font-size: 24px;
    margin-top: 24px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .PackagesPerCategory-title h3 {
    font-size: 24px;
    margin-top: 24px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .PackagesPerCategory-title h3 {
    font-size: 24px;
    margin-top: 24px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .PackagesPerCategory-title h3 {
    font-size: 24px;
    margin-top: 24px;
  }
}
