.ProjectList {
  margin: 0 0 13rem 0;
}

.ProjectList-container {
  padding: 80px 0;
}

.ProjectList-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ProjectList-title {
  margin-bottom: 35px;
}

.ProjectList-title h3 {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 1.07px;
  color: #58595c;
}

.ProjectList-paragraph-container {
  height: 350px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProjectList-paragraph {
  font-size: 30px;
  font-weight: 300;
}

.ProjectList-dropdown {
  text-align: right;
  margin-bottom: 25px;
}

/*Media Queries*/
@media screen and (min-width: 1441px) {
  .ProjectList {
    margin: 0 0 23rem 0;
  }
}

@media screen and (max-width: 1279px) and (min-width: 1020px) {
  .ProjectList {
    margin: 0 0 29rem 0;
  }
}

@media screen and (max-width: 1019px) and (min-width: 768px) {
  .ProjectList {
    margin: 0 0 8rem 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .ProjectList {
    margin: 0 0 8rem 0;
  }
  .ProjectList-title h3 {
    font-size: 24px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .ProjectListContainer {
    padding-right: 20px;
    padding-left: 20px;
  }
  .ProjectList-container {
    padding: 35px 0;
  }
  .ProjectList {
    margin: 0 0 9rem 0;
    padding: 50px 0;
  }
  .ProjectList-header-container {
    flex-direction: column;
  }
  .ProjectList-title {
    margin-bottom: 0;
    align-self: flex-start;
  }
  .ProjectList-dropdown {
    align-self: flex-end;
  }
  .ProjectList-title h3,
  .ProjectList-paragraph {
    font-size: 24px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .ProjectListContainer {
    padding-right: 20px;
    padding-left: 20px;
  }
  .ProjectList-container {
    padding: 35px 0;
  }
  .ProjectList {
    margin: 0 0 9rem 0;
    padding: 50px 0;
  }
  .ProjectList-header-container {
    flex-direction: column;
  }
  .ProjectList-title {
    margin-bottom: 0;
    align-self: flex-start;
  }
  .ProjectList-dropdown {
    align-self: flex-end;
  }
  .ProjectList-title h3,
  .ProjectList-paragraph {
    font-size: 24px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .ProjectListContainer {
    padding-right: 20px;
    padding-left: 20px;
  }
  .ProjectList-container {
    padding: 35px 0;
  }
  .ProjectList {
    margin: 0 0 9rem 0;
    padding: 50px 0;
  }
  .ProjectList-header-container {
    flex-direction: column;
  }
  .ProjectList-title {
    margin-bottom: 0;
    align-self: flex-start;
  }
  .ProjectList-dropdown {
    align-self: flex-end;
  }
  .ProjectList-title h3,
  .ProjectList-paragraph {
    font-size: 24px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .ProjectListContainer {
    padding-right: 20px;
    padding-left: 20px;
  }
  .ProjectList-container {
    padding: 35px 0;
  }
  .ProjectList {
    margin: 0 0 9rem 0;
    padding: 50px 0;
  }
  .ProjectList-header-container {
    flex-direction: column;
  }
  .ProjectList-title {
    margin-bottom: 0;
    align-self: flex-start;
  }
  .ProjectList-dropdown {
    align-self: flex-end;
  }
  .ProjectList-title h3,
  .ProjectList-paragraph {
    font-size: 24px;
  }
}
