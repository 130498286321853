.Textarea-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.Textarea-label {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.67px;
  color: #58595c;
}

.Textarea {
  width: 100%;
  height: 120px;
  padding: 10px 16px;
  border-radius: 4px;
  border: solid 1px #000;
  background-color: #fff;
  resize: none;
  font-size: 20px;
  color: #58595c;
}

.Textarea:focus {
  border-color: #f47600;
  box-shadow: 0 0 0 0.2rem rgba(244, 118, 0, 0.25);
  outline: none;
}

.Textarea-message {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.6px;
  color: #58595c;
  margin-top: 16px;
}

.Textarea::-webkit-input-placeholder {
  opacity: 0.24;
  font-size: 20px;
  letter-spacing: 0.67px;
  color: #000;
}

.Textarea:-ms-input-placeholder {
  opacity: 0.24;
  font-size: 20px;
  letter-spacing: 0.67px;
  color: #000;
}

.Textarea::placeholder {
  opacity: 0.24;
  font-size: 20px;
  letter-spacing: 0.67px;
  color: #000;
}

.Textarea-error {
  margin-top: 9px;
  color: red;
}

/*Media Queries*/
@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .Textarea-label {
    font-size: 19px;
  }
  .Textarea {
    font-size: 19px;
  }
  .Textarea::-webkit-input-placeholder {
    font-size: 19px;
  }

  .Textarea:-ms-input-placeholder {
    font-size: 19px;
  }

  .Textarea::placeholder {
    font-size: 19px;
  }
  .Textarea-message {
    font-size: 17px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .Textarea-label {
    font-size: 19px;
  }
  .Textarea {
    font-size: 19px;
  }
  .Textarea::-webkit-input-placeholder {
    font-size: 19px;
  }

  .Textarea:-ms-input-placeholder {
    font-size: 19px;
  }

  .Textarea::placeholder {
    font-size: 19px;
  }
  .Textarea-message {
    font-size: 17px;
  }
}

@media screen and (max-width: 767px) and (min-width: 736px) {
  .Textarea-label,
  .Textarea-message {
    font-size: 14px !important;
  }
  .Textarea {
    padding: 10px 11px;
    font-size: 16px !important;
  }
  .Textarea::-webkit-input-placeholder {
    font-size: 16px;
  }

  .Textarea:-ms-input-placeholder {
    font-size: 16px;
  }

  .Textarea::placeholder {
    font-size: 16px;
  }
}

@media screen and (max-width: 735px) and (min-width: 667px) {
  .Textarea-label,
  .Textarea-message {
    font-size: 14px !important;
  }
  .Textarea {
    padding: 10px 11px;
    font-size: 16px !important;
  }
  .Textarea::-webkit-input-placeholder {
    font-size: 16px;
  }

  .Textarea:-ms-input-placeholder {
    font-size: 16px;
  }

  .Textarea::placeholder {
    font-size: 16px;
  }
}

@media screen and (max-width: 666px) and (min-width: 601px) {
  .Textarea-label,
  .Textarea-message {
    font-size: 14px !important;
  }
  .Textarea {
    padding: 10px 11px;
    font-size: 16px !important;
  }
  .Textarea::-webkit-input-placeholder {
    font-size: 16px;
  }

  .Textarea:-ms-input-placeholder {
    font-size: 16px;
  }

  .Textarea::placeholder {
    font-size: 16px;
  }
}

@media screen and (max-width: 568px) and (min-width: 480px) {
  .Textarea-label,
  .Textarea-message {
    font-size: 14px !important;
  }
  .Textarea {
    padding: 10px 11px;
    font-size: 16px !important;
  }
  .Textarea::-webkit-input-placeholder {
    font-size: 16px;
  }

  .Textarea:-ms-input-placeholder {
    font-size: 16px;
  }

  .Textarea::placeholder {
    font-size: 16px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .Textarea-label,
  .Textarea-message {
    font-size: 14px !important;
  }
  .Textarea {
    padding: 10px 11px;
    font-size: 16px !important;
  }
  .Textarea::-webkit-input-placeholder {
    font-size: 16px;
  }

  .Textarea:-ms-input-placeholder {
    font-size: 16px;
  }

  .Textarea::placeholder {
    font-size: 16px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .Textarea-label,
  .Textarea-message {
    font-size: 14px !important;
  }
  .Textarea {
    padding: 10px 11px;
    font-size: 16px !important;
  }
  .Textarea::-webkit-input-placeholder {
    font-size: 16px;
  }

  .Textarea:-ms-input-placeholder {
    font-size: 16px;
  }

  .Textarea::placeholder {
    font-size: 16px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .Textarea-label,
  .Textarea-message {
    font-size: 14px !important;
  }
  .Textarea {
    padding: 10px 11px;
    font-size: 16px !important;
  }
  .Textarea::-webkit-input-placeholder {
    font-size: 16px;
  }

  .Textarea:-ms-input-placeholder {
    font-size: 16px;
  }

  .Textarea::placeholder {
    font-size: 16px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .Textarea-label,
  .Textarea-message {
    font-size: 14px !important;
  }
  .Textarea {
    padding: 10px 11px;
    font-size: 16px !important;
  }
  .Textarea::-webkit-input-placeholder {
    font-size: 16px;
  }

  .Textarea:-ms-input-placeholder {
    font-size: 16px;
  }

  .Textarea::placeholder {
    font-size: 16px;
  }
}
