.card-project {
  border-radius: 15px;
  border: none;
  padding: 5px 12px 5px 12px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  max-width: 588px;
  height: 157px;
}

.card-title-project {
  color: #58595c;
  font-size: 20px;
  margin: 0;
}

.card-title-project span {
  color: #f47600;
}

.card-subtitle-project,
.card-text-project {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.53px;
  color: #58595c;
}

.card-subtitle-project {
  margin-top: 15px;
  margin-bottom: 0 !important;
}

.Project-link {
  text-decoration: none !important;
}

.card-text-project {
  margin: 0px 0 0px 0;
}

.card-project-bg {
  width: 25px;
  height: 25px;
  background-color: tomato;
}

.card-project-img {
  width: 33%;
  height: 96px;
  position: absolute;
  right: 0;
  bottom: 0;
  background-image: url('../../../img/projects/projectsUsers/rhino_brief.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
}

.card-project-img-agency {
  width: 32%;
  height: 78px;
  position: absolute;
  right: 0;
  bottom: 0;
  background-image: url('../../../img/projects/projectAgencies/rhino_agency_img.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
}

.Project-wrapper {
  margin-bottom: 26px;
}

/*Media Queries*/
@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .card-title-project {
    font-size: 19px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  .card-title-project {
    font-size: 19px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .card-project {
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .card-project {
    border: 1px solid #b3b3b3;
    box-shadow: none;
    padding: 0;
    height: 100%;
  }
  .card-project-img {
    display: none;
  }
  .card-title-project {
    font-size: 18px;
  }
  .card-title-project span {
    display: block;
  }
  .card-subtitle-project,
  .card-text-project {
    font-size: 14px;
  }
  .card-subtitle-project {
    margin-top: 11px;
  }
  .card-body {
    padding: 16px 24px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .card-project {
    border: 1px solid #b3b3b3;
    box-shadow: none;
    padding: 0;
    height: 100%;
  }
  .card-project-img {
    display: none;
  }
  .card-title-project {
    font-size: 18px;
  }
  .card-title-project span {
    display: block;
  }
  .card-subtitle-project,
  .card-text-project {
    font-size: 14px;
  }
  .card-subtitle-project {
    margin-top: 11px;
  }
  .card-body {
    padding: 16px 24px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .card-project {
    border: 1px solid #b3b3b3;
    box-shadow: none;
    padding: 0;
    height: 100%;
  }
  .card-project-img {
    display: none;
  }
  .card-title-project {
    font-size: 18px;
  }
  .card-title-project span {
    display: block;
  }
  .card-subtitle-project,
  .card-text-project {
    font-size: 14px;
  }
  .card-subtitle-project {
    margin-top: 11px;
  }
  .card-body {
    padding: 16px 24px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .card-project {
    border: 1px solid #b3b3b3;
    box-shadow: none;
    padding: 0;
    height: 100%;
  }
  .card-project-img {
    display: none;
  }
  .card-title-project {
    font-size: 18px;
  }
  .card-title-project span {
    display: block;
  }
  .card-subtitle-project,
  .card-text-project {
    font-size: 14px;
  }
  .card-subtitle-project {
    margin-top: 11px;
  }
  .card-body {
    padding: 16px 24px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .card-project {
    border: 1px solid #b3b3b3;
    box-shadow: none;
    padding: 0;
    height: 100%;
  }
  .card-project-img {
    display: none;
  }
  .card-title-project {
    font-size: 18px;
  }
  .card-title-project span {
    display: block;
  }
  .card-subtitle-project,
  .card-text-project {
    font-size: 14px;
  }
  .card-subtitle-project {
    margin-top: 11px;
  }
  .card-body {
    padding: 16px 24px;
  }
}
