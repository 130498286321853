.ResetModal {
  width: 620px;
  height: 238px;
  border-radius: 15px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ResetModal-body {
  margin-bottom: 0;
}

.ResetModal-paragraph-container {
  margin-bottom: 25px;
}

.ResetModal-paragraph {
  font-size: 20px;
  letter-spacing: 0.23px;
  text-align: center;
  color: #58595c;
}

.ResetModal-btn-container {
  width: 360px;
  height: 64px;
}

.ResetModal-btn-container > div button {
  background-color: #f47600;
  color: #fff;
}

/*Media Queries*/
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .modal-content {
    width: 90%;
    margin: 0 auto;
  }
  .ResetModal {
    width: 100%;
  }
  .ResetModal-paragraph-container {
    padding: 0 40px;
  }
  .ResetModal-paragraph {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .modal-content {
    width: 90%;
    margin: 0 auto;
  }
  .ResetModal {
    width: 100%;
  }
  .ResetModal-paragraph-container {
    padding: 0 40px;
  }
  .ResetModal-paragraph {
    font-size: 18px;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .modal-content {
    width: 90%;
    margin: 0 auto;
  }
  .ResetModal {
    width: 100%;
  }
  .ResetModal-paragraph-container {
    padding: 0 52px;
  }
  .ResetModal-paragraph {
    font-size: 16px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .modal-content {
    width: 90%;
    margin: 0 auto;
  }
  .ResetModal {
    width: 100%;
  }
  .ResetModal-paragraph-container {
    padding: 0 38px;
  }
  .ResetModal-paragraph {
    font-size: 16px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .modal-content {
    width: 95%;
    margin: 0 auto;
  }
  .ResetModal {
    width: 100%;
  }
  .ResetModal-paragraph-container {
    padding: 0 30px;
  }
  .ResetModal-paragraph {
    font-size: 16px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .modal-content {
    width: 95%;
    margin: 0 auto;
  }
  .ResetModal {
    width: 100%;
  }
  .ResetModal-paragraph-container {
    padding: 0 20px;
  }
  .ResetModal > div:last-child {
    width: 90%;
  }
  .ResetModal-paragraph {
    font-size: 16px;
  }
}
