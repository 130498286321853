.UserChat {
  padding: 3rem 0;
  width: 100%;
}

textarea {
  resize: none;
}

.UserChat-btn-container {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.UserChat-upload {
  margin-right: 20px;
}

.UserChat-btn {
  border-radius: 15px;
  background-color: #f47600;
  border: none;
  padding: 13px 43px;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.67px;
}

.UserChat-btn:focus {
  outline: none;
}

.UserChat-msg-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 45vh;
  margin: 20px 0;
  overflow-x: hidden;
  width: 93%;
  margin: 20px 0 20px 7%;
}

.UserChat-form-group {
  width: 93%;
  margin: 0 0 1rem 7%;
}

.UserChat-title {
  margin: 0;
  color: #58595c;
  font-weight: 500;
  padding: 35px 0 35px 0;
}

.UserChat-upload-label {
  color: #58595c;
  margin: 0;
  cursor: pointer;
}

.Message-input {
  width: 100%;
  height: 80px;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1) !important;
  border: solid 1px rgba(0, 0, 0, 0.5) !important;
  background-color: #ffffff;
  padding: 16px;
  font-size: 15px;
  color: #000;
}

.Message-input::-webkit-input-placeholder {
  opacity: 0.24;
}
.Message-input:-ms-input-placeholder {
  opacity: 0.24;
}

.Message-input::placeholder {
  opacity: 0.24;
}

.Message-input:focus {
  outline: none !important;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1) !important;
  border: solid 1px rgba(0, 0, 0, 0.5) !important;
  background-color: #ffffff;
}

.UserChat-msg-container-paragraph {
  position: absolute;
  bottom: 0;
  margin-bottom: 32px;
  font-size: 17px;
  line-height: normal;
  letter-spacing: 0.67px;
  color: #b3b3b3;
}

/*Styling File input*/
.fileUpload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.fileUploadLabel {
  color: #000;
  font-weight: 500;
  letter-spacing: 0.67px;
  margin: 0;
  font-size: 17px;
  cursor: pointer;
}

.UserChat-upload-btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Message-clip-icon {
  display: inline-block;
  width: 7px;
  height: 11px;
  margin-right: 3px;
}

/*Media Queries*/
@media screen and (min-width: 1441px) {
  .UserChat-mobile {
    display: none;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1367px) {
  .UserChat-msg-container {
    height: 40vh;
  }
  .UserChat-title {
    padding: 25px 0 25px 0;
  }
  .UserChat-mobile {
    display: none;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1281px) {
  .UserChat {
    padding: 2rem 0;
  }
  .UserChat-msg-container {
    height: 39vh;
  }
  .UserChat-title {
    padding: 20px 0 20px 0;
    font-size: 27px;
  }
  .UserChat-mobile {
    display: none;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1150px) {
  .UserChat {
    padding: 2rem 0;
  }
  .UserChat-msg-container {
    height: 39vh;
  }
  .UserChat-title {
    padding: 20px 0 20px 0;
    font-size: 27px;
  }
  .UserChat-mobile {
    display: none;
  }
}

@media screen and (max-width: 1149px) and (min-width: 1020px) {
  .UserChat {
    padding: 6rem 0;
  }
  .UserChat-msg-container {
    height: 39vh;
  }
  .UserChat-title {
    padding: 20px 0 20px 0;
    font-size: 25px;
  }
  .UserChat-mobile {
    display: none;
  }
}

@media screen and (max-width: 1019px) and (min-width: 768px) {
  .UserChat {
    padding: 6rem 0;
  }
  .UserChat-msg-container {
    height: 55vh;
    width: 90%;
    margin: 20px 0 20px 10%;
  }
  .UserChat-form-group {
    width: 90%;
    margin: 0 0 1rem 10%;
  }
  .UserChat-title {
    padding: 20px 0 20px 0;
    font-size: 25px;
  }
  .UserChat-mobile {
    display: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  .UserChat {
    padding: 6rem 0;
  }
  .UserChat-msg-container {
    height: 53vh;
    width: 90%;
    margin: 20px 0 20px 10%;
  }
  .UserChat-form-group {
    width: 90%;
    margin: 0 0 1rem 10%;
  }
  .UserChat-title {
    padding: 20px 0 20px 0;
    font-size: 25px;
  }
  .UserChat-mobile {
    display: none;
  }
}

@media screen and (max-width: 479px) and (min-width: 414px) {
  .UserChat {
    padding: 1rem 0;
    height: 100vh;
  }
  .UserChat-msg-container {
    height: 65vh;
    width: 100%;
    margin: 20px 0 20px 0;
  }
  .UserChat-form-group {
    width: 100%;
    margin: 0 0 1rem 0;
  }
  .UserChat-title {
    padding: 20px 0 20px 0;
    font-size: 22px;
  }
  .fileUploadLabel {
    font-size: 15px !important;
  }
  .UserChat-btn {
    font-size: 15px;
    padding: 10px 30px;
  }
  .fileUploadLabelClip {
    width: 8px;
    height: 15px;
  }
  .UserChat-desk {
    display: none;
  }
  .UserChat-mobile {
    width: 100%;
  }
  .UserChat-header-mobile {
    height: 120px;
    width: 100%;
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .UserChat-header-mobile > div {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: 17px 318px;
    max-width: 335px;
    margin: 0 auto;
  }
  .UserChat-header-mobile > div > p {
    font-size: 20px;
    font-weight: 500;
    line-height: 0.9;
    letter-spacing: 0.3px;
    text-align: center;
    color: #58595c;
  }
  .UserChat-mgs-container-mobile {
    max-width: 392px;
    margin: 0 auto;
    padding: 15px 0;
  }
  .UserChat-mgs-title-mobile {
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.07px;
    text-align: center;
    color: #8a8a8f;
  }
  .Message-input {
    width: 279px;
    height: 34px;
    border-radius: 100px;
    background-color: #fafafa;
    padding: 2px 15px;
  }
  .UserChat-form-group {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .UserChat-mgs-send > button {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: #f47600;
    border: none;
  }
  .UserChat-mgs-send > button:focus {
    outline: none;
  }
  .UserChat-wrapper-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .UserChat-msg-container-paragraph {
    font-size: 14px;
    bottom: 13%;
  }
  .UserChat-mgs-mobile-fileName {
    font-size: 11px;
    margin-bottom: 3px;
    margin-left: 58px;
  }
}

@media screen and (max-width: 413px) and (min-width: 375px) {
  .UserChat {
    padding: 1rem 0;
  }
  .UserChat-msg-container {
    height: 63vh;
    width: 100%;
    margin: 20px 0 20px 0;
  }
  .UserChat-form-group {
    width: 100%;
    margin: 0 0 1rem 0;
  }
  .UserChat-title {
    padding: 20px 0 20px 0;
    font-size: 22px;
  }
  .fileUploadLabel {
    font-size: 15px !important;
  }
  .UserChat-btn {
    font-size: 15px;
    padding: 10px 30px;
  }
  .Message-input {
    padding: 8px;
  }
  .fileUploadLabelClip {
    width: 8px;
    height: 15px;
  }
  .Message-input {
    font-size: 12px !important;
    height: 60px;
  }
  .UserChat-msg-container-paragraph {
    font-size: 14px;
    padding: 5px;
    margin: 0 0 6px 0;
  }
  .UserChat-desk {
    display: none;
  }
  .UserChat-mobile {
    width: 100%;
  }
  .UserChat-header-mobile {
    height: 120px;
    width: 100%;
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .UserChat-header-mobile > div {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: 17px 318px;
    max-width: 335px;
    margin: 0 auto;
  }
  .UserChat-header-mobile > div > p {
    font-size: 20px;
    font-weight: 500;
    line-height: 0.9;
    letter-spacing: 0.3px;
    text-align: center;
    color: #58595c;
  }
  .UserChat-mgs-container-mobile {
    max-width: 353px;
    margin: 0 auto;
    padding: 15px 0;
  }
  .UserChat-mgs-title-mobile {
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.07px;
    text-align: center;
    color: #8a8a8f;
  }
  .Message-input {
    width: 279px;
    height: 34px;
    border-radius: 100px;
    background-color: #fafafa;
    padding: 2px 15px;
  }
  .UserChat-form-group {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .UserChat-mgs-send > button {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: #f47600;
    border: none;
  }
  .UserChat-mgs-send > button:focus {
    outline: none;
  }
  .UserChat-wrapper-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .UserChat-msg-container-paragraph {
    font-size: 14px;
    bottom: 13%;
  }
  .UserChat-mgs-mobile-fileName {
    font-size: 11px;
    margin-bottom: 3px;
    margin-left: 58px;
  }
}

@media screen and (max-width: 374px) and (min-width: 360px) {
  .UserChat {
    padding: 1rem 0;
  }
  .UserChat-msg-container {
    height: 61vh;
    width: 100%;
    margin: 20px 0 20px 0;
  }
  .UserChat-form-group {
    width: 100%;
    margin: 0 0 1rem 0;
  }
  .UserChat-title {
    padding: 20px 0 20px 0;
    font-size: 22px;
  }
  .fileUploadLabel {
    font-size: 15px !important;
  }
  .UserChat-btn {
    font-size: 15px;
    padding: 10px 30px;
  }
  .Message-input {
    padding: 8px;
  }
  .fileUploadLabelClip {
    width: 8px;
    height: 15px;
  }
  .Message-input {
    font-size: 12px !important;
    height: 60px;
  }
  .UserChat-msg-container-paragraph {
    font-size: 14px;
    padding: 5px;
    margin: 0 0 6px 0;
  }
  .UserChat-desk {
    display: none;
  }
  .UserChat-mobile {
    width: 100%;
  }
  .UserChat-header-mobile {
    height: 120px;
    width: 100%;
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .UserChat-header-mobile > div {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: 17px 318px;
    max-width: 335px;
    margin: 0 auto;
  }
  .UserChat-header-mobile > div > p {
    font-size: 20px;
    font-weight: 500;
    line-height: 0.9;
    letter-spacing: 0.3px;
    text-align: center;
    color: #58595c;
  }
  .UserChat-mgs-container-mobile {
    max-width: 353px;
    margin: 0 auto;
    padding: 15px 0;
  }
  .UserChat-mgs-title-mobile {
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.07px;
    text-align: center;
    color: #8a8a8f;
  }
  .Message-input {
    width: 279px;
    height: 34px;
    border-radius: 100px;
    background-color: #fafafa;
    padding: 2px 15px;
  }
  .UserChat-form-group {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .UserChat-mgs-send > button {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: #f47600;
    border: none;
  }
  .UserChat-mgs-send > button:focus {
    outline: none;
  }
  .UserChat-wrapper-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .UserChat-msg-container-paragraph {
    font-size: 14px;
    bottom: 13%;
  }
  .UserChat-mgs-mobile-fileName {
    font-size: 11px;
    margin-bottom: 3px;
    margin-left: 58px;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .UserChat {
    padding: 1rem 0;
  }
  .UserChat-msg-container {
    height: 56vh;
    width: 100%;
    margin: 20px 0 20px 0;
  }
  .UserChat-form-group {
    width: 100%;
    margin: 0 0 1rem 0;
  }
  .UserChat-title {
    padding: 20px 0 20px 0;
    font-size: 22px;
  }
  .fileUploadLabel {
    font-size: 15px !important;
  }
  .UserChat-btn {
    font-size: 15px;
    padding: 10px 30px;
  }
  .Message-input {
    padding: 8px;
  }
  .fileUploadLabelClip {
    width: 8px;
    height: 15px;
  }
  .Message-input {
    font-size: 12px !important;
    height: 60px;
  }
  .UserChat-msg-container-paragraph {
    font-size: 14px;
    padding: 5px;
    margin: 0 0 6px 0;
  }
  .UserChat-desk {
    display: none;
  }
  .UserChat-mobile {
    width: 100%;
  }
  .UserChat-header-mobile {
    height: 120px;
    width: 100%;
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .UserChat-header-mobile > div {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: 17px 224px;
    max-width: 335px;
    margin: 0 auto;
  }
  .UserChat-header-mobile > div > p {
    font-size: 20px;
    font-weight: 500;
    line-height: 0.9;
    letter-spacing: 0.3px;
    text-align: center;
    color: #58595c;
  }
  .UserChat-mgs-container-mobile {
    max-width: 298px;
    margin: 0 auto;
    padding: 15px 0;
  }
  .UserChat-mgs-title-mobile {
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.07px;
    text-align: center;
    color: #8a8a8f;
  }
  .Message-input {
    width: 220px;
    height: 34px;
    border-radius: 100px;
    background-color: #fafafa;
    padding: 2px 15px;
  }
  .UserChat-form-group {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .UserChat-mgs-send > button {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: #f47600;
    border: none;
  }
  .UserChat-mgs-send > button:focus {
    outline: none;
  }
  .UserChat-wrapper-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .UserChat-msg-container-paragraph {
    font-size: 14px;
    bottom: 13%;
  }
  .UserChat-mgs-mobile-fileName {
    font-size: 11px;
    margin-bottom: 3px;
    margin-left: 58px;
  }
}
