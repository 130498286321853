.UserDashboardList-wrapper {
  display: grid;
  grid-template-columns: 335px auto;
  grid-template-rows: repeat(1, 1fr);
  margin-top: 21px;
  padding: 13px;
  background-color: #f8f8f8;
}

.UserDashboardList-title {
  font-size: 15px;
  font-weight: 500;
  color: #000;
  margin: 0;
}

.UserDashboardList-docs-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
